import { useAssetManagerContext } from '../context/AssetManagerContext';

export const useCampaignInfo = () => {
  const { campaignInfo } = useAssetManagerContext();
  return campaignInfo;
};

export const useAssetInfo = () => {
  const { assetInfo } = useAssetManagerContext();
  return assetInfo;
};

export const useAssetType = () => {
  const { assetType } = useAssetManagerContext();
  return assetType;
};
