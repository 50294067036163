/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { t } from '@jotforminc/translation';
import {
  IconUsersFilled, IconProductSignMono, IconProductAppsMono
} from '@jotforminc/svg-icons';
import { getUrlParameter } from '@jotforminc/utils';

import '../styles/feature.scss';

import FeatureBox from './FeatureBox';

import { arrayObjToFirst, getPrettyLimits } from '../utils';

import IconHipaa from '../assets/svg/iconTableHipaa.svg';
import IconHipaaDisabled from '../assets/svg/iconTableHipaaDisabled.svg';
import IconHipaaDisabledLight from '../assets/svg/iconTableHipaaDisabledLight.svg';

import IconJFBranding from '../assets/svg/iconTableJFBranding.svg';
import IconJFBrandingLight from '../assets/svg/iconTableJFBrandingLight.svg';
import IconJFBrandingDisabled from '../assets/svg/iconTableJFBrandingDisabled.svg';
import IconJFBrandingDisabledLight from '../assets/svg/iconTableJFBrandingDisabledLight.svg';

import IconInfo from '../assets/svg/iconInfo.svg';
import IconInfoLight from '../assets/svg/iconInfoLight.svg';

import IconGDPR from '../assets/svg/iconGDPR.svg';

import { usePricingContext } from '../context/pricingTableContext';

import {
  GDPR_FRIENDLY,
  HIPAA_COMPLIANCE_AVAILABLE,
  HIPAA_COMPLIANCE_NOT_AVAILABLE,
  JOTFORM_BRANDING,
  MONTHLY_SIGNED_DOCUMENT_LIMIT,
  NO_BRANDING,
  ONE_USER,
  UNLIMITED,
  APPS
} from '../constants/texts';
import {
  COLOR_THEMES,
  PLANS,
  SOURCES,
  THEMES
} from '../constants';

const PlanContent = ({ plan, className }) => {
  const {
    state: {
      user,
      showFieldsPerFormLimit,
      showHipaaInfo,
      campaign: { type: campaignType },
      billingCountry,
      source,
      theme,
      visibleFeatures,
      hideAllLimits,
      hideNoBranding,
      pullUpHipaaBadge
    },
    onBrandingInfoClicked,
    onHipaaInfoClicked,
    getColumnColors,
    getUserLanguage,
    getPlanColors,
    getColorTheme
  } = usePricingContext();

  const {
    showFormCountStats,
    location: { country, country_code: countryCode }
  } = user;

  const columnColors = getColumnColors();
  const language = getUserLanguage();
  const planColors = getPlanColors();

  const { limits, name: planName } = plan;

  const teamsPricing = source === SOURCES.TEAMS_PRICING;
  const signPricing = source === SOURCES.SIGN_PRICING;
  const appsPricing = source === SOURCES.APPS_PRICING;
  const workflowsPricing = source === SOURCES.WORKFLOWS_PRICING;

  const showGDPRParam = getUrlParameter('gdpr');
  const showGDPR = showGDPRParam ? ['true', '1'].includes(showGDPRParam) : global.window.showGdprFeature;

  let features = getPrettyLimits({
    t,
    visibleFeatures,
    hideAllLimits,
    language,
    limits,
    showFormLimit: showFormCountStats,
    showHipaa: true,
    showFieldsPerFormLimit,
    showGDPR,
    campaignType,
    pullUpHipaaBadge
  });

  if (teamsPricing) {
    features = arrayObjToFirst(features, 'feature', 'perTeam');
  } else if (signPricing) {
    features = arrayObjToFirst(features, 'feature', 'signedDocuments');
  } else if (appsPricing) {
    features = [{ feature: 'apps', info: APPS }, ...features];
  } else if (workflowsPricing) {
    // features = arrayObjToFirst(features, 'feature', 'elementPerWorkflow');
    features = arrayObjToFirst(features, 'feature', 'workflowRuns');
  }

  if (!hideNoBranding) {
    features.push({ feature: 'branding' });
  }

  const handleBrandingInfoClicked = event => {
    event.stopPropagation();
    onBrandingInfoClicked();
  };

  const handleHipaaInfoClicked = event => {
    event.stopPropagation();
    onHipaaInfoClicked();
  };

  const contentBackgroundColor = columnColors.CONTENT;
  const contentTextColor = columnColors.CONTENT_TEXT;
  const contentTextBottomColor = columnColors.CONTENT_TEXT_BOTTOM;
  const { COLOR: TITLE_BG_COLOR } = planColors[planName];

  const planContentClasses = cx('w-full flex-1 radius-bl-lg radius-br-lg', className, {
    'px-5': (theme !== THEMES.BILLING && theme !== THEMES.MINI),
    'px-3': (theme === THEMES.BILLING || theme === THEMES.MINI)
  });

  const useLightTheme = getColorTheme() === COLOR_THEMES.LIGHT;

  const isHighlightedFeature = feature => {
    // eslint-disable-next-line max-len
    const isAiAgentFeature = (global.window?.location?.pathname?.includes('/ai-agents/pricing/') || global.window?.location?.pathname?.includes('/ai/chatbot')) && ['aiAgent', 'aiConversation'].includes(feature);
    const isWorkflowFeature = workflowsPricing && [/* 'elementPerWorkflow', */'workflowRuns'].includes(feature);
    return (feature === 'perTeam' && teamsPricing) || (feature === 'signedDocuments' && signPricing) || (feature === 'apps' && appsPricing) || isAiAgentFeature || isWorkflowFeature;
  };

  // styles for FeatureBox
  const initializeFeatureBoxStyles = (feature, isLastItem = false) => {
    const featureBoxClasses = cx({
      'py-4': theme === THEMES.DEFAULT,
      'py-3': theme === THEMES.LITE || THEMES.BILLING,
      'highlight-feature': isHighlightedFeature(feature),
      dark: !useLightTheme
    });

    const featureBoxTitleClasses = cx('mx-2', {
      'text-2xl mb-2': theme !== THEMES.BILLING && theme !== THEMES.LITE && theme !== THEMES.MINI
    });

    const featureBoxInlineStyles = {};
    if (!isLastItem) {
      featureBoxInlineStyles.borderBottom = `1px solid ${columnColors.CONTENT_BORDER}`;
    }

    const featureBoxContentTextBottomColor = columnColors.CONTENT_TEXT_BOTTOM;

    return {
      featureBoxClasses, featureBoxTitleClasses, featureBoxInlineStyles, featureBoxContentTextBottomColor
    };
  };

  return (
    <div className={planContentClasses} style={{ background: contentBackgroundColor, color: contentTextColor }}>
      {
        features.map((
          {
            feature,
            count,
            info,
            Icon: FeatureIcon
          },
          index,
          array
        ) => {
          let title = <>{count}</>;
          let description = <>{t(info)}</>;

          if (feature === 'perTeam') {
            title = (
              <div className="flex items-center">
                {teamsPricing && (
                  <i style={{ color: TITLE_BG_COLOR }} className="mr-2">
                    <IconUsersFilled className="w-5 h-5 block" />
                  </i>
                )}
                {t(ONE_USER)}
              </div>
            );
          }

          if (feature === 'signedDocuments') {
            title = (
              <div className="flex justify-center items-center">
                {signPricing && (
                  <i style={{ color: TITLE_BG_COLOR }} className="mr-2">
                    <IconProductSignMono className="w-6 h-7 block" />
                  </i>
                )}
                {count}
              </div>
            );

            if (signPricing) {
              description = <>{t(MONTHLY_SIGNED_DOCUMENT_LIMIT)}</>;
            }
          }

          if (feature === 'apps' && appsPricing) {
            title = (
              <div className="flex justify-center items-center">
                <i style={{ color: TITLE_BG_COLOR }} className="mr-2">
                  <IconProductAppsMono className="w-6 h-7 block" />
                </i>
                {t(UNLIMITED)}
              </div>
            );
          }

          if (feature === 'hipaaCompliance') {
            const iconClasses = cx('inline-block w-11 h-12');
            if (count === 'false') {
              title = useLightTheme ? <IconHipaaDisabledLight className={iconClasses} /> : <IconHipaaDisabled className={iconClasses} />;
            } else {
              title = <IconHipaa className={iconClasses} />;
            }

            const Icon = useLightTheme ? IconInfoLight : IconInfo;
            description = (
              <>
                {t(count === 'true' ? HIPAA_COMPLIANCE_AVAILABLE : HIPAA_COMPLIANCE_NOT_AVAILABLE)}
                {
                  count === 'true' && ([country, countryCode, billingCountry].includes('US') || showHipaaInfo) && (
                    <span className="absolute top-4 right-0" role="button" aria-hidden="true">
                      <Icon className="w-6 h-6" onClick={handleHipaaInfoClicked} />
                    </span>
                  )
                }
              </>
            );
          }

          if (feature === 'gdpr') {
            const iconClasses = cx('inline-block w-10 h-10');
            title = <IconGDPR className={iconClasses} />;
            description = t(GDPR_FRIENDLY);
          }

          if (feature === 'branding') {
            const brandingTitleIconClasses = cx('inline-block w-7 h-8');

            if (planName === PLANS.FREE) {
              const Icon = useLightTheme ? IconJFBrandingLight : IconJFBranding;
              title = <Icon className={brandingTitleIconClasses} />;
            } else {
              const Icon = useLightTheme ? IconJFBrandingDisabledLight : IconJFBrandingDisabled;
              title = <Icon className={brandingTitleIconClasses} />;
            }

            const featureDescriptionClasses = cx('font-medium', {
              'text-sm': (theme !== THEMES.BILLING || theme !== THEMES.MINI),
              'text-xs': (theme === THEMES.BILLING || theme === THEMES.MINI)
            });

            const BrandingIcon = useLightTheme ? IconInfoLight : IconInfo;
            description = (
              <>
                <span className={`${featureDescriptionClasses} mb-4`} style={{ color: contentTextBottomColor }}>
                  {t(planName === PLANS.FREE ? JOTFORM_BRANDING : NO_BRANDING)}
                </span>
                {
                  planName === PLANS.FREE && (
                    <span className="absolute top-4 right-0" role="button" aria-hidden="true">
                      <BrandingIcon className="w-6 h-6" onClick={handleBrandingInfoClicked} />
                    </span>
                  )
                }
              </>
            );
          }

          if (isHighlightedFeature(feature) && FeatureIcon) {
            title = (
              <div className="flex justify-center items-center">
                <i style={{ color: TITLE_BG_COLOR }} className="mr-2">
                  <FeatureIcon className="w-6 h-7 block" />
                </i>
                {count}
              </div>
            );
          }

          return (
            <FeatureBox
              key={feature}
              title={title}
              description={description}
              tabIndex="0"
              {...initializeFeatureBoxStyles(feature, array.length - 1 === index)}
            />
          );
        })
      }
    </div>
  );
};

PlanContent.propTypes = {
  plan: PropTypes.object.isRequired,
  className: PropTypes.string
};

PlanContent.defaultProps = {
  className: ''
};

export default PlanContent;
