import React from 'react';
import { t } from '@jotforminc/translation';
import LeftTree from '../assets/svg/eoy/left-tree.png';
import RightTree from '../assets/svg/eoy/right-tree.png';
import Stars from '../assets/svg/eoy/stars.svg';
import '../styles/campaignStyles/eoy2023.scss';

export const EoyPromotionRenderer = () => (
  <section className='eoy-2023-limit-modal'>
    <div className="stars">
      <Stars />
    </div>
    <div className="left-tree">
      <img src={LeftTree} alt="" />
    </div>
    <div className="title-wrapper">
      <div className="fist-title">
        {t('SAVE 50%')}
      </div>
      <div className="second-title">
        {t('ON ANNUAL PLANS')}
        <sup>*</sup>
      </div>
    </div>
    <div className="right-tree">
      <img src={RightTree} alt="" />
    </div>
  </section>
);
