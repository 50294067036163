import { useAssetInfo, useAssetType } from './useCampaignInfo';

export const useFlyinUtm = ({
  directURL,
  customUtmCampaign
}) => {
  const assetType = useAssetType();
  const {
    utm_campaign: utmCampaign_
  } = useAssetInfo();

  const utmCampaign = customUtmCampaign || utmCampaign_;

  const utm = {
    campaign: utmCampaign,
    medium: 'website',
    content: ((window.location.pathname !== '/') ? window.location.pathname.replace(/\//g, '') : `${utmCampaign}-${assetType}`)
  };
  const { campaign, medium, content } = utm;
  const urlSearchParams = new URLSearchParams();

  const utmParams = {
    utm_source: [campaign?.toLowerCase(), assetType].filter(f => !!f).join('-'),
    utm_medium: medium,
    utm_content: content,
    utm_campaign: campaign?.toLowerCase()
  };

  Object.keys(utmParams).forEach(paramKey => {
    if (utmParams[paramKey]) {
      urlSearchParams.append(paramKey, utmParams[paramKey]);
    }
  });

  const url = `${directURL}?${urlSearchParams.toString()}`;

  return {
    url
  };
};
