export const handleShowExitModal = (event, show) => {
  const {
    target = {},
    clientX = 0,
    clientY = 0,
    relatedTarget,
    toElement
  } = event;
  const { tagName = '' } = target;
  if (tagName.toLowerCase() === 'input') return;

  const vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

  if (clientX >= (vpWidth - 50)) return;
  if (clientY >= 50) return;

  if (document.querySelector('body').classList.contains('standalone')) return;

  const from = relatedTarget || toElement;
  if (!from) show();
};
