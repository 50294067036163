import React from 'react';
import { string } from 'prop-types';

import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';

import './v1.scss';

import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';

const WorkflowsBannerV1 = ({
  accountTypeName
}) => {
  const {
    utm_campaign: utmCampaign
  } = useAssetInfo();

  const utmSource = `${utmCampaign}-mfhb-cta`;

  const handleClick = () => {
    handleCustomNavigation(`/products/workflows/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`, '_self', true);
  };

  return (
    <div className="jotform-workflows-container">
      <div id="campaign-container" className="">
        <div className="workflows-wrapper">
          <div className="workflows-container">
            <div className="image">
              <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/workflows/banner/workflows-img.png" alt="Banner" />
            </div>
            <div className="title-wrapper">
              <h5>{t('Announcing')}</h5>
              <h4>{t('Jotform Workflows')}</h4>
            </div>
            <a
              onClick={handleClick}
              className="action-link"
              href={`/products/workflows/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}
            >
              {t('Discover Now')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

WorkflowsBannerV1.propTypes = {
  accountTypeName: string.isRequired
};

export default WorkflowsBannerV1;
