import React, { useMemo } from 'react';
import { object, string } from 'prop-types';

import { handleCustomNavigation } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';
import { formatCurrency } from '@jotforminc/currency';

import './v1.scss';

import FlyIn from '../../../../../ui/FlyIn/FlyIn';

import { useFlyinUtm } from '../../../../../../hooks/useUtm';
import { useActionManager } from '../../../../../../hooks/useActionManager';

const CloseIconRenderer = () => <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/flyin/close.svg" alt="Close Icon" />;

const SilverOneFlyInV1 = ({
  isSticky,
  user = {},
  ...props
}) => {
  const {
    account_type: { currency = 'USD' } = {},
    language
  } = user;

  const { tickAction } = useActionManager();
  const oneDollarText = formatCurrency({
    value: 1,
    locale: language,
    currency
  });

  const currencyImage = useMemo(() => {
    switch (currency) {
      case 'GBP':
        return 'pound_4x';
      case 'EUR':
        return 'euro_4x';
      default:
        return 'coin';
    }
  }, [currency]);

  const { url } = useFlyinUtm({
    directURL: '/pricing/silver/'
  });

  const handleClick = () => {
    tickAction({ action: 'click', target: 'upgrade-now' });
    handleCustomNavigation(url, '_blank', true);
  };

  return (
    <FlyIn
      isSticky={isSticky}
      CloseIconRenderer={CloseIconRenderer}
      {...props}
    >
      <div className="flyin-content silver-flyin-wrapper" data-jfa="silver-flyin-wrapper">
        <div className="silver-flyin-wrapper-container">
          <div className="badge"><span className="badge-inner">{t('LIMITED TIME OFFER')}</span></div>
          <img
            className="money" src={`https://cdn.jotfor.ms/assets/img/campaigns/2024/one-dollar/${currencyImage}.png`}
            alt='Jotform Only One Campaign'
          />
          <span className="title-plan">{t('Silver Plan')}</span>
          <div className="title-area">
            <span className="title-silver">{t('Only {oneCurrencyUnit}').replace('{oneCurrencyUnit}', oneDollarText)}</span>
            <span className="title-for-a-year">{t('FOR A YEAR')}</span>
          </div>
          <button className="cta-button" type="button" onClick={handleClick}>{t('Upgrade Now')}</button>
        </div>
      </div>
    </FlyIn>
  );
};

SilverOneFlyInV1.propTypes = {
  isSticky: string.isRequired,
  user: object.isRequired
};

export default SilverOneFlyInV1;
