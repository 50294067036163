import React from 'react';
import { object } from 'prop-types';

import { t } from '@jotforminc/translation';
import { IconCheck, IconXmark } from '@jotforminc/svg-icons';

import {
  FORMS,
  HIPAA_COMPLIANCE_AVAILABLE,
  HIPAA_COMPLIANCE_NOT_AVAILABLE,
  NO_BRANDING
} from '../../../constants/texts';

const FeatureItem = ({ feature }) => {
  const { count, info, feature: name } = feature;

  if (name === 'formCount') {
    return (
      <li>
        <IconCheck color='#01BD6F' />
        <strong>
          {count.split(' ')[0]}
        </strong>
        <span>{t(FORMS)}</span>
      </li>
    );
  }

  if (name === 'hipaaCompliance') {
    return (
      <li>
        {count ? <IconCheck color='#01BD6F' /> : <IconXmark color='#DC2626' />}
        <strong>{t(count ? HIPAA_COMPLIANCE_AVAILABLE : HIPAA_COMPLIANCE_NOT_AVAILABLE)}</strong>
      </li>
    );
  }

  if (name === 'branding') {
    return (
      <li>
        <IconCheck color='#01BD6F' />
        <strong>{t(NO_BRANDING)}</strong>
      </li>
    );
  }

  return (
    <li>
      <IconCheck color='#01BD6F' />
      <strong>
        {count}
      </strong>
      <span>{t(info)}</span>
    </li>
  );
};

FeatureItem.propTypes = {
  feature: object.isRequired
};

export default FeatureItem;
