import React from 'react';
import { object } from 'prop-types';

import MyPlanBanner, { MyPlanBannerButton, MyPlanBannerPlanText } from '../../../../../ui/MyPlanBanner/MyPlanBanner';

const MyAccountMyPlanBanner = ({
  buttonProps,
  planTextProps,
  ...props
}) => {
  return (
    <MyPlanBanner
      style={{
        background: 'linear-gradient(142.43deg, #FFE6CF -16.97%, #FFEAB6 111.74%)'
      }}
      {...props}
    >
      <div className='flex'>
        <MyPlanBannerPlanText
          {...planTextProps}
        />
        <MyPlanBannerButton
          {...buttonProps}
        />
      </div>
    </MyPlanBanner>
  );
};

MyAccountMyPlanBanner.propTypes = {
  buttonProps: object.isRequired,
  planTextProps: object.isRequired
};

export default MyAccountMyPlanBanner;
