import React from 'react';
import { object } from 'prop-types';

import { t, translationRenderer } from '@jotforminc/translation';

import MyPlanBanner, { MyPlanBannerButton, MyPlanBannerPlanText } from '../../../../../ui/MyPlanBanner/MyPlanBanner';

import './styles.scss';

const MyAccountMyPlanBanner = ({
  buttonProps,
  planTextProps,
  ...props
}) => {
  return (
    <MyPlanBanner
      className='education-billing'
      {...props}
    >
      <div className='education-billing-wrapper'>
        <MyPlanBannerPlanText
          {...planTextProps}
        />
        <div className='education-billing-button'>
          <MyPlanBannerButton
            {...buttonProps}
          />
          <div className='education-billing-title-area'>
            <div className="education-billing-first">
              {t('Nonprofits')}
            </div>
            <div className="education-billing-second">
              {translationRenderer('SAVE [1[50%]]')({
                renderer1: text => (<strong>{text}</strong>)
              })}
            </div>
          </div>
          <div className='right-image'>
            <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/education/education.svg" alt="education" />
          </div>
        </div>
      </div>
    </MyPlanBanner>
  );
};

MyAccountMyPlanBanner.propTypes = {
  buttonProps: object.isRequired,
  planTextProps: object.isRequired
};

export default MyAccountMyPlanBanner;
