import React from 'react';
import { object } from 'prop-types';

import { t } from '@jotforminc/translation';
import { formatCurrency } from '@jotforminc/currency';

import './silverone_badge.scss';

// TODO: move it to growth asset manager
const OneDollarSilverBadge = ({
  user = {}
}) => {
  const {
    account_type: { currency = 'USD' } = {},
    language
  } = user;

  const oneDollarText = formatCurrency({
    value: 1,
    locale: language,
    currency
  });

  return (
    <div className="relative">
      <div className="silverone--badge">
        <div className="pulse flex flex-col justify-center items-center">
          {t('Only {oneCurrencyUnit}').replace('{oneCurrencyUnit}', oneDollarText)}
          <p>{t('AT CHECKOUT')}</p>
        </div>
      </div>
    </div>
  );
};

OneDollarSilverBadge.propTypes = {
  user: object.isRequired
};

export default OneDollarSilverBadge;
