import React from 'react';
import { node, string } from 'prop-types';

import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { useAssetInfo } from '../../../hooks/useCampaignInfo';
import './my-plan-banner.scss';

const MyPlanBannerTitle = ({
  title
}) => {
  return (
    <h2
      className='jfRHeader--my-plan-banner--title font-bold sm:w-56 w-full shrink-0 pr-14 rtl:pr-0 rtl:pl-14 sm:pr-2 break-word relative z-2 gap-1 flex items-center text-md'
    >
      {t(title)}
    </h2>
  );
};

MyPlanBannerTitle.propTypes = {
  title: string.isRequired
};

export const MyPlanBannerPlanText = props => {
  return (
    <span
      className='jfRHeader--my-plan-banner--text flex items-center justify-start whitespace-nowrap'
      data-testid="user-current-plan"
      {...props}
    />
  );
};

export const MyPlanBannerButton = ({
  colorStyle = 'success',
  text = 'Change Plan',
  ...props
}) => {
  return (
    <Button
      {...props}
      size="small"
      colorStyle={colorStyle}
      aria-expanded={false}
      aria-label={t(text)}
      data-testid="change-plan-button"
    >
      {t(text)}
    </Button>
  );
};

MyPlanBannerButton.propTypes = {
  colorStyle: string,
  text: string
};

MyPlanBannerButton.defaultProps = {
  colorStyle: 'success',
  text: 'Change Plan'
};

const MyPlanBanner = ({
  children,
  className: classNameProp,
  title = 'My Plan',
  ...props
}) => {
  const {
    type: campaignType
  } = useAssetInfo();

  const className = `jfRHeader--my-plan-banner flex flex-col w-full items-start sm:items-center px-5 sm:px-7 py-5 sm:py-4 sm:flex-row space-y-2 sm:space-y-0 ${classNameProp}`;
  return (
    <div
      className={className}
      data-campaign-type={campaignType}
      {...props}
    >
      <MyPlanBannerTitle
        title={title}
      />
      <div className='flex'>
        {children}
      </div>
    </div>
  );
};

MyPlanBanner.propTypes = {
  children: node.isRequired,
  className: string,
  title: string
};

MyPlanBanner.defaultProps = {
  className: '',
  title: 'My Plan'
};

export default MyPlanBanner;
