import React, { useEffect } from 'react';
import { string } from 'prop-types';

import { t, translationRenderer } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';

import './v2.scss';

import CountDown from '../../../../../ui/CountDown/CountDown';

import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';
import { useActionManager } from '../../../../../../hooks/useActionManager';

const NewUserBannerV2 = ({
  accountTypeName, user, expires, ...props
}) => {
  const {
    utm_campaign: utmCampaign,
    expires: {
      new_users_campaign: expirationDate
    }
  } = useAssetInfo();

  const { tickAction } = useActionManager();

  const utmSource = `${utmCampaign}-mfhb-cta`;
  if (!document.body.classList.contains('gamNewUser-body')) {
    document.body.classList.add('gamNewUser-body');
  }

  const handleClick = () => {
    tickAction({ action: 'click', target: 'save-now' });
    handleCustomNavigation(`/pricing?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`, '_self', true);
  };

  useEffect(() => {
    tickAction({ action: 'seen' });
  }, []);

  return (
    <div className="uk-campaign-container" {...props}>
      <div className="uk-campaign-content-container">
        <div className="heading-counter-wrapper">
          <div className="heading">
            <strong className="title">{t('TODAY ONLY!')}</strong>
            <div className="discount">
              {translationRenderer('[1[SAVE]] [2[50]][3[%]]')({
                renderer1: save => <strong key={save} className="second-title">{save}</strong>,
                renderer2: rate => <strong key={rate} className="third-title">{rate}</strong>,
                renderer3: percent => <strong key={percent} className="four-title">{percent}</strong>
              })}
            </div>
          </div>
          <div className="counter">
            <span className="counter-title locale white">{t('Offer expires in')}</span>
            <strong id="countdown-for-newuser">
              <CountDown expires={expires || expirationDate} />
            </strong>
          </div>
        </div>
        <div className="item button">
          <a
            onClick={handleClick}
            className="locale"
            href={`/pricing?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}
          >
            {t('Save Now')}
          </a>
        </div>
      </div>
    </div>
  );
};

NewUserBannerV2.propTypes = {
  accountTypeName: string.isRequired,
  user: string.isRequired,
  expires: string
};

NewUserBannerV2.defaultProps = {
  expires: null
};

export default NewUserBannerV2;
