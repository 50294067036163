import React from 'react';
import { string } from 'prop-types';

import { t } from '@jotforminc/translation';

import './v1.scss';

import FlyIn from '../../../../../ui/FlyIn/FlyIn';

const GovernmentlaunchFlyInV1 = ({ isSticky, ...props }) => {
  const isEN = window?.user?.language === 'en-US' || 'en-US';

  const handleClose = () => {
    console.log('action: flyin close');
  };

  return (
    <FlyIn
      onClose={handleClose}
      isSticky={isSticky}
      {...props}
    >
      <div className="government-flyin-wrapper" data-jfa="government-flyin">
        <div className="government-flyin-wrapper-container">
          <div className="title-area">
            {isEN ? (
              <span className='logo-government'>
                Announcing
                <img src='https://cdn.jotfor.ms/assets/img/campaigns/2024/government/logo-government.svg' alt='Jotform Government' />
              </span>
            ) : (
              <div>
                <img className="icon-logo" src='https://cdn.jotfor.ms/assets/img/campaigns/2024/government/icon-logo.svg' alt='Jotform Government' />
                <span class="text-announcing">
                  {t('Announcing')}
                  <strong>JOTFORM GOVERNMENT</strong>
                </span>
              </div>
            )}
          </div>
          <a className="action-link" href="/government/?utm_source=government-2024-flyin-landing&utm_medium=website&utm_content=photography&utm_campaign=government-2024">
            {t('Discover Now')}
          </a>
        </div>
      </div>
    </FlyIn>
  );
};

GovernmentlaunchFlyInV1.propTypes = {
  isSticky: string.isRequired
};

export default GovernmentlaunchFlyInV1;
