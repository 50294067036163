import isEmpty from 'lodash/isEmpty';

import { formatBytes, getUrlParameter } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';
import {
  IconAiColor,
  IconUsersMoreFilled,
  IconProductApprovalsColor
} from '@jotforminc/svg-icons';

import { CAMPAIGN_TYPES, ORDERED_PLAN_LIST, PLANS } from './constants';
import {
  AVAILABLE_SPACE,
  FIELDS_PER_FORM,
  FORM_LIMIT,
  FORMCOUNT_FORMS,
  FORMS_REPORTS_FIELDS_PER_FORM,
  MONTHLY_FORM_VIEWS,
  MONTHLY_PAYMENT_SUBMISSIONS,
  MONTHLY_SIGNED_DOCUMENTS,
  MONTHLY_SUBMISSIONS,
  REPORTS_FIELDS_PER_FORM,
  TOTAL_SUBMISSION_STORAGE,
  UNLIMITED,
  MONTHLY_WORKFLOW_RUNS,
  PER_TEAM,
  AGENTCOUNT_AGENTS,
  AGENT_LIMIT,
  MONTHLY_CONVERSATIONS,
  AI_AGENTS,
  AI_CONVERSATIONS,
  AGENTCOUNT_CHATBOT,
  CHATBOT_LIMIT
} from './constants/texts';

export const isUnlimited = ({ value, feature, planName }) => (
  planName === PLANS.ENTERPRISE
    ? true
    : value === null || value === undefined || value === 0 || value === -1 || value === 10000000 || (feature === 'payments' && value === 100000)
);

export const getPrettyLimits = ({
  t: translate,
  visibleFeatures,
  hideAllLimits,
  language,
  limits,
  showFormLimit,
  showHipaa,
  showFieldsPerFormLimit,
  showGDPR,
  campaignType,
  pullUpHipaaBadge
}) => {
  const isAILimitsEnabled = getUrlParameter('agent-limits') === '1';

  if (hideAllLimits) return [];

  const result = ([
    { feature: 'formCount', info: FORM_LIMIT },
    { feature: 'submissions', info: MONTHLY_SUBMISSIONS },
    { feature: 'perTeam', info: PER_TEAM },
    { feature: 'uploads', info: AVAILABLE_SPACE },
    { feature: 'views', info: MONTHLY_FORM_VIEWS },
    { feature: 'totalSubmissions', info: TOTAL_SUBMISSION_STORAGE },
    { feature: 'fieldPerForm', info: FIELDS_PER_FORM },
    { feature: 'payments', info: MONTHLY_PAYMENT_SUBMISSIONS },
    { feature: 'signedDocuments', info: MONTHLY_SIGNED_DOCUMENTS }
  ]).concat(isAILimitsEnabled ? [
    { feature: 'aiAgents', info: AI_AGENTS },
    { feature: 'aiConversations', info: AI_CONVERSATIONS }
  ] : [])
    // .concat(limits.elementPerWorkflow ? [{ feature: 'elementPerWorkflow', info: ELEMENTS_PER_WORKFLOW, Icon: IconFlowForkVerticalRadiusLine }] : [])
    .concat(
      limits.workflowRuns || global.window?.location?.pathname?.includes('/products/workflows/pricing/') || global.window?.location?.pathname?.includes('/products/approvals/pricing/')
        ? [{ feature: 'workflowRuns', info: MONTHLY_WORKFLOW_RUNS, Icon: IconProductApprovalsColor }]
        : []
    )
    .concat(
    // eslint-disable-next-line no-nested-ternary
      !showFieldsPerFormLimit
        ? (
          showFormLimit
            ? [{ feature: 'reportsFieldsPerForm', info: REPORTS_FIELDS_PER_FORM }]
            : [{ feature: 'formsReportsFieldsPerForm', info: FORMS_REPORTS_FIELDS_PER_FORM }]
        )
        : []
    )
    .concat(showHipaa ? [{ feature: 'hipaaCompliance' }] : [])
    .concat(showGDPR ? [{ feature: 'gdpr' }] : [])
    .filter(({ feature }) => (showFormLimit ? true : feature !== 'formCount'))
    .filter(({ feature }) => (showFormLimit && showFieldsPerFormLimit ? true : feature !== 'fieldPerForm'))
    .filter(({ feature }) => (isEmpty(visibleFeatures) ? true : visibleFeatures.includes(feature)))
    .filter(({ feature }) => (feature === 'signedDocuments' ? !!limits[feature] : true))
    .map(limitObj => {
      const { feature } = limitObj;
      const limit = limits[feature];

      if (feature === 'formCount') {
        return { ...limitObj, count: isUnlimited({ value: limit, feature }) ? translate(UNLIMITED) : translate(FORMCOUNT_FORMS).replace('{formCount}', limit.toLocaleString(language)) };
      }

      if (feature === 'uploads') {
        return { ...limitObj, count: formatBytes(limit) };
      }

      return { ...limitObj, count: isUnlimited({ value: limit, feature }) ? translate(UNLIMITED) : limit.toLocaleString(language) };
    });

  if (campaignType === CAMPAIGN_TYPES.HIPAAGOLD || pullUpHipaaBadge) {
    const hipaaFeature = result.find(({ feature }) => feature === 'hipaaCompliance');

    let newArr = [];

    if (hipaaFeature) {
      newArr = [hipaaFeature, ...result.filter(({ feature }) => feature !== 'hipaaCompliance')];
    } else {
      newArr = result;
    }

    return newArr;
  }

  // TODO :: It'll be remove after chatbot release
  const isAIChatBot = global.window?.location?.pathname?.includes('/ai/chatbot');

  if (global.window?.location?.pathname?.includes('/ai-agents/pricing/') || isAIChatBot) {
    const aiAgentsLimit = limits.aiAgents !== undefined ? limits.aiAgents : limits.formCount;
    const aiConversationsLimit = limits.aiConversations !== undefined ? limits.aiConversations : limits.views;

    return [
      {
        feature: 'aiAgent',
        info: isAIChatBot ? CHATBOT_LIMIT : AGENT_LIMIT,
        count: isUnlimited({ value: aiAgentsLimit, feature: 'aiAgent' })
          ? translate(UNLIMITED)
          : translate(isAIChatBot ? AGENTCOUNT_CHATBOT : AGENTCOUNT_AGENTS).replace('{agentCount}', aiAgentsLimit.toLocaleString(language)),
        Icon: IconUsersMoreFilled
      },
      {
        feature: 'aiConversation',
        info: MONTHLY_CONVERSATIONS,
        count: aiConversationsLimit.toLocaleString(language),
        Icon: IconAiColor
      },
      ...result
    ];
  }
  return result;
};

export const arrayObjToFirst = (arr, item, key) => {
  const b = arr.filter(e => e[item] === key);
  const c = arr.filter(e => e[item] !== key);
  const d = [...b, ...c];
  return d;
};

export const comparePlans = (planA, planB) => {
  if (ORDERED_PLAN_LIST.indexOf(planA) < ORDERED_PLAN_LIST.indexOf(planB)) return -1;
  if (ORDERED_PLAN_LIST.indexOf(planA) === ORDERED_PLAN_LIST.indexOf(planB)) return 0;
  if (ORDERED_PLAN_LIST.indexOf(planA) > ORDERED_PLAN_LIST.indexOf(planB)) return 1;
};

export const translateLongDate = date => {
  // Apr 1, 2023
  if (!date || date.length < 2) return;

  const dates = date.split(' ');
  if (dates[1].indexOf(',') > 0) {
    dates[1] = dates[1].replace(',', '');
  }
  if (dates[1].indexOf('st') > 0) {
    dates[1] = dates[1].replace('st', '');
  }
  if (dates[1].indexOf('nd') > 0) {
    dates[1] = dates[1].replace('nd', '');
  }
  if (dates[1].indexOf('rd') > 0) {
    dates[1] = dates[1].replace('rd', '');
  }
  if (dates[1].indexOf('th') > 0) {
    dates[1] = dates[1].replace('th', '');
  }

  return `${t(dates[0])} ${dates[1]}, ${dates[2]}`;
};
