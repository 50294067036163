import React from 'react';
import { string } from 'prop-types';

import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';

import HeaderBanner from '../../../../../ui/HeaderBanner/HeaderBanner';
import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';

import './v4.scss';

const BlackFridayBannerV4 = ({ accountTypeName }) => {
  const {
    utm_campaign: utmCampaign
  } = useAssetInfo();

  const utmSource = `${utmCampaign}-mfhb-cta`;

  const handleClick = () => {
    handleCustomNavigation(`/pricing/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`, '_self', true);
  };
  return (
    <HeaderBanner>
      <section className='blackfriday-2024-wrapper'>
        <div className='blackfriday-2024-container'>
          <div className='title-wrapper'>
            <div className="title-percent">
              <span>{t('50% OFF')}</span>
              <span>{t('BLACK FRIDAY')}</span>
            </div>
            <div className="badge">
              <span className="badge-inner">
                <p>{t('GET EXTRA 20% OFF AT CHECKOUT')}</p>
              </span>
            </div>
            <div className="center-area">
              <div className="image-2">
                <img width="116" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/myforms-header-banner/sale-bag.png" alt="Jotform Black Friday Bag" />
              </div>
            </div>
          </div>
          <a
            className="locale upgrade-now-button" onClick={handleClick}
            href={`/pricing/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}
          >
            {t('Upgrade Now')}
          </a>
        </div>
      </section>
    </HeaderBanner>
  );
};

BlackFridayBannerV4.propTypes = {
  accountTypeName: string.isRequired
};

export default BlackFridayBannerV4;
