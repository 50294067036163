import React from 'react';
import cx from 'classnames';
import { t } from '@jotforminc/translation';
import { capitalizeAccToLocale as capitalize } from '@jotforminc/utils';

import { POPULAR } from '../constants/texts';
import { THEMES } from '../constants';

import { usePricingContext } from '../context/pricingTableContext';

import '../styles/best_value.scss';

const PopularRibbon = () => {
  const {
    getUserLanguage,
    state: {
      theme
    }
  } = usePricingContext();

  const language = getUserLanguage();

  const bestValueClasses = cx('pricingBox-ribbon-belt', {
    themeDefault: theme === THEMES.DEFAULT,
    themeLite: theme === THEMES.LITE,
    themeBilling: theme === THEMES.BILLING,
    themeMini: theme === THEMES.MINI
  });

  return (
    <div className={bestValueClasses}>
      <div className="pricingBox-ribbon-shadow">
        <div className="pricingBox-ribbon-content">
          <div className="triangle-behind" />
          <div className="triangle-sides-shadow" />
          <div className="triangle-sides" />
          <div className="text-content">{capitalize(t(POPULAR), language)}</div>
          <div className="triangle-after" />
        </div>
      </div>
    </div>
  );
};

export default PopularRibbon;
