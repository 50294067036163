import React, { useEffect, useRef } from 'react';
import { node } from 'prop-types';
import { useAssetInfo } from '../../../hooks/useCampaignInfo';
import './header-banner.scss';
import { useActionManager } from '../../../hooks/useActionManager';

const HeaderBanner = ({ children, ...props }) => {
  const HeaderBannerRef = useRef(null);
  const { type: campaignType } = useAssetInfo();
  const { tickAction } = useActionManager();

  useEffect(() => {
    if (!document.querySelector('body').classList.contains('jf-header-banner')) {
      document.querySelector('body').classList.add('jf-header-banner');
      document.querySelector('body').classList.add(`jf-header-banner--${campaignType.toLowerCase()}`);
    }
  }, []);

  useEffect(() => {
    tickAction({ action: 'seen' });
  }, []);

  return (
    <div
      ref={HeaderBannerRef}
      className="jfRHeader--header-banner"
      data-campaign-type={campaignType}
      {...props}
    >
      {children}
    </div>
  );
};

HeaderBanner.propTypes = {
  children: node.isRequired
};

export default HeaderBanner;
