import React, { useEffect, useRef, useState } from 'react';
import { elementType, node, string } from 'prop-types';

import { IconXmark } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { StorageHelper } from '@jotforminc/storage-helper';

import './flyin.scss';

import { getDismissStorageItemKey } from '../../../utils/campaign';

import { useAssetInfo, useAssetType } from '../../../hooks/useCampaignInfo';
import { useActionManager } from '../../../hooks/useActionManager';

const FlyIn = ({
  isSticky,
  onClose,
  children,
  CloseIconRenderer,
  ...props
}) => {
  const FlyInRef = useRef(null);
  const {
    type: campaignType,
    assetsVersion
  } = useAssetInfo();
  const assetType = useAssetType();
  const { tickAction } = useActionManager();

  const [isShow, setIsShow] = useState(true);

  const dismissKey = getDismissStorageItemKey({
    assetType,
    assetsVersion,
    campaignType
  });

  const handleClose = () => {
    onClose();
    tickAction({ action: 'closed' });
    StorageHelper.setLocalStorageItem({
      key: dismissKey,
      value: '1',
      ttl: 1000 * 60 * 60 * 24
    });
    document.querySelector('body').classList.remove('jf-flyin--isSticky');
    setIsShow(false);
  };

  useEffect(() => {
    if (!isSticky) {
      document.querySelector('body').classList.add('jf-flyin--isSticky');
      setIsShow(true);
    } else {
      document.querySelector('body').classList.remove('jf-flyin--isSticky');
      setIsShow(false);
    }
  }, [isSticky]);

  useEffect(() => {
    if (isShow) {
      document.querySelector('body').classList.add('jf-flyin');
    }
  }, [isShow]);

  useEffect(() => {
    if (isShow) {
      tickAction({ action: 'seen' });
    }
  }, [isShow]);

  if (!isShow) {
    return null;
  }

  return (
    <div
      ref={FlyInRef}
      className="jfRHeader--flyin"
      data-campaign-type={campaignType}
      {...props}
    >
      <div className="jfRHeader--flyin-inner">{children}</div>
      <button
        type="button"
        className="jfRHeader--flyin-close-btn"
        aria-label={t('Close')}
        onClick={handleClose}
      >
        <CloseIconRenderer />
      </button>
    </div>
  );
};

FlyIn.propTypes = {
  children: node.isRequired,
  isSticky: string.isRequired,
  CloseIconRenderer: elementType,
  onClose: () => {}
};

FlyIn.defaultProps = {
  onClose: () => {},
  CloseIconRenderer: IconXmark
};

export default FlyIn;
