import React, { useMemo } from 'react';
import { string, object } from 'prop-types';

import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';
import { formatCurrency } from '@jotforminc/currency';

import HeaderBanner from '../../../../../ui/HeaderBanner/HeaderBanner';
import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';

import './v1.scss';
import { useActionManager } from '../../../../../../hooks/useActionManager';

const OneDollarBannerV1 = ({
  accountTypeName,
  user = {}
}) => {
  const {
    utm_campaign: utmCampaign
  } = useAssetInfo();

  const {
    account_type: { currency = 'USD' } = {},
    language
  } = user;

  const { tickAction } = useActionManager();

  const oneDollarText = formatCurrency({
    value: 1,
    locale: language,
    currency
  });

  const currencyImage = useMemo(() => {
    switch (currency) {
      case 'GBP':
        return 'pound_4x';
      case 'EUR':
        return 'euro_4x';
      default:
        return 'coin';
    }
  }, [currency]);

  const utmSource = `${utmCampaign}-mfhb-cta`;

  const handleClick = () => {
    tickAction({ action: 'click', target: 'upgrade-now' });
    handleCustomNavigation(`/pricing/silver/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`, '_self', true);
  };

  return (
    <HeaderBanner>
      <div className="one-dollar-campaign-wrapper">
        <div className="one-dollar-campaign-container">
          <div className="badge">
            <div className="badge-inner">
              {t('LIMITED TIME OFFER')}
            </div>
          </div>
          <div className="coin">
            <img src={`https://cdn.jotfor.ms/assets/img/campaigns/2024/one-dollar/${currencyImage}.png`} alt="Money" />
          </div>
          <div className="title-wrapper">
            <div className="title-1">
              {t('Silver Plan')}
            </div>
            <div className="title-2">
              <div className="first">
                {t('Only {oneCurrencyUnit}').replace('{oneCurrencyUnit}', oneDollarText)}
              </div>
              <div className="second">
                {t('FOR A YEAR')}
                *
              </div>
            </div>
          </div>
          <a
            onClick={handleClick}
            className="action-link"
            href={`/pricing/silver/yearly/?utm_campaign=onedollarsilver&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}
          >
            {t('Upgrade now')}
          </a>
        </div>
      </div>
    </HeaderBanner>
  );
};

OneDollarBannerV1.propTypes = {
  accountTypeName: string.isRequired,
  user: object.isRequired
};

export default OneDollarBannerV1;
