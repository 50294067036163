import React from 'react';
import { object } from 'prop-types';

import { t } from '@jotforminc/translation';

import './v2.scss';

import AccountBox from '../../../../../ui/AccountBox/AccountBox';

const RegularFiftyDiscountAccountBox = ({ user }) => {
  return (
    <AccountBox user={user}>
      <div className="spring-sale-wrapper">
        <div className="spring-sale-wrapper-image">
          <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/spring-sale/account-box/flowers.svg" alt="Flowers" />
        </div>
        <div className='spring-sale-title-wrapper'>
          <div className="spring-sale-wrapper-title">
            {t('SUMMER SALE')}
          </div>
          <div className='spring-sale-wrapper-badge'>
            {t('SAVE 50%')}
          </div>
        </div>
      </div>
    </AccountBox>
  );
};

RegularFiftyDiscountAccountBox.propTypes = {
  user: object.isRequired
};

export default RegularFiftyDiscountAccountBox;
