import React, { useMemo } from 'react';
import { string } from 'prop-types';
import cx from 'classnames';

import { t } from '@jotforminc/translation';

import { COLOR_THEMES, PLAN_COLUMN_COLORS, SIGN_PLAN_COLORS } from '../../constants';

import pricingTableManager from '../../hocs/pricingTableManager';
import { usePricingTable } from '../../hooks/usePricingTable';

import PlanHeader from './PlanHeader';
import PlanCta from './PlanCTA';
import FeatureItem from './FeatureItem';

import Notice from '../../components/Notice';
import Switch from '../../components/Switch';
import Disclaimers from '../../components/Disclaimers';

const Plan = ({ planName }) => {
  const {
    showHIPAABadgeOnHeader,
    clickableColumn,
    theme,
    isBottomCTAVisible,
    showAllFeaturesButton,
    getPlan
  } = usePricingTable();

  const {
    isBestValue,
    features,
    onPlanContainerClicked,
    onSeeAllFeaturesClicked
  } = getPlan(planName);

  const PLAN_COLORS = isBestValue ? SIGN_PLAN_COLORS.BEST_PLAN : SIGN_PLAN_COLORS[planName];

  const listClasses = cx('w-full max-w-72 radius-lg md:w-52 sm:w-60 xxl:w-68 p-1 mx-1 mt-6', {
    '': !isBestValue && !showHIPAABadgeOnHeader,
    'best-value': isBestValue
  });

  const buttonClasses = cx('text-center w-full h-full flex flex-col', {
    'cursor-pointer': clickableColumn
  });

  const palette = useMemo(() => {
    return theme === COLOR_THEMES.LIGHT ? PLAN_COLUMN_COLORS.LIGHT : PLAN_COLUMN_COLORS.DEFAULT;
  }, [theme]);

  const handleSeeAllFeaturesClicked = event => {
    event.stopPropagation();
    onSeeAllFeaturesClicked();
  };

  const handleColumnClick = () => {
    if (!clickableColumn) return;
    onPlanContainerClicked({ clickPoint: 'plan-container' });
  };

  return (
    <li
      className={listClasses}
      data-testid={`pricing-table-${planName.toLowerCase()}-column`}
    >
      <div
        className={buttonClasses}
        onClick={handleColumnClick}
        aria-label={`${planName}`}
      >
        <div
          className='shadow-light-xs radius'
          style={{
            backgroundColor: PLAN_COLORS.BACKGROUND
          }}
        >
          <PlanHeader
            planName={planName}
            palette={palette}
          />
          <div
            className='w-full flex-1 radius-bl radius-br px-3 pt-2' style={{
              color: PLAN_COLORS.CONTENT_TEXT
            }}
          >
            {features.map((feature, featureIndex) => (
              <FeatureItem
                key={feature.info}
                feature={feature}
                isLastItem={featureIndex !== features.length - 1}
                planName={planName}
                palette={palette}
              />
            ))}
            {showAllFeaturesButton && (
              <button
                type='button'
                className="w-full py-4 text-md font-medium"
                onClick={handleSeeAllFeaturesClicked}
              >
                <span>{t('See all features')}</span>
              </button>
            )}
          </div>
        </div>
        {isBottomCTAVisible && (
          <PlanCta
            planName={planName}
            isTopCta={false}
          />
        )}
      </div>
    </li>
  );
};

Plan.propTypes = {
  planName: string.isRequired
};

const LightESignPricingWithFeatures = () => {
  const {
    visiblePlanNames,
    showPeriodSwitchToggle,
    hideNoticeBox,
    campaignStatus
  } = usePricingTable();

  const tableWrapperClasses = cx('w-min m-auto min-w-full', {
    'md:min-w-md': !campaignStatus
  });

  return (
    <div
      className={tableWrapperClasses}
      data-testid="pricing-table-container"
    >
      {showPeriodSwitchToggle && <Switch />}
      {!hideNoticeBox && <Notice />}
      <ul className="flex flex-wrap xl:flex-nowrap justify-center gap-4 md:gap-0 w-full mt-2 list-none">
        { visiblePlanNames.map(planName => <Plan planName={planName} key={planName} />) }
      </ul>
      <Disclaimers />
    </div>
  );
};

export default pricingTableManager(LightESignPricingWithFeatures);
