/* eslint-disable complexity */
import React from 'react';
import { object, bool } from 'prop-types';
import cx from 'classnames';
import upperFirst from 'lodash/upperFirst';
import { t } from '@jotforminc/translation';
import { capitalizeAccToLocale as capitalize } from '@jotforminc/utils';

import { PLANS, THEMES } from '../constants';
import { STARTER } from '../constants/texts';

import { usePricingContext } from '../context/pricingTableContext';

import Price from './Price';
import BestValueRibbon from './BestValueRibbon';
import PopularRibbon from './PopularRibbon';
import DealCounter from './DealCounter';
import PlanNote from './PlanNote';

import HIPAAHeaderBadge from '../assets/svg/iconHeaderHipaa.svg';

const PlanHeader = ({ plan, showCampaignPrice }) => {
  const {
    state: {
      campaignStatus,
      theme,
      showHIPAABadgeOnHeader
    },
    getColumnColors,
    getUserLanguage,
    getPlanColors,
    showDealCounterSelector,
    showBestValueBadgeSelector,
    showPopularBadgeSelector
  } = usePricingContext();

  const language = getUserLanguage();
  const planColors = getPlanColors();

  const { prettyName, name: planName } = plan;

  const showDealCounter = showDealCounterSelector({ plan });
  const showBestValueBadge = showBestValueBadgeSelector({ plan });
  const showPopularBadge = showPopularBadgeSelector({ plan });

  const {
    HEADER: headerBackgroundColor,
    HEADER_TEXT: headerTextColor
  } = getColumnColors();

  const { COLOR: TITLE_BG_COLOR, TEXT: TITLE_TEXT_COLOR } = planColors[planName];

  const titleClasses = cx('relative flex justify-center items-center w-full font-bold radius-tl-lg radius-tr-lg',
    {
      'pt-4 mt-2': !!showBestValueBadge,
      'color-white': !TITLE_TEXT_COLOR,
      'text-2xl': theme === THEMES.DEFAULT,
      'text-xl': theme !== THEMES.DEFAULT
    });

  const titleTextClasses = cx({
    'my-4': theme === THEMES.DEFAULT,
    'my-3': theme !== THEMES.DEFAULT
  });

  const headerClasses = cx('flex flex-col justify-center items-center w-full text-sm', {
    'min-h-32': theme === THEMES.DEFAULT || THEMES.BILLING,
    'min-h-24': theme === THEMES.LITE,
    'min-h-40 py-4': theme === THEMES.MINI && campaignStatus
  });

  const planHeaderClasses = cx('w-full', {
    'sticky top-2 z-2': theme === THEMES.LITE
  });

  const priceContentClasses = cx('mx-2 font-bold mt-4', {
    'text-5xl': (theme !== THEMES.BILLING && theme !== THEMES.MINI),
    'text-3xl': (theme === THEMES.BILLING || theme === THEMES.MINI)
  });

  const renderPriceContent = () => {
    if (planName === PLANS.FREE) return <strong className={priceContentClasses}>{capitalize(t(prettyName), language)}</strong>;

    return <Price plan={plan} showCampaignPrice={showCampaignPrice} />;
  };

  const HIPAABadge = () => {
    if (showHIPAABadgeOnHeader) {
      return (
        <>
          <div className="absolute -top-2 left-2 z-1">
            <HIPAAHeaderBadge className="w-10 h-auto block" />
          </div>
          <div className="absolute -top-2 inset-x-0 h-2 bg-white" />
        </>
      );
    }
    return null;
  };

  return (
    <div className={planHeaderClasses}>
      {showDealCounter && <DealCounter theme={theme} plan={plan} />}
      {theme === THEMES.LITE && <div className="absolute bg-white -top-2 inset-x-0 h-4" />}
      <div className={titleClasses} style={{ backgroundColor: TITLE_BG_COLOR, color: TITLE_TEXT_COLOR }}>
        <span className={titleTextClasses}>
          {t(planName === PLANS.FREE ? STARTER : upperFirst(prettyName))}
        </span>
        { showBestValueBadge && <BestValueRibbon /> }
        { showPopularBadge && <PopularRibbon /> }
        {HIPAABadge()}
      </div>
      { planName !== PLANS.ENTERPRISE && (
        <div className={headerClasses} style={{ backgroundColor: headerBackgroundColor, color: headerTextColor }}>
          <span className={planName === PLANS.FREE ? 'flex flex-col' : 'text-xl'}>
            { renderPriceContent() }
          </span>
          <PlanNote plan={plan} />
        </div>
      ) }
    </div>
  );
};

PlanHeader.propTypes = {
  plan: object.isRequired,
  showCampaignPrice: bool
};

PlanHeader.defaultProps = {
  showCampaignPrice: false
};

export default PlanHeader;
