import React from 'react';
import { object } from 'prop-types';

import { t, translationRenderer } from '@jotforminc/translation';

import MyPlanBanner, { MyPlanBannerButton, MyPlanBannerPlanText } from '../../../../../ui/MyPlanBanner/MyPlanBanner';

import './styles.scss';

const MyAccountMyPlanBanner = ({
  buttonProps,
  planTextProps,
  ...props
}) => {
  return (
    <MyPlanBanner
      className='eoy-2023--wrapper'
      {...props}
    >
      <div className='eoy-2023--badge'>
        <MyPlanBannerPlanText
          {...planTextProps}
        />
        <MyPlanBannerButton
          {...buttonProps}
        />
        <div className="eoy-2023--badge-text">
          <strong>
            {translationRenderer('SAVE [1[50%]]')({
              renderer1: text => (<b>{text}</b>)
            })}
          </strong>
          <strong>{t('ON ANNUAL PLANS')}</strong>
        </div>
      </div>
    </MyPlanBanner>
  );
};

MyAccountMyPlanBanner.propTypes = {
  buttonProps: object.isRequired,
  planTextProps: object.isRequired
};

export default MyAccountMyPlanBanner;
