import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { THEMES } from '../constants';

import { usePricingContext } from '../context/pricingTableContext';

const FeatureBox = ({
  title,
  description,
  letterSpacing,
  featureBoxClasses,
  featureBoxTitleClasses,
  featureBoxInlineStyles,
  featureBoxContentTextBottomColor
}) => {
  const {
    state: {
      theme
    }
  } = usePricingContext();

  const featureBoxClasses_ = cx(
    'relative flex justify-center items-center text-sm',
    featureBoxClasses
  );

  const featureBoxTitleClasses_ = cx(
    'font-bold block flex justify-center items-center',
    {
      'text-lg': (theme === THEMES.BILLING || theme === THEMES.MINI),
      '-mb-1': theme === THEMES.MINI
    },
    featureBoxTitleClasses
  );

  const featureBoxDescriptionClasses = cx('font-medium', {
    'text-sm': theme !== THEMES.BILLING,
    'text-xs': theme === THEMES.BILLING || theme === THEMES.LITE
  });

  return (
    <div className={featureBoxClasses_} style={featureBoxInlineStyles}>
      <span className="flex flex-col space-y-1">
        <strong className={featureBoxTitleClasses_} style={{ letterSpacing }}>
          {title}
        </strong>
        <span className={featureBoxDescriptionClasses} style={{ color: featureBoxContentTextBottomColor, letterSpacing }}>
          {description}
        </span>
      </span>
    </div>
  );
};

FeatureBox.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  letterSpacing: PropTypes.string,
  featureBoxClasses: PropTypes.string.isRequired,
  featureBoxTitleClasses: PropTypes.string.isRequired,
  featureBoxInlineStyles: PropTypes.object.isRequired,
  featureBoxContentTextBottomColor: PropTypes.string
};

FeatureBox.defaultProps = {
  letterSpacing: undefined,
  featureBoxContentTextBottomColor: undefined
};

export default FeatureBox;
