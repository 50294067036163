import React from 'react';
import { string } from 'prop-types';

import NewUserBannerV2 from '../../newuser/banner/v2';
import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';

const OverQuotaBanner = ({ accountTypeName, ...props }) => {
  const {
    expires: {
      over_quota_campaign: expires
    }
  } = useAssetInfo();
  return <NewUserBannerV2 accountTypeName={accountTypeName} expires={expires} {...props} />;
};

OverQuotaBanner.propTypes = {
  accountTypeName: string.isRequired
};

export default OverQuotaBanner;
