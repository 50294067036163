import React from 'react';

import './index.scss';

import Lightbox from '../../../../../ui/Lightbox/Lightbox';

const SummerSaleLightbox = () => {
  const handleClose = () => {
    console.log('action: lightbox close');
  };
  return (
    <Lightbox
      ariaLabel="Summer Sale"
      onClose={handleClose}
    >
      <div className="summer-sale-2023-lightbox">
        summer sale dummy
      </div>
    </Lightbox>
  );
};

export default SummerSaleLightbox;
