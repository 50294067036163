/* eslint-disable max-len */
import React from 'react';

import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';

import './v1.scss';

import { string } from 'prop-types';
import ExitModal from '../../../../../ui/ExitModal/ExitModal';

import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';
import { useActionManager } from '../../../../../../hooks/useActionManager';

const AcademyExitModal = ({ accountTypeName }) => {
  const {
    utm_campaign: utmCampaign
  } = useAssetInfo();
  const utmSource = `${utmCampaign}-exit-modal-cta`;

  const { tickAction } = useActionManager();

  const handleClick = () => {
    tickAction({ action: 'click', target: 'get-certified' });
    handleCustomNavigation(`/academy/courses/jotform-apps/explore-jotform-apps/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=exit-intent`, '_self', true);
  };

  return (
    <ExitModal title="Academy Launch Exit Modal">
      <div className="exit-modal--container">
        <div className="exit-modal--left">
          <div className="exit-modal--sub-title">
            <div>
              <span>{t('New Course')}</span>
              <strong>{t('Explore Jotform Apps')}</strong>
            </div>
            <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/academy-launch/play.svg" className="exit-modal--sub-title-icon" alt="New Course Explore Jotform Apps" />
          </div>
          <img
            src="https://cdn.jotfor.ms/assets/img/campaigns/2024/academy-launch/academy-phone.png" width="409" height="501"
            className="exit-modal--phone"
            alt="Exit Modal Phone"
          />
        </div>
        <div className="exit-modal--right">
          <div className="exit-modal--title">
            {t('Need help getting started?')}
          </div>
          <div className="exit-modal--text">
            {t('Take our free course to learn how to use Jotform Apps.')}
          </div>
          <a className="exit-modal--button" onClick={handleClick}>
            {t('Get Certified')}
          </a>
        </div>
        <img
          src="https://cdn.jotfor.ms/assets/img/campaigns/2024/academy-launch/academy-woman.png" width="332" height="247"
          className="exit-modal--woman"
          alt="Exit Modal Woman"
        />
      </div>
    </ExitModal>
  );
};

AcademyExitModal.propTypes = {
  accountTypeName: string.isRequired
};

export default AcademyExitModal;
