import React from 'react';

import PricingTableProvider from '../context/pricingTableContext';

const pricingTableManager = Component => {
  return props => {
    return (
      <PricingTableProvider
        {...props}
      >
        <Component />
      </PricingTableProvider>
    );
  };
};

export default pricingTableManager;
