import React from 'react';
import { object, string } from 'prop-types';

import { handleCustomNavigation } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';

import './v1.scss';

import FlyIn from '../../../../../ui/FlyIn/FlyIn';

import { useFlyinUtm } from '../../../../../../hooks/useUtm';

const CloseIconRenderer = () => <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/flyin/close.svg" alt="Close Icon" />;

const BlackfridayFlyin = ({
  isSticky,
  ...props
}) => {
  const { url } = useFlyinUtm({
    directURL: '/pricing/'
  });

  const handleClick = () => {
    handleCustomNavigation(url, '_blank', true);
  };

  return (
    <FlyIn
      isSticky={isSticky}
      CloseIconRenderer={CloseIconRenderer}
      {...props}
    >
      <div className="flyin-content silver-flyin-wrapper" data-jfa="silver-flyin-wrapper">
        <div className="silver-flyin-wrapper-container">
          <div className="badge"><span className="badge-inner">{t('LIMITED TIME OFFER')}</span></div>
          <img
            className="money" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/one-dollar/wfwfwf.png"
            alt='Jotform Only One Campaign'
          />
          <span className="title-plan">{t('Silver Plan')}</span>
          <div className="title-area">
            <span className="title-silver">{t('Only')}</span>
            <span className="title-for-a-year">{t('FOR A YEAR')}</span>
          </div>
          <button className="cta-button" type="button" onClick={handleClick}>{t('Upgrade Now')}</button>
        </div>
      </div>
    </FlyIn>
  );
};

BlackfridayFlyin.propTypes = {
  isSticky: string.isRequired,
  user: object.isRequired
};

export default BlackfridayFlyin;
