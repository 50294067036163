import { useState } from 'react';
import { string } from 'prop-types';

import { useInterval } from '../../../hooks/useInterval';

const CountDown = ({ expires }) => {
  const getDistance = () => {
    const now = new Date().getTime();
    const distance = new Date(expires * 1000).getTime() - now;
    return distance;
  };
  const [countdown, setCountdown] = useState(getDistance() < 0 ? '00 : 00 : 00' : '24 : 00 : 00');
  const [delay, setDelay] = useState(getDistance() < 0 ? null : 1000);
  useInterval(() => {
    const distance = getDistance();
    if (distance < 0) {
      setCountdown('00 : 00 : 00');
      setDelay(null);
    }
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (hours < 10) hours = `0${hours}`;

    if (minutes < 10) minutes = `0${minutes}`;

    if (seconds < 10) seconds = `0${seconds}`;

    setCountdown(`${hours}h : ${minutes}m : ${seconds}s `);
  }, delay);

  return countdown;
};

CountDown.propTypes = {
  expires: string
};

export default CountDown;
