import React from 'react';
import {
  array
} from 'prop-types';
import '../styles/skeleton.scss';

const PricingTableSkeleton = ({ availablePlansForUser }) => {
  return (
    <div className="skeleton-wrap">
      {availablePlansForUser.map(() => (
        <div className="skeleton">
          <div className="skeleton--header" />
          <ul className="skeleton--content-list">
            <li className="skeleton--content-list-item" />
            <li className="skeleton--content-list-item" />
            <li className="skeleton--content-list-item" />
          </ul>
          <div className="skeleton--button" />
        </div>
      ))}
    </div>
  );
};
PricingTableSkeleton.propTypes = {
  availablePlansForUser: array
};

PricingTableSkeleton.defaultProps = {
  availablePlansForUser: []
};
export default PricingTableSkeleton;
