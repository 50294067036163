import React from 'react';
import { t } from '@jotforminc/translation';

const BlackFridayMyaccountBadgeV1 = () => {
  return (
    <div className="sm:ml-auto sm:rtl:ml-0 sm:rtl:mr-auto wrap-nowrap">
      <div className="p-0.5 radius-full ml-1" style={{ background: 'linear-gradient(294deg, #10121C 47.92%, #C12122 125.42%)' }}>
        <div className="px-4 py-1.5 flex justify-center items-center color-white font-medium text-center w-full radius-xl line-height-xs text-xs font-medium text-uppercase">
          {t('SALE')}
        </div>
      </div>
    </div>
  );
};

export default BlackFridayMyaccountBadgeV1;
