import React from 'react';

import { t } from '@jotforminc/translation';

import G2PromotionFlyInV1 from './v1';

const G2PromotionFlyInV2 = props => (
  <G2PromotionFlyInV1 {...props}>
    <strong className="is-main-text">{t('No. 1 form builder platform on G2')}</strong>
  </G2PromotionFlyInV1>
);

export default G2PromotionFlyInV2;
