/* eslint-disable max-len */
import React from 'react';

import { t, translationRenderer } from '@jotforminc/translation';

import './v2.scss';

import ExitModal from '../../../../../ui/ExitModal/ExitModal';
import { useActionManager } from '../../../../../../hooks/useActionManager';

const RegularFiftyDiscountExitModal = () => {
  const { tickAction } = useActionManager();

  const onClose = () => {
    console.log('action: onClose');
  };

  const handleClick = () => {
    tickAction({ action: 'click', target: 'save-now' });
  };

  return (
    <ExitModal
      onClose={onClose}
      title="Summer Sale Exit Modal"
    >
      <div class="exit-modal-content">
        <div class="exit-modal--area">
          <div class="exit-modal--container">
            <img class="bee left" src="https://cdn01.jotfor.ms/assets/img/campaigns/2024/spring-sale/flyin/bee.gif" alt="Bee" />
            <div class="img-flower">
              <img class="left-flower" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/spring-sale/exit-intent/flower-left.svg" alt="Summer Sale Flower Left" />
            </div>
            <div class="exit-modal--title">
              <p class="exit-modal--title-h2">
                {t('SUMMER SALE')}
              </p>
              <p class="exit-modal--title-h1">
                {translationRenderer('SAVE [1[50%]]')({
                  renderer1: str => (<strong>{str}</strong>)
                })}
              </p>
              <p class="exit-modal--title-h4">
                {t('ON ANNUAL PLANS')}
              </p>
              <button class="exit-modal--cta js-exit-modal-cta" type="button" onClick={handleClick}>
                {t('Save now')}
              </button>
              <p class="exit-modal--title-h5">
                {t('Offer does not apply to Jotform Enterprise. The 50-percent discount is a one-time offer when upgrading to an annual Bronze, Silver, or Gold plan. Plans will auto-renew at the full price one year after the discount is applied. After one year, plans can also be adjusted to the full-price monthly plan, unless canceled. If you cancel your account within 30 days of initial payment, a full refund will be issued. Cannot be combined with other offers. HIPAA compliance features are only available with a Gold plan. Offer expires June 10, 2024.')}
              </p>
            </div>
            <div class="img-flower">
              <img class="right-flower" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/spring-sale/exit-intent/flower-right.svg" alt="Summer Sale Flower Right" />
            </div>
          </div>
        </div>
      </div>
    </ExitModal>
  );
};

export default RegularFiftyDiscountExitModal;
