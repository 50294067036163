import React from 'react';
import { string } from 'prop-types';

import { t } from '@jotforminc/translation';

import { handleCustomNavigation } from '@jotforminc/utils';
import './v1.scss';

import { useFlyinUtm } from '../../../../../../hooks/useUtm';
import FlyIn from '../../../../../ui/FlyIn/FlyIn';
import { useActionManager } from '../../../../../../hooks/useActionManager';

const WorkflowslaunchFlyInV1 = ({ isSticky, ...props }) => {
  const { url } = useFlyinUtm({
    directURL: '/products/workflows/',
    customUtmCampaign: 'workflows'
  });
  const { tickAction } = useActionManager();

  const handleSeeJotformWorkflowsBtn = () => {
    tickAction({ action: 'click', target: 'discover-now' });
    handleCustomNavigation(url, '_blank', true);
  };

  return (
    <FlyIn
      isSticky={isSticky}
      {...props}
      CloseIconRenderer={() => <img className="close-flyin" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/teams/flyin/close.svg" alt="Close Icon" />}
    >
      <div className="workflows-flyin-wrapper" data-jfa="workflows-flyin">
        <div className="workflows-flyin-wrapper-container">
          <div className="workflows-container">
            <div className="image">
              <img
                width="283" height="64" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/workflows/flyin/workflows-img.png"
                alt="Banner"
              />
            </div>
            <div className="title-wrapper">
              <h5>{t('Announcing')}</h5>
              <h4>{t('Jotform Workflows')}</h4>
            </div>
            <button type='button' className="cta-button" onClick={handleSeeJotformWorkflowsBtn}>
              <a href="#">{t('Discover Now')}</a>
            </button>
          </div>

        </div>
      </div>
    </FlyIn>
  );
};

WorkflowslaunchFlyInV1.propTypes = {
  isSticky: string.isRequired
};

export default WorkflowslaunchFlyInV1;
