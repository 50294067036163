import React, {
  createContext, useContext, useState, useEffect
} from 'react';
import {
  object, string, func, bool, oneOf, array, number, oneOfType
} from 'prop-types';
import { langCodes } from '@jotforminc/translation';
import { getUrlParameter } from '@jotforminc/utils';
import { StorageHelper } from '@jotforminc/storage-helper';

import {
  CAMPAIGN_TYPES,
  PERIODS,
  PLAN_COLUMN_COLORS,
  PLANS,
  SOURCES,
  PLAN_COLORS,
  THEMES,
  COLOR_THEMES,
  PLAN_LAYOUTS,
  PRICE_TYPES
} from '../constants';

import { dealCounterEndDate, dealCounterStartDate } from '../components/DealCounter';

export const PricingTableContext = createContext({});

const PricingTableProvider = ({
  // eslint-disable-next-line react/prop-types
  children,
  actionLogger,
  onBrandingInfoClicked,
  onHipaaInfoClicked,
  onPlanContainerClicked,
  onSeeAllFeaturesClicked,
  onPeriodChanged,
  onEnterpriseColumnClicked,
  ...props
}) => {
  const getCurrencyLocaleFromUser = () => {
    // It should work when subscriptionDetails.gatewayInfo.currencyLocale not exits (guest users).
    const { localeCurrencySymbol, location: { country_code: countryCode } = {}, language = 'en-US' } = props.user;

    if (localeCurrencySymbol === '1') {
      if (['AU', 'CA'].includes(countryCode) && props.plans.BRONZE.currency === 'USD') return 'en-CA';
      return `${language.substr(0, 2)}-${countryCode}`;
    }

    return 'en-US';
  };

  const campaignData = props.user.campaign || {};

  const userPlan = props.user.account_type?.name || props.user.account_type || props.user.accountType?.name || props.user.accountType || '';

  const initialState = {
    ...props,
    showFieldsPerFormLimit: props.plans[PLANS.FREE].limits.fieldPerForm !== undefined && props.plans[PLANS.FREE].limits.fieldPerForm !== null && props.plans[PLANS.FREE].limits.fieldPerForm > -1,
    currencyCode: props.plans[PLANS.BRONZE].currency,
    userPlan,
    isGuestUser: userPlan === 'GUEST',
    campaign: campaignData,
    campaignAssetsVersion: campaignData?.assetsVersion || 'v1',
    campaignStatus: props.disableCampaign ? false : !!(campaignData.status && CAMPAIGN_TYPES[campaignData.type] && campaignData.type !== CAMPAIGN_TYPES.ONEDOLLARSILVER),
    currencyLocale: props.currencyLocale ?? getCurrencyLocaleFromUser(),
    source: props.source,
    isNonprofitEducationUser: props.user.nonprofit === '1' || props.user.education === '1'
  };

  const [state, setState] = useState(initialState);

  const getColorTheme = () => {
    const {
      campaign: { type: campaignType },
      campaignStatus,
      source,
      colorTheme
    } = state;

    if (colorTheme) {
      return colorTheme;
    }

    if ([
      CAMPAIGN_TYPES.NONPROFIT, CAMPAIGN_TYPES.EDUCATION, CAMPAIGN_TYPES.HIPAAGOLD,
      CAMPAIGN_TYPES.BACKTOPAID, CAMPAIGN_TYPES.REGULARFIFTYDISCOUNT, CAMPAIGN_TYPES.SAVEUPTOFORTYDISCOUNT,
      CAMPAIGN_TYPES['50'], CAMPAIGN_TYPES['30'], CAMPAIGN_TYPES.EOY,
      CAMPAIGN_TYPES.SUMMERCAMP
    ].includes(campaignType)) {
      return COLOR_THEMES.DARK;
    }

    if (campaignStatus || [SOURCES.BLACK_FRIDAY_PAGE, SOURCES.BUY_ONE_GET_ONE].includes(source)) {
      return COLOR_THEMES.LIGHT;
    }
    return COLOR_THEMES.DARK;
  };

  const getColumnColors = () => {
    if (getColorTheme() === COLOR_THEMES.LIGHT) {
      return PLAN_COLUMN_COLORS.LIGHT;
    }
    return PLAN_COLUMN_COLORS.DEFAULT;
  };

  const getUserLanguage = () => {
    const { user: { language = langCodes.en } } = state;
    return global.langCode || language;
  };

  const getPlanColors = () => {
    return PLAN_COLORS.DEFAULT;
  };

  const showDealCounterSelector = ({ plan }) => {
    const { name: planName, campaign_status: planCampaignStatus, campaign_status_biyearly: planCampaignStatusBiyearly } = plan;
    const { campaign: { type: campaignType }, campaignStatus, period } = state;

    return [PLANS.BRONZE, PLANS.SILVER, PLANS.GOLD, PLANS.DIAMOND].includes(planName) && new Date() >= dealCounterStartDate && new Date() < dealCounterEndDate
      && campaignStatus && ((period === PERIODS.YEARLY && planCampaignStatus === 'ON') || (period === PERIODS.BIYEARLY && planCampaignStatusBiyearly === 'ON'))
      && Object.values(CAMPAIGN_TYPES).filter(c => ![CAMPAIGN_TYPES.NEWUSER, CAMPAIGN_TYPES.OVERQUOTAUSER, CAMPAIGN_TYPES.BACKTOPAID, CAMPAIGN_TYPES.NONE].includes(c)).includes(campaignType);
  };

  const hipaaDiscountSelector = ({ plan = {} } = {}) => {
    const { name: planName } = plan;
    const { campaign: { type: campaignType }, disableCampaign } = state;

    return planName
      ? planName === PLANS.GOLD && campaignType === CAMPAIGN_TYPES.HIPAAGOLD && !disableCampaign
      : campaignType === CAMPAIGN_TYPES.HIPAAGOLD && !disableCampaign;
  };

  const getBestValuePlanName = () => {
    const { plans } = state;
    return plans.GOLD.planType === '2024' ? PLANS.GOLD : PLANS.SILVER;
  };

  const showBestValueBadgeSelector = ({ plan }) => {
    const { name: planName } = plan;

    const {
      hideBestValueBadge,
      isGuestUser,
      campaign: { type: campaignType },
      campaignStatus
    } = state;
    const showDealCounter = showDealCounterSelector({ plan });
    const hipaaDiscount = hipaaDiscountSelector({ plan });
    const bestValuePlan = getBestValuePlanName();
    if (global?.bestValueBadgeInEOY && CAMPAIGN_TYPES.EOY === campaignType && planName === bestValuePlan) return true;

    return !hideBestValueBadge && !showDealCounter
      && (hipaaDiscount || (planName === bestValuePlan && !(campaignType === CAMPAIGN_TYPES.HIPAAGOLD)))
      && (!campaignStatus || ![CAMPAIGN_TYPES.BLACKFRIDAY, CAMPAIGN_TYPES.BLACKFRIDAYCHECKOUT, CAMPAIGN_TYPES.EOY].includes(campaignType))
      && !(campaignType === CAMPAIGN_TYPES.NEWUSER && isGuestUser);
  };

  const showPopularBadgeSelector = ({ plan }) => {
    const { name: planName } = plan;

    return planName === PLANS.SILVER && props.showPopularBadge;
  };

  const showPeriodSwitchToggleSelector = () => {
    const {
      campaign: { type: campaignType },
      campaignAssetsVersion,
      campaignStatus,
      disableCampaign,
      hidePeriodSwitch,
      isNonprofitEducationUser
    } = state;

    if (hidePeriodSwitch !== undefined) {
      return !hidePeriodSwitch;
    }
    return !disableCampaign && (
      !campaignStatus || isNonprofitEducationUser || [CAMPAIGN_TYPES.HIPAAGOLD, CAMPAIGN_TYPES.SAVEUPTOFORTYDISCOUNT].includes(campaignType)
      || (CAMPAIGN_TYPES.REGULARFIFTYDISCOUNT === campaignType && campaignAssetsVersion !== 'v2')
    );
  };

  const checkLegacyYearlyUsersOnCampaignTime = () => {
    const { campaignStatus, user, userCurrentPeriod } = state;
    const { account_type: { planType: priceType, currentPlanType: currentPriceType } = {} } = user;
    return campaignStatus && (priceType !== currentPriceType) && (userCurrentPeriod === PERIODS.YEARLY);
  };

  const getVisiblePlans = () => {
    const {

      visiblePlans: visiblePlansProp,
      source,
      plans,
      planLayout,
      campaignStatus,
      campaign: { type: campaignType },
      isGuestUser,
      theme
    } = state;

    if (visiblePlansProp) {
      const goldIndex = visiblePlansProp.findIndex(plan => plan === PLANS.GOLD);
      if (goldIndex !== -1 && plans.DIAMOND?.isVisible) {
        return visiblePlansProp.splice(goldIndex, 1, PLANS.DIAMOND);
      }
      return visiblePlansProp;
    }

    const visiblePlans = [PLANS.FREE, PLANS.BRONZE, PLANS.SILVER, PLANS.GOLD];

    const storagePlanAvailable = plans.STORAGE.isVisible || getUrlParameter('show-plan')?.toUpperCase() === PLANS.STORAGE;

    if (source !== SOURCES.HIPAA_PRICING && storagePlanAvailable) {
      visiblePlans.splice(1, 0, PLANS.STORAGE);
    } else if (
      planLayout !== PLAN_LAYOUTS.CHECKOUT_CARD && plans.ENTERPRISE.isVisible
    ) {
      visiblePlans.push(PLANS.ENTERPRISE);
    }

    // remove FREE plan on black friday and eoy
    if (campaignStatus && [CAMPAIGN_TYPES.BLACKFRIDAY, CAMPAIGN_TYPES.BLACKFRIDAYCHECKOUT, CAMPAIGN_TYPES.EOY].includes(campaignType) && !isGuestUser
    && [
      SOURCES.PRICING,
      SOURCES.HIPAA_PRICING,
      SOURCES.TEAMS_PRICING,
      SOURCES.SIGN_PRICING,
      SOURCES.APPS_PRICING,
      SOURCES.WORKFLOWS_PRICING
    ].includes(source)
    && theme === THEMES.DEFAULT) {
      const freeIndex = visiblePlans.findIndex(p => p === PLANS.FREE);
      visiblePlans.splice(freeIndex, 1);
    }

    return Object.keys(plans).filter(plan => visiblePlans.includes(plan));
  };

  const getShowCampaignPrice = ({ plan }) => {
    const {
      forceDiscount,
      isNonprofitEducationUser,
      period,
      source
    } = state;

    const {
      name: planName,
      campaign_status: planCampaignStatus,
      campaign_status_biyearly: planCampaignStatusBiyearly
    } = plan;

    return ![PLANS.STORAGE, PLANS.FREE].includes(planName) && (
      forceDiscount
      || isNonprofitEducationUser
      || (planCampaignStatus === 'ON' && (period === PERIODS.YEARLY))
      || (planCampaignStatusBiyearly === 'ON' && (period === PERIODS.BIYEARLY))
    ) && ([SOURCES.SURVEYS, SOURCES.TYPEFORM_ALTERNATIVE].includes(source) ? period === PERIODS.YEARLY : true);
  };

  const showBiyearlyPeriodSelector = () => {
    const {
      showBiyearlyPeriod,
      campaign: { type: campaignType },
      campaignStatus,
      disableCampaign
    } = state;
    const showPeriodSwitchToggle = showPeriodSwitchToggleSelector();

    const showBiYearlyParam = getUrlParameter('show-biyearly');

    if (showBiYearlyParam !== undefined) {
      return showBiYearlyParam;
    }

    if (showBiyearlyPeriod !== undefined) {
      return showBiyearlyPeriod;
    }

    return false && !disableCampaign && campaignStatus && showPeriodSwitchToggle && [CAMPAIGN_TYPES.EOY, CAMPAIGN_TYPES.NEWUSER].includes(campaignType);
  };

  const isReactivateScheduledDowngradeEnableSelector = () => {
    const { subscriptionDetails } = state;
    return subscriptionDetails?.expirationInfo?.type === 'downgrade' && !['CHECK', 'SAASY'].includes(subscriptionDetails?.lastPaymentType?.gateway);
  };

  const showOneDollarSilverBadgeSelector = ({ plan }) => {
    const {
      forceDiscount,
      period,
      source,
      campaign: { type: campaignType }
    } = state;
    const { name: planName } = plan;

    return (
      !forceDiscount // nonprofit
      && planName === PLANS.SILVER && period === PERIODS.YEARLY
      && [
        SOURCES.APPS_PRICING,
        SOURCES.PRICING,
        SOURCES.BILLING,
        SOURCES.LIMIT_DIALOG,
        SOURCES.SIGN_PRICING,
        SOURCES.TEAMS_PRICING,
        SOURCES.WORKFLOWS_PRICING
      ].includes(source)
      && (campaignType === CAMPAIGN_TYPES.ONEDOLLARSILVER || getUrlParameter('one-dollar') === '1'));
  };

  const handlePlanContainerClicked = plan => {
    let path = global.location.pathname[3] === '/' ? global.location.pathname.slice(3) : global.location.pathname;
    path = path.startsWith('/pricing/') && !path.endsWith('/pricing/') ? '/pricing/' : path;

    StorageHelper.setLocalStorageItem({
      key: 'jf-checkout-last-pricing-page',
      value: JSON.stringify({
        path,
        plan: plan.name
      }),
      ttl: 1000 * 60 * 60 * 24 * 30
    });

    onPlanContainerClicked(plan);
  };

  useEffect(() => {
    if (state?.period) {
      onPeriodChanged(state?.period);
    }
  }, [state.period]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      user: props.user,
      plans: props.plans
    }));
  }, [props.user, props.plans]);

  return (
    <PricingTableContext.Provider
      value={{
        state,
        setState,
        actionLogger,
        onBrandingInfoClicked,
        onHipaaInfoClicked,
        onPlanContainerClicked: handlePlanContainerClicked,
        onSeeAllFeaturesClicked,
        onPeriodChanged,
        onEnterpriseColumnClicked,
        getBestValuePlanName,
        getColorTheme,
        getColumnColors,
        getUserLanguage,
        getPlanColors,
        getVisiblePlans,
        getShowCampaignPrice,
        checkLegacyYearlyUsersOnCampaignTime,
        showDealCounterSelector,
        hipaaDiscountSelector,
        showBestValueBadgeSelector,
        showPopularBadgeSelector,
        showPeriodSwitchToggleSelector,
        showBiyearlyPeriodSelector,
        showOneDollarSilverBadgeSelector,
        isReactivateScheduledDowngradeEnableSelector
      }}
    >
      {children}
    </PricingTableContext.Provider>
  );
};

PricingTableProvider.propTypes = {
  plans: object.isRequired,
  user: object.isRequired,
  currencyLocale: string,
  source: oneOf(Object.values(SOURCES)).isRequired,
  visiblePlans: array,
  visibleFeatures: array,
  hideAllLimits: bool,
  theme: oneOf(Object.values(THEMES)),
  colorTheme: oneOf(Object.values(COLOR_THEMES)),
  planLayout: oneOf(Object.values(PLAN_LAYOUTS)),
  showPopularBadge: bool,
  showHIPAABadgeOnHeader: bool,
  hideNoticeBox: bool,
  customEnterpriseUtmParams: string,
  userCurrentPeriod: oneOf([PERIODS.MONTHLY, PERIODS.YEARLY, PERIODS.BIYEARLY]),
  showHipaaInfo: bool,
  showDowngradeNotice: bool,
  expirationDate: string,
  billingCountry: string,
  lastPaymentAmount: oneOfType([string, number]),
  disableCampaign: bool,
  forceDiscount: bool,
  hipaaGrandfathered: bool,
  period: string,
  hidePeriodSwitch: bool,
  hideNoBranding: bool,
  hideBestValueBadge: bool,
  disableCallToActions: bool,
  isReactivationEnable: bool,
  showBiyearlyPeriod: bool,
  showEoySwitchLongMessage: bool,
  showTripleSwitch: bool,
  clickableColumn: bool,
  actionLogger: func,
  onPlanContainerClicked: func.isRequired,
  onSeeAllFeaturesClicked: func,
  onEnterpriseColumnClicked: func,
  onBrandingInfoClicked: func,
  onHipaaInfoClicked: func,
  onPeriodChanged: func,
  // New Props
  highlightHipaa: bool,
  priceType: oneOf(Object.values(PRICE_TYPES)),
  pullUpFeatures: array,
  customFeatures: array
};

PricingTableProvider.defaultProps = {
  theme: 'default',
  colorTheme: undefined,
  planLayout: undefined,
  currencyLocale: undefined,
  showPopularBadge: false,
  showHIPAABadgeOnHeader: false,
  forceDiscount: false,
  showHipaaInfo: false,
  showDowngradeNotice: false,
  expirationDate: null,
  lastPaymentAmount: 0,
  userCurrentPeriod: PERIODS.MONTHLY,
  billingCountry: '',
  hipaaGrandfathered: null,
  disableCampaign: false,
  visiblePlans: null,
  visibleFeatures: null,
  hideAllLimits: false,
  hideNoticeBox: false,
  hideBestValueBadge: false,
  customEnterpriseUtmParams: '',
  period: PERIODS.YEARLY,
  hidePeriodSwitch: undefined,
  hideNoBranding: false,
  disableCallToActions: false,
  isReactivationEnable: false,
  showBiyearlyPeriod: undefined,
  showEoySwitchLongMessage: false,
  showTripleSwitch: global.enableTripleSwitch || ['1', 'true'].includes(getUrlParameter('triple-switch')) || undefined,
  clickableColumn: true,
  actionLogger: f => f,
  onSeeAllFeaturesClicked: f => f,
  onEnterpriseColumnClicked: f => f,
  onBrandingInfoClicked: f => f,
  onHipaaInfoClicked: f => f,
  onPeriodChanged: f => f,
  // New Props
  highlightHipaa: false,
  priceType: PRICE_TYPES.USER,
  pullUpFeatures: [],
  customFeatures: []
};

export default PricingTableProvider;

export const usePricingContext = () => useContext(PricingTableContext);
