import React from 'react';
import { string, object } from 'prop-types';

import { t } from '@jotforminc/translation';
import { Currency } from '@jotforminc/currency';

import { PLANS } from '../constants';
import { CUSTOM_PRICE, MONTH } from '../constants/texts';

import { usePricingTable } from '../hooks/usePricingTable';

const PriceAndNote = ({
  planName,
  palette
}) => {
  const {
    getPlan
  } = usePricingTable();

  const {
    price,
    fullPrice,
    pricePeriod,
    note
  } = getPlan(planName);

  const isPriceInteger = price % 1 === 0;

  return (
    <div
      className='flex flex-col justify-center items-center w-full text-sm'
      style={{
        minHeight: '86px'
      }}
    >
      {planName === PLANS.ENTERPRISE && (
        <span className='text-center color-navy-600 font-bold tracking-xxxs text-4xl'>
            {t(CUSTOM_PRICE)}
        </span>
      )}
      {planName === PLANS.FREE && (
        <span className="flex flex-col">
          <strong className="mx-2 font-bold mt-4 text-3xl">
            {t(planName)}
          </strong>
        </span>
      )}
      {![PLANS.ENTERPRISE, PLANS.FREE].includes(planName) && (
        <div className="flex flex-col">
          {
            fullPrice && (
              <div className="text-xs" style={{ color: palette.HEADER_NOTE }}>
                <strong className="font-bold relative">
                  <Currency
                    value={fullPrice}
                    CurrencyRenderer={({ children }) => <span className="mr-1">{children}</span>}
                    ValueRenderer={({ children }) => (
                      <span className="text-lg">
                        <div
                          className="absolute h-1 bg-red-300 top-4/12 left-0"
                          style={{
                            width: '120%',
                            transform: 'rotate(-25deg)'
                          }}
                        />
                        {children}
                      </span>
                    )}
                  />
                </strong>
                {`/${pricePeriod}`}
              </div>
            )
          }
          {
            price && (
              <div className="text-md pb-2 current-price-wrapper">
                <div className="flex items-end">
                  <Currency
                    value={price}
                    CurrencyRenderer={({ children }) => <span className="text-sm">{children}</span>}
                    ValueRenderer={({ children }) => <strong className="font-extrabold flex items-end text-6xl line-height-4xl">{children}</strong>}
                    DecimalWrapperRenderer={({ children }) => (
                      <span className="flex flex-col text-left">
                        <strong className="font-bold text-xl line-height-md">{children}</strong>
                        <span className="text-sm font-normal line-height-xs" style={{ color: palette.HEADER_NOTE }}>
                          {`/${t(MONTH)}`}
                        </span>
                      </span>
                    )}
                  />
                  {
                    isPriceInteger && (
                      <span className="text-sm font-normal line-height-xs" style={{ color: palette.HEADER_NOTE }}>
                          {`/${t(MONTH)}`}
                      </span>
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
      )}
      {note && (
        <span className='text-xs' style={{ color: palette.HEADER_NOTE }}>
          {note}
        </span>
      )}
    </div>
  );
};

PriceAndNote.propTypes = {
  planName: string.isRequired,
  palette: object.isRequired
};

export default PriceAndNote;
