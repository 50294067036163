/* eslint-disable max-len */
export const GET_STARTED = 'Get Started';
export const REACTIVATE = 'Reactivate';
export const CURRENT = 'Current';
export const DOWNGRADE = 'Downgrade';
export const UPGRADE = 'Upgrade';
export const CONTACT_SALES = 'Contact Sales';
export const GET_A_QUOTE = 'Get a Quote';
export const CUSTOM_PRICE = 'Custom Price';
export const APPLY_NOW = 'Apply Now';
export const ENTERPRISE_FEATURES_LIST = [
  'HIPAA Compliance Features Available',
  'Service Level Agreements (SLA)',
  'Custom Domain',
  'Priority Real-Time Support',
  'Local Data Residency Option',
  'Single Sign-On (SSO) Integrations'
];
export const ALL_FEATURES_INCLUDED = 'All Features Included';
export const BILLED_ANNUALLY_AT_PRICE = '(Billed annually at {price})';
export const BILLED_BIYEARLY_AT_PRICE = '(Billed biyearly at {price})';
export const BILLED_ANNUALLY_AFTER_2_YEARS = '(Billed annually after 2-years)';
export const BEST_VALUE = 'Best Value';
export const STARTER = 'Starter';
export const YEAR = 'year';
export const MONTH = 'month';
export const FORM_LIMIT = 'Form Limit';
export const MONTHLY_SUBMISSIONS = 'Monthly Submissions';
export const AVAILABLE_SPACE = 'Available Space';
export const MONTHLY_FORM_VIEWS = 'Monthly Form Views';
export const TOTAL_SUBMISSION_STORAGE = 'Total Submission Storage';
export const MONTHLY_PAYMENT_SUBMISSIONS = 'Monthly Payment Submissions';
export const MONTHLY_SIGNED_DOCUMENTS = 'Monthly Signed Documents';
export const MONTHLY_WORKFLOW_RUNS = 'Monthly Workflow Runs';
export const AGENTCOUNT_AGENTS = '{agentCount} Agents';
export const AGENTCOUNT_CHATBOT = '{agentCount} Chatbots';
export const AGENT_LIMIT = 'Agent Limit';
export const CHATBOT_LIMIT = 'AI Chatbot Limit';
export const AI_AGENTS = 'AI Agents';
export const AI_CONVERSATIONS = 'AI Conversations';
export const MONTHLY_CONVERSATIONS = 'Monthly Conversations';
export const ELEMENTS_PER_WORKFLOW = 'Elements Per Workflow';
export const FIELDS_PER_FORM = 'Fields per form';
export const REPORTS_FIELDS_PER_FORM = 'Reports, Fields per form';
export const FORMS_REPORTS_FIELDS_PER_FORM = 'Forms, Reports, Fields per form';
export const UNLIMITED = 'Unlimited';
export const FORMCOUNT_FORMS = '{formCount} Forms';
export const HIPAA_COMPLIANCE_AVAILABLE = 'HIPAA Compliance Features Available';
export const HIPAA_COMPLIANCE_NOT_AVAILABLE = 'HIPAA Compliance Features Unavailable';
export const GDPR_FRIENDLY = 'GDPR Friendly';
export const JOTFORM_BRANDING = 'Jotform Branding';
export const NO_BRANDING = 'No Branding';
export const YOU_ARE_SCHEDULED_TO_BE_DOWNGRADED_TO_FREE_ON_EXPIRATION_DATE = 'You are scheduled to be downgraded to FREE on {expirationDate}';
export const BILLED_ANNUALLY = 'Billed annually';
export const BILLED_BIYEARLY = 'Billed biyearly';
export const LEGACY_PLAN_USER_INFORMATION = 'Legacy Plan User Information';
export const AS_JOTFORM_HAS_GROWN_AND_EVOLVED_OVER_TIME_ALTERNATIVE = 'As Jotform has grown and evolved over time, our pricing and subscription plans have changed. However, as an <strong>{plan} legacy plan user,</strong> you are currently locked in at your low rate - so as long as you don’t change your subscription, you’ll enjoy that price for the rest of your life.';
export const AS_JOTFORM_HAS_GROWN_AND_EVOLVED_OVER_TIME_OUR_PRICING_AND_SUBSCRIPTION_PLANS_HAVE_CHANGED = 'As Jotform has grown and evolved over time, our pricing and subscription plans have changed. However, as an [1[{plan} legacy plan user,]] you are currently locked in at your low rate - so as long as you don’t change your subscription, you’ll enjoy that price for the rest of your life.';
export const ALL_PRICES_LISTED_ABOVE_ARE_IN_USD = 'All prices listed above are in USD.';
export const CAMPAIGN_DISCLAIMER = 'Offer does not apply to Jotform Enterprise. The {discountPercent}-percent yearly discount is a one-time offer when upgrading to an annual Bronze, Silver, or Gold plan. Plans will auto-renew at the full price one year after the discount is applied, depending on which option is selected. Once your purchased discount period is over, plans can also be adjusted to the full-priced monthly plan, unless canceled. If you cancel your account within 30 days of initial payment, a full refund will be issued. Cannot be combined with other offers. HIPAA compliance features are only available with a Gold plan.';
export const OFFER_EXPIRES = 'Offer expires {expireDate}.';
export const NONPROFIT_EDUCATION_CAMPAIGN_DISCLAIMER = 'This discount is not available for Jotform Enterprise. Jotform application form must be filled out to benefit from the 50 percent discount. If existing paid users apply and get approved to receive the discount, it will become effective starting with the next subscription period. If a user already has a paid subscription, they will need to cancel it to subscribe to a new paid plan.';
export const HIPAA_CAMPAIGN_DISCLAIMER = 'Offer does not apply to Jotform Enterprise. The 50-percent discount is a one-time offer when upgrading to an annual Gold plan. Plans will auto-renew at the full price one year after the discount is applied. After one year, plans can also be adjusted to the full-priced monthly plan, unless canceled. If you cancel your account within 30 days of initial payment, a full refund will be issued. Cannot be combined with other offers. HIPAA compliance features are only available with Gold plan.';
export const HIPAA_COMPLIANCE_GRANDFATHERED = 'Our HIPAA compliance features are now available only with our Gold and Enterprise plans. However, as an existing HIPAA user, you can continue to use HIPAA compliance features to collect health information safely and securely as long as you maintain your subscription.';
export const SUMMER_CAMP_DISCLAIMER = 'Offer does not apply to Jotform Enterprise. The 50-percent discount is a one-time offer when upgrading to an annual Bronze, Silver, or Gold plan. Plans will auto-renew at the full price one year after the discount is applied. After one year, plans can also be adjusted to the full-price monthly plan, unless canceled. If you cancel your account within 30 days of initial payment, a full refund will be issued. Cannot be combined with other offers. HIPAA compliance features are only available with a Gold plan.';
export const PER_TEAM = 'per Team';
export const ONE_USER = '1 User';
export const SIGNED_DOCUMENT_LIMIT = 'Signed Document Limit';
export const MONTHLY_SIGNED_DOCUMENT_LIMIT = 'Monthly Signed Document Limit';
export const HIPAA_COMPLIANCE_SILVER = 'HIPAA compliance features are only available with our [1[Silver]] or [2[Gold]] plan. It is included in the cost and there is no additional fee.';
export const HIPAA_COMPLIANCE_GOLD = 'HIPAA compliance features are only available with our [1[Gold]] plan. It is included in the cost and there is no additional fee.';
export const HIPAA_COMPLIANCE_GOLD_ENTERPRISE = 'HIPAA compliance features are only available with our [1[Gold]] and [1[Enterprise]] plans. Upgrade your plan today to enable HIPAA compliance features.';
export const USERS_AND_TEAMS = 'Users and Teams';
export const GET_THE_OFFER = 'Get the Offer';
export const SPECIAL_OFFER = 'SPECIAL OFFER';
export const POPULAR = 'POPULAR';
export const FORMS = 'Forms';
export const TARGET_PLAN_NAME_PLAN = '{targetPlanName} Plan';
export const SAVE_UP_TO_DISCOUNTRATE = 'Save up to {discountRate}';
export const UNLIMITED_USAGE = 'Unlimited Usage';
export const CUSTOM_BRANDING = 'Custom Branding & Domain';
export const APPS = 'Apps';
export const START_A_FREE_TRIAL = 'Start a free trial';
