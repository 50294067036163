/* eslint-disable max-len */
import React from 'react';
import { object } from 'prop-types';

import { t } from '@jotforminc/translation';
import { LightboxPricingTable, COLOR_THEMES } from '@jotforminc/pricing-table';

import './v1.scss';

import Modal from '../../../../../ui/Lightbox/Lightbox';

const BlackFridayLightbox = ({
  user = {},
  plans = {}
}) => {
  return (
    <Modal
      ariaLabel="BlackFriday Lightbox"
      title="BlackFriday Lightbox"
    >
      <div className="lightbox-content">
        <div className="modal-content">
          <div className="lightbox--wrapper">
            <div className="center">
              <div className="lightbox--title">
                <p className="lightbox--title-h2">
                  {t('LIMITED TIME OFFER')}
                </p>
                <img className="bf-text" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/bf.svg" alt="Jotform Black Friday Campaign" />
                <div className="badge">
                  <span className="badge-inner">
                    <p>{t('GET EXTRA 20% OFF AT CHECKOUT')}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing-table">
          <LightboxPricingTable
            user={user}
            plans={plans}
            colorTheme={COLOR_THEMES.DARK}
            onPlanContainerClicked={f => f}
          />
        </div>
        <div className="modal-content-footer">
          <p className="title-h5">
            *
            {t('Offer does not apply to Jotform Enterprise. The 50-percent discount is a one-time offer when upgrading to an annual Bronze, Silver, or Gold plan. Plans will auto-renew at the full price one year after the discount is applied. After one year, plans can also be adjusted to the full-priced monthly plan, unless canceled. If you cancel your account within 30 days of initial payment, a full refund will be issued. Cannot be combined with other offers.')}
          </p>
        </div>
      </div>
    </Modal>
  );
};

BlackFridayLightbox.propTypes = {
  user: object.isRequired,
  plans: object.isRequired
};

export default BlackFridayLightbox;
