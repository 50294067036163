import React from 'react';
import { object } from 'prop-types';

import { t } from '@jotforminc/translation';

import './styles.scss';
import MyPlanBanner, { MyPlanBannerButton, MyPlanBannerPlanText } from '../../../../../ui/MyPlanBanner/MyPlanBanner';

const MyAccountMyPlanBanner = ({
  buttonProps,
  planTextProps,
  ...props
}) => {
  return (
    <MyPlanBanner
      className='blackfriday-billing-container'
      {...props}
    >
      <MyPlanBannerPlanText
        {...planTextProps}
      />
      <div className='blackfriday-billing--badge'>
        <MyPlanBannerButton
          {...buttonProps}
        />
        <div className="blackfriday-billing--badge-content">
          <div className="blackfriday-billing--badge-text">
            <strong>{t('BLACK FRIDAY')}</strong>
            <strong>{t('HUGE SALE')}</strong>
          </div>
          <img
            width="92"
            height="72" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/myaccount/sale-bag.png"
            alt="Jotform Black Friday Bag"
          />
        </div>
      </div>
    </MyPlanBanner>
  );
};

MyAccountMyPlanBanner.propTypes = {
  buttonProps: object.isRequired,
  planTextProps: object.isRequired
};

export default MyAccountMyPlanBanner;
