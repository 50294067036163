import React from 'react';

import './v2.scss';

import ExitModal from '../../../../../ui/ExitModal/ExitModal';

const NewUserExitModalV2 = () => {
  const onClose = () => {
    console.log('action: onClose');
  };
  return (
    <ExitModal
      onClose={onClose}
      title="New User Exit Modal"
    >
      exit modal v2
    </ExitModal>
  );
};

export default NewUserExitModalV2;
