import React from 'react';
import cx from 'classnames';
import { t } from '@jotforminc/translation';
import { capitalizeAccToLocale as capitalize } from '@jotforminc/utils';

import { BEST_VALUE, SPECIAL_OFFER } from '../constants/texts';
import { THEMES, PLANS } from '../constants';

import { usePricingContext } from '../context/pricingTableContext';

import '../styles/best_value.scss';

const BestValueRibbon = () => {
  const {
    getUserLanguage,
    getBestValuePlanName,
    state: {
      campaign: { type: campaignType },
      disableCampaign,
      theme
    },
    hipaaDiscountSelector
  } = usePricingContext();

  const language = getUserLanguage();
  const bestValuePlan = getBestValuePlanName();

  const bestValueClasses = cx('pricingBox-ribbon-belt', {
    hipaaDiscount: hipaaDiscountSelector() || bestValuePlan === PLANS.GOLD,
    themeDefault: theme === THEMES.DEFAULT,
    themeLite: theme === THEMES.LITE,
    themeBilling: theme === THEMES.BILLING,
    themeMini: theme === THEMES.MINI
  });

  return (
    <div className={bestValueClasses}>
      <div className="pricingBox-ribbon-shadow">
        <div className="pricingBox-ribbon-content">
          <div className="triangle-behind" />
          <div className="triangle-sides-shadow" />
          <div className="triangle-sides" />
          <div className="text-content">{capitalize(t(!disableCampaign && campaignType === 'HIPAAGOLD' ? SPECIAL_OFFER : BEST_VALUE), language)}</div>
          <div className="triangle-after" />
        </div>
      </div>
    </div>
  );
};

export default BestValueRibbon;
