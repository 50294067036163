import React from 'react';
import { t } from '@jotforminc/translation';

const NewUserLimitDialogV2 = () => {
  return (
    <div className="limitdialog-v2">
      <img
        style={{ maxWidth: '100%' }}
        alt={t('%50 Sale Campaign Image')}
        src="https://cdn.jotfor.ms/assets/img/campaigns/2023/new-user/myforms/formcount.png?v=0.1"
      />
    </div>
  );
};

export default NewUserLimitDialogV2;
