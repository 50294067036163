import React from 'react';
import { t } from '@jotforminc/translation';

import '../styles/campaignStyles/black-friday-2024.scss';

export const BlackFridayPromotionRenderer = () => (
  <div className="limitDialog-bf2024">
    <div className="limitDialog-bf2024--text">
      <strong>{t('BLACK FRIDAY')}</strong>
      <span>{t('HUGE SALE')}</span>
    </div>
    <img
      className="limitDialog-bf2024--image-left"
      src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/2024/limit-dialog/shopping-bag.png"
      alt="Shopping Bag"
      width="107"
      height="120"
    />
    <img
      className="limitDialog-bf2024--image-right"
      src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/2024/limit-dialog/shopping-bag.png"
      alt="Shopping Bag"
      width="107"
      height="120"
    />
  </div>
);
