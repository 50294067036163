import React from 'react';
import './pricing-badge.scss';
import { node } from 'prop-types';
import { useAssetInfo } from '../../../hooks/useCampaignInfo';

const PricingBadge = ({ children }) => {
  const {
    type: campaignType
  } = useAssetInfo();
  return (
    <div className="jfRHeader--pricing-badge" data-campaign-type={campaignType}>
      {children}
    </div>
  );
};

PricingBadge.propTypes = {
  children: node.isRequired
};

export default PricingBadge;
