import React, { useEffect, useState } from 'react';
import { func, node } from 'prop-types';

import { handleCustomNavigation } from '@jotforminc/utils';

import './account-box.scss';

import { useAssetInfo } from '../../../hooks/useCampaignInfo';
import { useActionManager } from '../../../hooks/useActionManager';

const AccountBox = ({
  children,
  onClick
}) => {
  const { tickAction } = useActionManager();
  const [seen, setSeen] = useState(false);

  const {
    utm_campaign: utmCampaign,
    type: campaignType
  } = useAssetInfo();

  const handleClick = () => {
    onClick();
    tickAction({ action: 'click', target: 'account-box' });
    handleCustomNavigation(`/pricing/?utm_source=jNewHeader&utm_medium=banner&utm_campaign=${utmCampaign}&utm_term=Upgrade%20for%20more&utm_content=Upgrade%20Now`, '_self', true);
  };

  useEffect(() => {
    if (!seen) {
      setSeen(true);
      tickAction({ action: 'seen' });
    }
  }, [seen]);

  return (
    <button
      type="button" onClick={handleClick} className="jfRHeader--account-box"
      data-campaign-type={campaignType}
    >
      {children}
    </button>
  );
};

AccountBox.propTypes = {
  children: node.isRequired,
  onClick: func
};

AccountBox.defaultProps = {
  onClick: () => {}
};

export default AccountBox;
