import React, { useMemo } from 'react';
import { object } from 'prop-types';

import { t } from '@jotforminc/translation';
import { formatCurrency } from '@jotforminc/currency';

import './styles.scss';
import MyPlanBanner, { MyPlanBannerButton, MyPlanBannerPlanText } from '../../../../../ui/MyPlanBanner/MyPlanBanner';

const MyAccountMyPlanBanner = ({
  buttonProps,
  planTextProps,
  user = {},
  ...props
}) => {
  const {
    account_type: { currency = 'USD' } = {},
    language
  } = user;

  const oneDollarText = formatCurrency({
    value: 1,
    locale: language,
    currency
  });

  const currencyImage = useMemo(() => {
    switch (currency) {
      case 'GBP':
        return 'pound';
      case 'EUR':
        return 'euro';
      default:
        return 'dollar';
    }
  }, [currency]);

  return (
    <MyPlanBanner
      className='silver-one-container'
      {...props}
    >
      <div className='silver-one'>
        <MyPlanBannerPlanText
          {...planTextProps}
        />
        <div className='one-dollar-billing-wrapper'>
          <MyPlanBannerButton
            colorStyle='primary'
            {...buttonProps}
          />
          <div className="one-dollar-billing-badge">
            <div className="one-dollar-billing-badge-inner">
              {t('LIMITED TIME OFFER')}
            </div>
          </div>
          <div className="one-dollar-billing-coin">
            <img src={`https://cdn.jotfor.ms/assets/img/campaigns/2024/one-dollar/billing/billing-${currencyImage}.png`} alt="" />
          </div>
          <div className='one-dollar-billing-title-area'>
            <div className="one-dollar-billing-first">
              {t('Silver Plan')}
            </div>
            <div className="one-dollar-billing-second">
              <strong>
                {t('Only {oneCurrencyUnit}')
                  .replace('{oneCurrencyUnit}', oneDollarText)}
              </strong>
              <span>{t('FOR A YEAR')}</span>
            </div>
          </div>
        </div>
      </div>
    </MyPlanBanner>
  );
};

MyAccountMyPlanBanner.propTypes = {
  user: object.isRequired,
  buttonProps: object.isRequired,
  planTextProps: object.isRequired
};

export default MyAccountMyPlanBanner;
