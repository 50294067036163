import React, { forwardRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  node, object, string, bool, elementType
} from 'prop-types';
import { usePopper } from 'react-popper';

import './popper.scss';

// eslint-disable-next-line react/prop-types
export const PopperContainer = forwardRef(({ children, style, ...props }, ref) => {
  return (
    <div
      className='jf-popper-container'
      style={{
        ...style,
        backgroundColor: '#F9F9FF',
        border: '1px solid #F3F3FE',
        borderRadius: '4px',
        padding: '12px 16px',
        fontSize: '10px',
        lineHeight: '12px',
        color: '#0A1551',
        zIndex: 100,
        filter: 'drop-shadow(0 4px 8px rgba(57, 66, 88, 0.4))',
        width: '256px'
      }}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
});

// eslint-disable-next-line react/prop-types
const Arrow = forwardRef(({ popoverPlacement, className, ...props }, ref) => {
  const classNames = [
    'jf-popper-arrow',
    popoverPlacement,
    className
  ].join(' ');

  return (
    <div
      className={classNames}
      ref={ref}
      {...props}
    />
  );
});

export const Popper = ({
  children,
  referenceElement,
  options,
  portalSelector,
  showArrow,
  PopperContainerRenderer
}) => {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const portalEl = portalSelector ? global.document.querySelector(portalSelector) : null;

  const popperModifiers = [];

  if (showArrow) {
    popperModifiers.push({ name: 'arrow', options: { element: arrowElement } });
  }

  const { styles, attributes, state: popperState } = usePopper(referenceElement, popperElement, {
    modifiers: popperModifiers,
    ...options
  });

  const popper = (
    <PopperContainerRenderer
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      {showArrow && (
        <Arrow
          ref={setArrowElement}
          style={styles.arrow}
          popoverPlacement={popperState?.placement}
        />
      )}
      { children }
    </PopperContainerRenderer>
  );

  if (portalSelector && portalEl) {
    return ReactDOM.createPortal(popper, portalEl);
  }

  return popper;
};

Popper.propTypes = {
  children: node.isRequired,
  referenceElement: object.isRequired,
  options: object,
  portalSelector: string,
  showArrow: bool,
  PopperContainerRenderer: elementType
};

Popper.defaultProps = {
  options: {},
  portalSelector: undefined,
  showArrow: true,
  PopperContainerRenderer: PopperContainer
};
