import React from 'react';
import { object } from 'prop-types';
import capitalize from 'lodash/capitalize';

import { t } from '@jotforminc/translation';

import { getPrettyLimits } from '../../../utils';

import { TARGET_PLAN_NAME_PLAN } from '../../../constants/texts';

import { usePricingContext } from '../../../context/pricingTableContext';

import FeatureItem from './FeatureItem';
import Price from '../../Price';
import PlanNote from '../../PlanNote';
import PlanCTA from '../../PlanCTA';

import '../../../styles/checkout-card.scss';

const CheckoutCardPlan = ({ plan }) => {
  const {
    state: {
      user: {
        campaign: { type: campaignType },
        showFormCountStats,
        visibleFeatures,
        hideAllLimits,
        showFieldsPerFormLimit,
        disableCallToActions
      }
    },
    onPlanContainerClicked,
    getShowCampaignPrice,
    getUserLanguage
  } = usePricingContext();

  const showCampaignPrice = getShowCampaignPrice({ plan });
  const language = getUserLanguage();

  let features = getPrettyLimits({
    t: t,
    visibleFeatures,
    hideAllLimits,
    language,
    limits: plan.limits,
    showFormLimit: showFormCountStats,
    showHipaa: true,
    showFieldsPerFormLimit,
    campaignType
  });

  // Move HIPAA and branding to top
  const hipaaFeatureIndex = features.findIndex(f => f.feature === 'hipaaCompliance');
  features = [
    features[hipaaFeatureIndex],
    { feature: 'branding' },
    ...features.filter((item, index) => index !== hipaaFeatureIndex)
  ];

  const handlePlanClick = () => {
    if (disableCallToActions) return;
    onPlanContainerClicked(plan);
  };

  return (
    <div className="checkout-card">
      <div className="checkout-card--left">
        <div className="checkout-card--left-inner">
          <div className="checkout-card--badge">
            <strong>2</strong>
            <strong>YEARS</strong>
          </div>
          <div className="checkout-card--plan">
            {`Jotform ${t(TARGET_PLAN_NAME_PLAN).replace('{targetPlanName}', capitalize(t(plan.name)))}`}
          </div>
          <div>
            <Price plan={plan} showCampaignPrice={showCampaignPrice} />
            <PlanNote plan={plan} />
          </div>
          <div onClick={handlePlanClick} className="checkout-card--cta">
            <PlanCTA
              plan={plan}
              showCampaignPrice={showCampaignPrice}
            />
          </div>
        </div>
      </div>
      <ul className="checkout-card--list">
        { features.map(feature => <FeatureItem key={feature.feature} feature={feature} />) }
      </ul>
    </div>
  );
};

CheckoutCardPlan.propTypes = {
  plan: object.isRequired
};

export default CheckoutCardPlan;
