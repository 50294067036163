/* eslint-disable max-len */
import {
  func,
  number, shape, string
} from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { t, translationRenderer } from '@jotforminc/translation';
import { capitalizeFirstLetter } from '@jotforminc/utils';

import { BSG_UTM, ENTERPRISE_UTM } from '../constants';
import {
  UPGRADE_CTA_WORKFLOW_RUNS, YOU_HAVE_REACHED_YOU_FORM_LIMIT, YOU_HAVE_REACHED_YOUR_AI_AGENTS_LIMIT, YOU_HAVE_REACHED_YOUR_WORKFLOW_RUNS_LIMIT
} from '../constants/texts';

import { getCurrencyDirection, getCurrencySymbol, LimitTypes } from '../utils';

import '../styles/campaignStyles/spring-sale-2024.scss';

// eslint-disable-next-line react/prop-types
export const LimitDialogDefaultTitleRenderer = ({ limitType } = {}) => {
  switch (limitType) {
    case 'workflowRuns':
      return <h1 className="jfBasicModal-title">{t(YOU_HAVE_REACHED_YOUR_WORKFLOW_RUNS_LIMIT)}</h1>;
    case 'aiAgents':
      return <h1 className="jfBasicModal-title">{t(YOU_HAVE_REACHED_YOUR_AI_AGENTS_LIMIT)}</h1>;
    default:
      return <h1 className="jfBasicModal-title">{t(YOU_HAVE_REACHED_YOU_FORM_LIMIT)}</h1>;
  }
};

const LimitDescriptionRenderer = ({
  limitType, trackCta, accountType, limit, dispachedFrom
}) => {
  switch (limitType) {
    case 'workflowRuns':
      return translationRenderer(UPGRADE_CTA_WORKFLOW_RUNS)({
        renderer1: str => <a onClick={trackCta} href={`${accountType === 'GOLD' ? `/enterprise/contact-sales${ENTERPRISE_UTM}` : `/pricing${BSG_UTM(accountType, dispachedFrom, limitType)}`}`} target="_blank">{str}</a>
      });

    case 'aiAgents':
      return translationRenderer(
        '[1[{accountType}]] accounts are limited to [2[{max_count}]] [3[{limitType}]]. Delete an existing [3[{limitType}]] or [4[upgrade]] to increase your [3[{limitType}]].'
      )({
        renderer1: () => capitalizeFirstLetter(accountType?.toLowerCase()),
        renderer2: () => limit,
        renderer3: () => t(LimitTypes.aiAgents.body),
        renderer4: str => <a onClick={trackCta} href={`${accountType === 'GOLD' ? `/enterprise/contact-sales${ENTERPRISE_UTM}` : `/pricing${BSG_UTM(accountType, dispachedFrom, limitType)}`}`} target="_blank">{str}</a>
      });

    default:
      return translationRenderer(
        '[1[{accountType}]] accounts are limited to [2[{max_count}]] forms. Delete an existing form or [3[upgrade]] to increase your form limit.'
      )({
        renderer1: () => capitalizeFirstLetter(accountType?.toLowerCase()),
        renderer2: () => limit,
        renderer3: str => <a onClick={trackCta} href={`${accountType === 'GOLD' ? `/enterprise/contact-sales${ENTERPRISE_UTM}` : `/pricing${BSG_UTM(accountType, dispachedFrom, limitType)}`}`} target="_blank">{str}</a>
      });
  }
};

export const LimitDialogDefaultDescriptionRenderer = ({
  accountType,
  limit, dispachedFrom, limitType, trackCta
}) => {
  return (
    <>
      <div>
        <div className="jfBasicModal-desc">
          <LimitDescriptionRenderer
            limitType={limitType}
            trackCta={trackCta}
            accountType={accountType}
            limit={limit}
            dispachedFrom={dispachedFrom}
          />
        </div>
      </div>
      {window?.user?.campaign?.type === 'REGULARFIFTYDISCOUNT' && window?.user?.campaign?.assetsVersion === 'v2' && window?.user?.campaign?.status === true && (
      <div className='spring-sale-limit-modal'>
        <div className="spring-sale-limit-modal-wrapper">
          <div className="bee">
            <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/spring-sale/flyin/bee.gif" alt="Bee" />
          </div>
          <div className="left-flowers">
            <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/spring-sale/limit-modal/flowers-left.svg" alt="Flowers Left" />
          </div>
          <div className="title-area">
            <h3 className="small-title">{t('SUMMER SALE')}</h3>
            <h2 className="big-title">{t('SAVE 50%')}</h2>
          </div>
          <div className="right-flowers">
            <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/spring-sale/limit-modal/flowers-right.svg" alt="Flowers Right" />
          </div>
        </div>
      </div>
      )}
    </>
  );
};

const getMonthlyPrice = yearlyPrice => {
  const net = (yearlyPrice / 12);
  const num = Math.floor(net);
  const decimal = Math.floor((net % 1) * 100);
  return {
    net, num, decimal
  };
};

export const LimitDialogPlanRenderer = ({
  name,
  className,
  prettyName,
  currency,
  prices: { monthly: currentMonthly = 0 },
  limits
}) => {
  const isFreeAccount = Number(currentMonthly) <= 0;

  const currencySymbol = getCurrencySymbol(currency);
  const currencyDirection = getCurrencyDirection(currency);
  return (
    <li className="jfLimitDialog-plan">
      <a className="jfLimitDialog-plan-link" href={`/pricing/${prettyName.toLowerCase()}?plan=${prettyName}`} target="_blank">
        <h2 className={classNames('jfLimitDialog-plan-title', className)}>
          {t(name)}
        </h2>
        <div className="jfLimitDialog-plan-details">
          {isFreeAccount && <span className="isFree isBigText">{t('FREE')}</span>}
          {!isFreeAccount > 0 && (
          <div className={`isPaid currency-${currency}`}>
            {currencyDirection === 'left' && <span className="currencyIndicator">{currencySymbol}</span>}
            <span className="monthlyPrice isBigText">{currentMonthly}</span>
            {currencyDirection === 'right' && <span className="currencyIndicator">{currencySymbol}</span>}
            <span className="monthText">
              /
              {t('month')}
            </span>
          </div>
          )}
        </div>
        <ul className="jfLimitDialog-features">
          <li className="jfLimitDialog-features-list">
            <div className="jfLimitDialog-features-name">
              {name === 'GOLD' && limits.formCount >= 10000000 ? t('Unlimited') : t('{formCount} Forms').replace('{formCount}', limits.formCount)}
            </div>
          </li>
        </ul>
      </a>
    </li>
  );
};

// eslint-disable-next-line complexity
export const LimitDialogCampaignPlanRenderer = ({
  name,
  prettyName, // Don't use this if you need please use prettyAccountName function
  className,
  currency,
  prices: { yearly: currentYearly = 0 }, // Campaign prices here
  fullPrices: { monthly: fullMonthlyBilledMonthly },
  newPrices: { prices: { monthly: newFullMonthlyBilledMonthly } = {} } = {},
  limits,
  campaignName,
  campaignLightbox,
  userAccountType,
  username
}) => {
  const currentAnnualBilledMonthly = React.useMemo(() => getMonthlyPrice(currentYearly), [currentYearly]);
  const isFreeAccount = Number(currentAnnualBilledMonthly.net) <= 0;
  if (isFreeAccount) { // Don't show free for campaigns
    return null;
  }
  const getCampaignHrefProps = (campName = '') => {
    switch (campName) {
      case 'BLACKFRIDAY':
        return {
          utm_campaign: 'blackFriday',
          utm_term: username || window?.user?.username,
          utm_medium: campaignLightbox ? 'MyformsLightbox' : 'FormLimit',
          utm_source: `bf2022-${campaignLightbox ? 'lb' : 'fl'}-${prettyName.charAt(0).toLowerCase()}`,
          utm_content: userAccountType || window?.user?.account_type?.name
        };
      case 'EOY':
        return {
          utm_campaign: 'eoy2022',
          utm_term: username || window?.user?.username,
          utm_medium: campaignLightbox ? 'MyformsLightbox' : 'FormLimit',
          utm_source: `eoy2022-${campaignLightbox ? 'lb' : 'fl'}-${prettyName.charAt(0).toLowerCase()}`,
          utm_content: userAccountType || window?.user?.account_type?.name
        };
      case 'LOCKDOWN':
        return {
          utm_campaign: 'ld2022',
          utm_term: username || window?.user?.username,
          utm_medium: campaignLightbox ? 'MyformsLightbox' : 'FormLimit',
          utm_source: `ld2022-${campaignLightbox ? 'lb' : 'fl'}-${prettyName.charAt(0).toLowerCase()}`,
          utm_content: userAccountType || window?.user?.account_type?.name
        };
      case 'NEWUSER':
      default:
        return {
          utm_campaign: 'newUser',
          utm_medium: 'formLimitModal',
          utm_source: `formLimit-${prettyName}`,
          utm_content: 'myforms',
          utm_term: 'create'
        };
    }
  };

  const {
    utm_campaign: utmCampaign,
    utm_medium: utmMedium,
    utm_source: utmSource,
    utm_content: utmContent,
    utm_term: utmTerm
  } = getCampaignHrefProps(campaignName);

  const getFormattedNewPrice = (price, afterDot) => {
    const sPrice = price.toString();
    const dotIndex = sPrice.indexOf('.');
    if (dotIndex === -1) {
      return price;
    }
    if (afterDot) {
      const afterDotText = sPrice.substring(dotIndex + 1);
      const formatAfterDotText = afterDotText.length === 1 ? `.${afterDotText}0` : `.${afterDotText}`;
      return formatAfterDotText;
    }
    return sPrice.substring(0, dotIndex);
  };

  const currencySymbol = getCurrencySymbol(currency);
  const currencyDirection = getCurrencyDirection(currency);
  const annualyBilledText = currencyDirection === 'left' ? 'Billed annually at {currency} {annuallyPrice}' : 'Billed annually at {annuallyPrice} {currency}';
  const pricingLink = `/pricing/${prettyName.toLowerCase()}?plan=${prettyName}&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_term=${utmTerm}&utm_content=${utmContent}&utm_campaign=${utmCampaign}`;
  const isCampaignAvailable = ['BLACKFRIDAY', 'EOY', 'NEWUSER', 'LOCKDOWN'].includes(campaignName);
  const isLockdownAvailable = ['LOCKDOWN'].includes(campaignName);
  return (
    <li className="jfLimitDialog-plan">
      <a className="jfLimitDialog-plan-link" href={pricingLink} target="_blank">
        {
          isCampaignAvailable ? (
            <h2 className={classNames('jfLimitDialog-plan-title', className)}>
              {t(prettyName)}
            </h2>
          ) : (
            <h2 className={classNames('jfLimitDialog-plan-title', className)}>
              {t(name)}
            </h2>
          )
        }
        <div className="jfLimitDialog-plan-details">
          {
            isLockdownAvailable && (
              <>
                <span className="priceTitle">
                  {t('Upcoming Price')}
                </span>
                <div className='upcomingPrice oldPrice'>
                  <div className='upcomingPrice-detail'>
                    {currencyDirection === 'left' && <span className="currencyIndicator isOld">{currencySymbol}</span>}
                    <span className="oldPriceText">
                      {' '}
                      {newFullMonthlyBilledMonthly}
                      {' '}
                    </span>
                    {currencyDirection === 'right' && <span className="currencyIndicator isOld">{currencySymbol}</span>}
                    <span className="monthText">
                      /
                      {t('month')}
                    </span>
                  </div>
                </div>
              </>
            )
          }
          {
            isLockdownAvailable && (
              <span className="priceTitle">
                {t('Current Price')}
              </span>
            )
          }
          <div className="oldPrice">
            {currencyDirection === 'left' && <span className="currencyIndicator isOld">{currencySymbol}</span>}
            {
              isCampaignAvailable ? (
                <span className="oldPriceText">
                  {' '}
                  {fullMonthlyBilledMonthly}
                  {' '}
                </span>
              ) : (
                fullMonthlyBilledMonthly
              )
            }
            {currencyDirection === 'right' && <span className="currencyIndicator isOld">{currencySymbol}</span>}
            <span className="monthText">
              /
              {t('month')}
            </span>
          </div>
          {
            isLockdownAvailable && (
              <span className="priceTitle">
                {t('Limited Time Discount')}
              </span>
            )
          }
          <div className={`isPaid currency-${currency}`}>
            {currencyDirection === 'left' && <span className="currencyIndicator">{currencySymbol}</span>}
            {
              isCampaignAvailable ? (
                <>
                  <span className="monthlyPrice isBigText">{getFormattedNewPrice(currentAnnualBilledMonthly.net, false)}</span>
                  <span className="monthlyPrice isBigText decimal">{getFormattedNewPrice(currentAnnualBilledMonthly.net, true)}</span>
                </>
              ) : (
                <span className="monthlyPrice isBigText">{currentAnnualBilledMonthly.net}</span>
              )
            }
            {currencyDirection === 'right' && <span className="currencyIndicator">{currencySymbol}</span>}
            <span className="monthText">
              /
              {t('month')}
            </span>
          </div>
        </div>
        <ul className="jfLimitDialog-features">
          <li className="jfLimitDialog-features-list">
            <div className="jfLimitDialog-features-name">
              {name === 'GOLD' ? t('Unlimited') : t('{formCount} Forms').replace('{formCount}', limits.formCount)}
            </div>
            {
              !isLockdownAvailable && (
                <div className="jfLimitDialog-features-desc">
                  {isFreeAccount
                    ? `* ${t('All features included')}`
                    : `${isCampaignAvailable
                      ? '(' : '* '}${t(annualyBilledText).replace('{annuallyPrice}', currentYearly).replace('{currency}', currencySymbol)}${isCampaignAvailable
                      ? ')'
                      : ''}`}
                </div>
              )
            }
          </li>
        </ul>
        <div className="jfLimitDialog-plan-upgrade">
          <a
            className={classNames('jfLimitDialog-plan-button', className)}
            href={pricingLink}
          >
            {
              isCampaignAvailable ? (
                <span>{t('Upgrade')}</span>
              ) : (
                <span>{t('UPGRADE')}</span>
              )
            }
          </a>
        </div>
      </a>
    </li>
  );
};

LimitDialogDefaultDescriptionRenderer.propTypes = {
  limit: number.isRequired,
  accountType: string.isRequired,
  dispachedFrom: string,
  limitType: string,
  trackCta: func
};

LimitDialogDefaultDescriptionRenderer.defaultProps = {
  dispachedFrom: '',
  limitType: 'formCount',
  trackCta: f => f
};

LimitDialogPlanRenderer.propTypes = {
  name: string.isRequired,
  className: string.isRequired,
  prices: shape({}).isRequired
  // currencyIndicator: string.isRequired,
  // features: arrayOf(shape({})).isRequired
};

LimitDialogCampaignPlanRenderer.propTypes = LimitDialogPlanRenderer.propTypes;
