import { useAssetManagerContext } from '../context/AssetManagerContext';
import { useAssetInfo } from './useCampaignInfo';

const trackOnceActions = ['seen'];

export const useActionManager = () => {
  const {
    actionManager, assetInfo: { type: campaignType }, assetType
  } = useAssetManagerContext();
  const { assetYear, assetsVersion } = useAssetInfo();
  const target = `${campaignType.toLowerCase()}-${assetType}-${assetsVersion}-${assetYear}`;

  const tickAction = ({ action, target: targetParam = '', trackOnce: trackOnceParam = false }) => {
    const trackOnce = trackOnceActions.includes(action) || trackOnceParam;
    let _target = target;
    if (targetParam) {
      _target = `${targetParam}-${target}`;
    }
    actionManager.registerJotformAction({ action, target: _target, trackOnce });
  };

  return { tickAction };
};
