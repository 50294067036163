/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { object, string } from 'prop-types';

import '../styles/deal_counter.scss';

const DEAL_COUNTER_INTERVAL = {
  BRONZE: [
    {
      startsAt: '0900',
      endsAt: '1100',
      initialValue: 220,
      intervalInMinutes: 12
    },
    {
      startsAt: '1100',
      endsAt: '1500',
      initialValue: 210,
      intervalInMinutes: 8
    },
    {
      startsAt: '1500',
      endsAt: '2100',
      initialValue: 180,
      intervalInMinutes: 15
    },
    {
      startsAt: '2100',
      endsAt: '2359',
      initialValue: 156,
      intervalInMinutes: 20
    }
  ],
  SILVER: [
    {
      startsAt: '0900',
      endsAt: '1100',
      initialValue: 117,
      intervalInMinutes: 12
    },
    {
      startsAt: '1100',
      endsAt: '1500',
      initialValue: 107,
      intervalInMinutes: 12
    },
    {
      startsAt: '1500',
      endsAt: '2100',
      initialValue: 87,
      intervalInMinutes: 36
    },
    {
      startsAt: '2100',
      endsAt: '2359',
      initialValue: 77,
      intervalInMinutes: 18
    }
  ],
  DIAMOND: [
    {
      startsAt: '0900',
      endsAt: '1100',
      initialValue: 42,
      intervalInMinutes: 60
    },
    {
      startsAt: '1100',
      endsAt: '1500',
      initialValue: 40,
      intervalInMinutes: 48
    },
    {
      startsAt: '1500',
      endsAt: '2100',
      initialValue: 35,
      intervalInMinutes: 90
    },
    {
      startsAt: '2100',
      endsAt: '2359',
      initialValue: 31,
      intervalInMinutes: 60
    }
  ],
  GOLD: [
    {
      startsAt: '0900',
      endsAt: '1100',
      initialValue: 42,
      intervalInMinutes: 60
    },
    {
      startsAt: '1100',
      endsAt: '1500',
      initialValue: 40,
      intervalInMinutes: 48
    },
    {
      startsAt: '1500',
      endsAt: '2100',
      initialValue: 35,
      intervalInMinutes: 90
    },
    {
      startsAt: '2100',
      endsAt: '2359',
      initialValue: 31,
      intervalInMinutes: 60
    }
  ]
};

const DEAL_COUNTER_SECOND_DAY_INTERVAL = {
  BRONZE: [
    {
      startsAt: '0000',
      endsAt: '0900',
      initialValue: 147,
      intervalInMinutes: 45
    },
    {
      startsAt: '0900',
      endsAt: '1100',
      initialValue: 135,
      intervalInMinutes: 12
    },
    {
      startsAt: '1100',
      endsAt: '1500',
      initialValue: 125,
      intervalInMinutes: 8
    },
    {
      startsAt: '1500',
      endsAt: '2100',
      initialValue: 95,
      intervalInMinutes: 15
    }
  ],
  SILVER: [
    {
      startsAt: '0000',
      endsAt: '0900',
      initialValue: 67,
      intervalInMinutes: 36
    },
    {
      startsAt: '0900',
      endsAt: '1100',
      initialValue: 52,
      intervalInMinutes: 12
    },
    {
      startsAt: '1100',
      endsAt: '1500',
      initialValue: 42,
      intervalInMinutes: 12
    },
    {
      startsAt: '1500',
      endsAt: '2100',
      initialValue: 22,
      intervalInMinutes: 36
    }
  ],
  DIAMOND: [
    {
      startsAt: '0000',
      endsAt: '0900',
      initialValue: 28,
      intervalInMinutes: 108
    },
    {
      startsAt: '0900',
      endsAt: '1100',
      initialValue: 23,
      intervalInMinutes: 60
    },
    {
      startsAt: '1100',
      endsAt: '1500',
      initialValue: 21,
      intervalInMinutes: 48
    },
    {
      startsAt: '1500',
      endsAt: '2100',
      initialValue: 16,
      intervalInMinutes: 90
    }
  ],
  GOLD: [
    {
      startsAt: '0000',
      endsAt: '0900',
      initialValue: 28,
      intervalInMinutes: 108
    },
    {
      startsAt: '0900',
      endsAt: '1100',
      initialValue: 23,
      intervalInMinutes: 60
    },
    {
      startsAt: '1100',
      endsAt: '1500',
      initialValue: 21,
      intervalInMinutes: 48
    },
    {
      startsAt: '1500',
      endsAt: '2100',
      initialValue: 16,
      intervalInMinutes: 90
    }
  ]
};

// IMPORTANT: Dates must be GMT -5 / EST
export const dealCounterStartDate = new Date('2024-01-11 09:00:00 EST');
export const dealCounterEndDate = new Date('2024-01-12 21:00:00 EST');

const DealCounter = ({ theme, plan: { name: planName } }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const currentDealCount = useMemo(() => {
    const planIntervals = currentDate >= new Date('2024-01-12 00:00:00 EST') ? DEAL_COUNTER_SECOND_DAY_INTERVAL[planName] : DEAL_COUNTER_INTERVAL[planName];

    currentDate.setHours(currentDate.getHours() - 4);
    // eslint-disable-next-line max-len
    const currentHour = `${currentDate.getUTCHours() < 10 ? `0${currentDate.getUTCHours()}` : currentDate.getUTCHours()}${currentDate.getUTCMinutes() < 10 ? `0${currentDate.getUTCMinutes()}` : currentDate.getUTCMinutes()}`;

    // Pick valid time interval array
    const validInterval = planIntervals.find(({ startsAt, endsAt }) => currentHour >= startsAt && currentHour <= endsAt);

    if (!validInterval) {
      return null;
    }

    // How much time did past from start time of the interval
    const timeDifference = currentHour - validInterval.startsAt;

    let timeDifferenceInHours = 0;
    let timeDifferenceInMinutes = 0;

    if (timeDifference >= 100) {
      timeDifferenceInHours = parseInt(timeDifference / 100, 10);
      timeDifferenceInMinutes = parseInt(timeDifference % 100, 10) + (timeDifferenceInHours * 60);
    } else {
      timeDifferenceInMinutes = timeDifference;
    }

    // Calculate the current left deal count
    const initialDealCount = validInterval.initialValue;
    return initialDealCount - parseInt(timeDifferenceInMinutes / validInterval.intervalInMinutes, 10);
  }, [currentDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000 * 60 * 60);

    return () => clearInterval(interval);
  }, []);

  // let userSeenDealsBefore = true;
  // if (readCookie(`SS2023DealCount-${planName}`) !== currentDealCount) {
  //   userSeenDealsBefore = false;
  // }

  // setCookie(`SS2023DealCount-${planName}`, '', 0);
  // setCookie(`SS2023DealCount-${planName}`, currentDealCount, 30);

  // There is an animation in front-end, so put deal count in an array in digits format
  // const shouldFirstDigitAnimate = (parseInt(currentDealCount / 10, 10) + 1) === (parseInt((currentDealCount + 1) / 10, 10));
  // const dealCountInDigits = Array.from(currentDealCount.toString(), (c, i) => currentDealCount.toString().slice(i, i + 1));
  // const animationStyle = userSeenDealsBefore ? { transform: 'translateY(0)' } : { transform: 'translateY(-1em)', animationName: 'tick-digit' };

  if (!currentDealCount) {
    return null;
  }

  return (
    <div className={`js-pricing-table-deal-counter theme-${theme}`}>
      <div className="deal-count-wrapper">
        <div className="counter">
          { currentDealCount }
        </div>
        <span>DEALS LEFT</span>
      </div>
    </div>
  );
};

DealCounter.propTypes = {
  theme: string.isRequired,
  plan: object.isRequired
};

export default DealCounter;
