import { formatCurrency } from '../utils/utils';

import { useCurrencyState } from '../context/context';

const useFormatCurrency = ({
  locale: forcedLocale,
  currency: forcedCurrency
} = {}) => {
  const { locale, currency } = useCurrencyState();

  const locale_ = forcedLocale || locale;
  const currency_ = forcedCurrency || currency;

  return ({ value }) => formatCurrency({
    value,
    locale: locale_,
    currency: currency_
  });
};

export default useFormatCurrency;
