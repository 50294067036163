/* eslint-disable complexity */
import React, { useState } from 'react';
import { object } from 'prop-types';
import cx from 'classnames';

import { IconAngleDown } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import PlanHeader from './PlanHeader';
import PlanContent from './PlanContent';
import PlanCTA from './PlanCTA';

import OneDollarSilverBadge from './ui/OneDollarSilverBadge/OneDollarSilverBadge';

import {
  THEMES
} from '../constants';

import { usePricingContext } from '../context/pricingTableContext';

import '../styles/plan.scss';

const Plan = ({ plan }) => {
  const {
    state: {
      user,
      campaignStatus,
      forceDiscount,
      theme,
      layout,
      period,
      disableCallToActions,
      showHIPAABadgeOnHeader,
      clickableColumn,
      showAllFeaturesButton
    },
    actionLogger,
    onPlanContainerClicked,
    showBestValueBadgeSelector,
    showOneDollarSilverBadgeSelector,
    getShowCampaignPrice
  } = usePricingContext();

  const showBestValueBadge = showBestValueBadgeSelector({ plan });
  const showCampaignPrice = getShowCampaignPrice({ plan });
  const showOneDollarSilverBadge = showOneDollarSilverBadgeSelector({ plan });

  // const showAllFeaturesButton = true;
  const [seeMore, setSeeMore] = useState(false);

  const { name: planName } = plan;

  const listClasses = cx('w-full max-w-72 radius-lg', {
    'sm:w-60 max-h-120 overflow-scroll px-2': theme === THEMES.LITE,
    'md:w-52 sm:w-60 xxl:w-68 p-1 mx-1': theme === THEMES.DEFAULT,
    'sm:w-48 p-1.5': (theme === THEMES.BILLING || theme === THEMES.MINI) && !campaignStatus,
    'max-w-full w-full sm:w-48 p-1.5': (theme === THEMES.MINI) && !campaignStatus,
    'xs:w-44 mx-2': (theme === THEMES.BILLING) && campaignStatus,
    'w-1/2 md:w-2/6 p-1.5': (theme === THEMES.MINI),
    'mt-6': !showBestValueBadge && (theme !== THEMES.LITE) && !showHIPAABadgeOnHeader
  });

  const buttonClasses = cx('text-center', {
    'w-full h-full flex flex-col': theme !== THEMES.LITE,
    'cursor-pointer': !disableCallToActions && clickableColumn
  });

  const planContentClasses = cx({
    planContentShowAllFeatures: showAllFeaturesButton,
    'planContentShowAllFeatures-seeMore': showAllFeaturesButton && seeMore
  });

  const handlePlanClick = ({ clickPoint, event }) => {
    event.stopPropagation();
    if (disableCallToActions || !clickableColumn) return;

    actionLogger({
      action: 'plan-clicked',
      target: [planName, period, clickPoint, layout].filter(f => f).join('_'),
      project: 'jf-pricing-table'
    });

    onPlanContainerClicked(plan);

    if (document.body.classList.contains('jfModalOpened')) return;
    document.body.classList.add('jfModalOpened');
  };

  const isTopCtaVisible = () => {
    if (disableCallToActions || [THEMES.LITE, THEMES.MINI].includes(theme)) return false;

    if (forceDiscount || campaignStatus) return true;

    return false;
  };

  const handleSeeMoreClick = e => {
    e.stopPropagation();
    setSeeMore(!seeMore);
    actionLogger({
      action: 'plan-clicked',
      target: [planName, period, 'see-more', layout].filter(f => f).join('_'),
      project: 'jf-pricing-table'
    });
  };

  return (
    <li
      className={listClasses}
      data-testid={`pricing-table-${planName.toLowerCase()}-column`}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={buttonClasses}
        onClick={event => handlePlanClick({ clickPoint: 'plan-container', event })}
        aria-label={`${plan.name}`}
      >
        { showOneDollarSilverBadge && (
          <OneDollarSilverBadge
            user={user}
          />
        )}
        <PlanHeader
          plan={plan}
          showCampaignPrice={showCampaignPrice}
        />
        {isTopCtaVisible() && (
          <PlanCTA
            plan={plan}
            type='top'
            showCampaignPrice={showCampaignPrice}
            onClick={event => handlePlanClick({ clickPoint: 'top-cta', event })}
          />
        )}
        <PlanContent
          plan={plan}
          className={planContentClasses}
        />
        {(!showAllFeaturesButton || seeMore) && (
          <PlanCTA
            plan={plan}
            type="bottom"
            showCampaignPrice={showCampaignPrice}
            onClick={event => handlePlanClick({ clickPoint: 'bottom-cta', event })}
          />
        )}
        {showAllFeaturesButton && !seeMore && (
          <button type="button" className="bg-transparent p-0 w-full flex justify-center items-center color-navy-300 mt-4" onClick={e => handleSeeMoreClick(e)}>
            {t('See more')}
            <IconAngleDown width={20} height={20} />
          </button>
        )}
      </div>
    </li>
  );
};

Plan.propTypes = {
  plan: object.isRequired
};

export default Plan;
