/* eslint-disable max-len */
import React from 'react';
import {
  func, number, oneOfType, string
} from 'prop-types';
import { translationRenderer } from '@jotforminc/translation';

import { LimitTypes } from '../utils';
import { BSG_UTM } from '../constants';
import {
  YOU_HAVE_REACHED_YOUR_LIMIT,
  ACCOUNTS_LIMITED_TO,
  UPGRADE_CTA_SUBMISSIONS,
  UPGRADE_CTA_FORM_COUNT,
  NEWUSER_CAMPAIGN_CTA,
  UPGRADE_CTA_WORKFLOW_RUNS,
  UPGRADE_CTA_AI_AGENTS
} from '../constants/texts';

const LimitDialogV2 = ({
  limitType, limit, activeCampaign, planType, trackCta, trackWelcomeCta, dispatchedFrom
}) => {
  const handleCTAClick = () => {
    trackCta();

    window.open(`/pricing${BSG_UTM(planType, dispatchedFrom, limitType)}`, '_blank');
  };

  const handleSaveButtonClick = () => {
    trackWelcomeCta();

    window.open(`/pricing?utm_source=limitDialog&utm_content=${dispatchedFrom}&utm_campaign=${limitType}-${planType}&utm_medium=banner&utm_term=todays-only-save-btn`, '_blank');
  };

  const prettyPlanName = planType?.charAt(0)?.toUpperCase() + planType?.slice(1)?.toLowerCase();

  const renderLimitDescription = type => {
    switch (type) {
      case 'formCount':
        return (
          <>
            {translationRenderer(UPGRADE_CTA_FORM_COUNT)({
              renderer1: () => <span>{prettyPlanName}</span>,
              renderer2: () => <span>{limit}</span>,
              renderer3: upgradeText => <button className='upgrade-cta' type="button" onClick={() => handleCTAClick()}>{upgradeText}</button>
            })}
          </>
        );
      case 'submissions':
        return (
          <>
            {translationRenderer(ACCOUNTS_LIMITED_TO)({
              renderer1: () => <span>{prettyPlanName}</span>,
              renderer2: () => <span>{limit}</span>,
              renderer3: () => <span>{LimitTypes?.[limitType]?.body}</span>
            })}
            <p>
              {translationRenderer(UPGRADE_CTA_SUBMISSIONS)({
                renderer1: upgradeText => <button className='upgrade-cta' type="button" onClick={() => handleCTAClick()}>{upgradeText}</button>
              })}
            </p>
          </>
        );
      case 'workflowRuns':
        return (
          <>
            {translationRenderer(ACCOUNTS_LIMITED_TO)({
              renderer1: () => <span>{prettyPlanName}</span>,
              renderer2: () => <span>{limit}</span>,
              renderer3: () => <span>{LimitTypes?.[limitType]?.body}</span>
            })}
            <p>
              {translationRenderer(UPGRADE_CTA_WORKFLOW_RUNS)({
                renderer1: upgradeText => <button className='upgrade-cta' type="button" onClick={() => handleCTAClick()}>{upgradeText}</button>
              })}
            </p>
          </>
        );
      case 'aiAgents':
        return (
          <>
            {translationRenderer(ACCOUNTS_LIMITED_TO)({
              renderer1: () => <span>{prettyPlanName}</span>,
              renderer2: () => <span>{limit}</span>,
              renderer3: () => <span>{LimitTypes?.[limitType]?.body}</span>
            })}
            <p>
              {translationRenderer(UPGRADE_CTA_AI_AGENTS)({
                renderer1: upgradeText => <button className='upgrade-cta' type="button" onClick={() => handleCTAClick()}>{upgradeText}</button>
              })}
            </p>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="limit-dialog-v2-wrapper">
        <div className="icon">
          <svg
            width="60" height="60" viewBox="0 0 60 60"
            fill="none" xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M30 6.00008C16.7452 6.00008 6.00002 16.7452 6.00002 30.0001C6.00002 43.2549 16.7452 54.0001 30 54.0001C43.2548 54.0001 54 43.2549 54 30.0001C54
               16.7452 43.2548 6.00008 30 6.00008ZM0.666687 30.0001C0.666687 13.7997 13.7997 0.666748 30 0.666748C46.2004 0.666748 59.3334 13.7997 59.3334 30.0001C59.3334
               46.2004 46.2004 59.3334 30 59.3334C13.7997 59.3334 0.666687 46.2004 0.666687 30.0001ZM30 38.0001C28.5273 38.0001 27.3334 36.8062 27.3334 35.3334V16.6667C27.3334
               15.194 28.5273 14.0001 30 14.0001C31.4728 14.0001 32.6667 15.194 32.6667 16.6667V35.3334C32.6667 36.8062 31.4728 38.0001 30 38.0001ZM30 40.6667C28.5273 40.6667 27.3334
               41.8607 27.3334 43.3334C27.3334 44.8062 28.5273 46.0001 30 46.0001C31.4728 46.0001 32.6667 44.8062 32.6667 43.3334C32.6667 41.8607 31.4728 40.6667 30 40.6667Z"
              fill="#DC2626"
            />
          </svg>
        </div>
        <div className="title-and-desc">
          <div className="title">
            {translationRenderer(YOU_HAVE_REACHED_YOUR_LIMIT)({
              renderer1: () => <span>{LimitTypes?.[limitType]?.title}</span>
            })}
          </div>
          <div className="desc">
            {renderLimitDescription(limitType)}
          </div>
          {['NEWUSER', 'OVERQUOTAUSER'].includes(activeCampaign) && (
            <div className='todays-only-wrapper'>
              <button type='button' onClick={() => handleSaveButtonClick()} className="campaign-wrapper">
                <div className="campaign-badge-border">
                  <div className="campaign-badge">
                    {translationRenderer(NEWUSER_CAMPAIGN_CTA)({
                      renderer1: todayOnly => <strong>{todayOnly}</strong>
                    })}
                  </div>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
LimitDialogV2.propTypes = {
  activeCampaign: string,
  limitType: string,
  limit: oneOfType([string, number]),
  planType: string,
  trackCta: func,
  trackWelcomeCta: func,
  dispatchedFrom: string
};

LimitDialogV2.defaultProps = {
  activeCampaign: '',
  limitType: 'formCount',
  limit: 5,
  planType: 'FREE',
  trackCta: f => f,
  trackWelcomeCta: f => f,
  dispatchedFrom: ''
};
export default LimitDialogV2;
