import React from 'react';
import { string, bool } from 'prop-types';
import cx from 'classnames';

import { t } from '@jotforminc/translation';
import { Currency } from '@jotforminc/currency';

import HIPAAHeaderBadge from '../../assets/svg/iconHeaderHipaa.svg';

import { PLANS, SIGN_PLAN_COLORS } from '../../constants';
import { CUSTOM_PRICE, MONTH } from '../../constants/texts';

import DealCounter from '../../components/DealCounter';
// import BestValueRibbon from '../../components/BestValueRibbon';
import PlanCta from './PlanCTA';

import { usePricingTable } from '../../hooks/usePricingTable';

import OneDollarSilverBadge from '../../components/ui/OneDollarSilverBadge/OneDollarSilverBadge';

const PlanHeader = ({ planName, showCTA }) => {
  const {
    user,
    showHIPAABadgeOnHeader,
    isTopCTAVisible,
    getPlan
  } = usePricingTable();

  const {
    prettyName,
    price,
    fullPrice,
    pricePeriod,
    note,
    isBestValue,
    showOneDollarSilverBadge,
    showDealCounter
  } = getPlan(planName);

  const isPriceInteger = price % 1 === 0;

  const titleClasses = cx('relative flex justify-center items-center w-full font-bold radius-tl radius-tr text-2xl', {
    'bg-blue-500 border-white': isBestValue
  });

  const isCTAVisible = showCTA || isTopCTAVisible;
  const PLAN_COLORS = isBestValue ? SIGN_PLAN_COLORS.BEST_PLAN : SIGN_PLAN_COLORS[planName];

  return (
    <div className="w-full" id={`pricing-table-${planName.toLowerCase()}-header`}>
      { showOneDollarSilverBadge && (
        <OneDollarSilverBadge
          user={user}
        />
      )}
      {showDealCounter && <DealCounter theme="default" plan={{ planName }} />}
      <div
        className={titleClasses}
        style={{
          color: PLAN_COLORS.COLOR,
          backgroundColor: PLAN_COLORS.BACKGROUND
        }}
      >
        <span className="w-full border-b-2 border-navy-25 text-uppercase py-4 mx-4">
          {prettyName}
        </span>
        {/* {isBestValue && <BestValueRibbon />} */}
        {isBestValue}
        {showHIPAABadgeOnHeader && (
          <>
            <div className="absolute -top-2 left-2 z-1">
              <HIPAAHeaderBadge className="w-10 h-auto block" />
            </div>
            <div className="absolute -top-2 inset-x-0 h-2 bg-white" />
          </>
        )}
      </div>
      <div
        className={cx(
          'flex flex-col w-full justify-between text-sm px-2 xl:px-0 pb-4',
          {
            'min-h-60 lg:min-h-56': isCTAVisible,
            'min-h-32': !isCTAVisible
          }
        )}
        style={{
          color: PLAN_COLORS.CONTENT_TEXT,
          backgroundColor: PLAN_COLORS.BACKGROUND
        }}
      >
        <div className='flex flex-col grow-1 justify-center items-center'>
          {planName === PLANS.ENTERPRISE && (
          <span className='text-center color-navy-600 font-bold tracking-xxxs text-4xl'>
            {t(CUSTOM_PRICE)}
          </span>
          )}
          {planName === PLANS.FREE && (
          <span className="flex flex-col">
            <strong className="mx-2 font-bold mt-4 text-6xl">
              {t(planName)}
            </strong>
          </span>
          )}
          {![PLANS.ENTERPRISE, PLANS.FREE].includes(planName) && (
          <div className="flex flex-col text-xl">
            {
              fullPrice && (
                <div className="text-md pt-1" style={{ color: PLAN_COLORS.HEADER_NOTE }}>
                  <strong className="font-bold relative">
                    <Currency
                      value={fullPrice}
                      CurrencyRenderer={({ children }) => <span className="mr-1">{children}</span>}
                      ValueRenderer={({ children }) => (
                        <span className="text-2xl">
                          <div
                            className="absolute h-1 bg-red-300 top-4/12 left-0"
                            style={{
                              width: '120%',
                              transform: 'rotate(-25deg)'
                            }}
                          />
                          {children}
                        </span>
                      )}
                    />
                  </strong>
                  {`/${pricePeriod}`}
                </div>
              )
            }
            {
              price && (
                <div className="text-md py-2 current-price-wrapper">
                  <div className="flex items-end">
                    <Currency
                      value={price}
                      CurrencyRenderer={({ children }) => <span className="text-sm">{children}</span>}
                      ValueRenderer={({ children }) => <strong className="font-extrabold flex items-end text-8xl line-height-6xl">{children}</strong>}
                      DecimalWrapperRenderer={({ children }) => (
                        <span className="flex flex-col text-left">
                          <strong className="font-bold text-xl line-height-md">{children}</strong>
                          <span className="text-sm font-normal line-height-xs" style={{ color: PLAN_COLORS.HEADER_NOTE }}>
                            {`/${t(MONTH)}`}
                          </span>
                        </span>
                      )}
                    />
                    {
                      isPriceInteger && (
                        <span className="text-sm font-normal line-height-xs" style={{ color: PLAN_COLORS.HEADER_NOTE }}>
                          {`/${t(MONTH)}`}
                        </span>
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
          )}
          {note && (
            <span className="text-md mt-2" style={{ color: PLAN_COLORS.HEADER_NOTE }}>
              {note}
            </span>
          )}
        </div>
        {isCTAVisible && (
          <PlanCta
            planName={planName}
            isTopCta={true}
          />
        )}
      </div>
    </div>
  );
};

PlanHeader.propTypes = {
  planName: string.isRequired,
  showCTA: bool
  // palette: object.isRequired
};

PlanHeader.defaultProps = {
  showCTA: false
};

export default PlanHeader;
