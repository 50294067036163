/* eslint-disable max-len */
export const ENTERPRISE_UTM = dispatchedFrom => `?utm_medium=referral&utm_source=jotform.com&utm_campaign=enterprise_pricing&utm_content=jotform_${dispatchedFrom ?? 'limit_dialog'}_enterprise_column_bottom_cta`;
export const BSG_UTM = (accountType, dispatchedFrom, limitType) => {
  return `?utm_source=limitDialog&utm_content=${dispatchedFrom}&utm_campaign=${limitType ? `${limitType}-` : ''}${accountType}&utm_medium=banner&utm_term=${accountType === 'GOLD' ? 'contact-sales-text' : 'upgrade-now-text'}`;
};
export const limitTypes = {
  submissions: 'Monthly Submission',
  payments: 'Payment Submission',
  uploads: 'Upload Space',
  views: 'Form View',
  signed_documents: 'Monthly Signed Documents',
  signedDocuments: 'Monthly Signed Documents',
  total_submissions: 'Submission Storage'
};
