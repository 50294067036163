import React from 'react';
import { string, bool } from 'prop-types';
import cx from 'classnames';

import { COLOR_THEMES } from '../../constants';

import { CURRENT, DOWNGRADE } from '../../constants/texts';

import { usePricingTable } from '../../hooks/usePricingTable';

const PlanCta = ({ planName, isTopCta }) => {
  const {
    theme,
    getPlan
  } = usePricingTable();

  const {
    ctaText,
    downgradeNotice,
    onPlanContainerClicked,
    isBestValue,
    colors
  } = getPlan(planName);

  const showDowngradeNoticeClass = cx('text-xs min-h-12 flex items-center', {
    'bg-white -mx-3': theme === COLOR_THEMES.LIGHT
  });

  const pricingPlanCTAClasses = cx('cursor-pointer text-lg flex justify-center items-center w-full font-bold transition hover:opacity-70 radius h-12 mt-6', {
    'max-w-10/12 mx-auto': theme === COLOR_THEMES.DARK,
    'max-w-full': theme === COLOR_THEMES.LIGHT
  });

  let ctaBgColor = colors.COLOR;
  let ctaTextColor = colors.TEXT;

  if (ctaText === CURRENT) {
    ctaBgColor = '#D3DAE5';
    ctaTextColor = '#394258';
  } else if (ctaText === DOWNGRADE) {
    ctaBgColor = '#e6ebf2';
    ctaTextColor = '#57647e';
  }

  if (isBestValue && isTopCta) {
    ctaBgColor = '#ffffff';
    ctaTextColor = '#2e69ff';
  }

  const handleCTAClick = event => {
    event.stopPropagation();
    onPlanContainerClicked({ clickPoint: isTopCta ? 'top-cta' : 'bottom-cta' });
  };

  return (
    <button
      type='button'
      className="w-full px-4"
      data-test-id={`jf-pt-plan-cta${isTopCta ? '-top' : ''}`}
      onClick={handleCTAClick}
    >
      {
        downgradeNotice
          ? (
            <div
              className={showDowngradeNoticeClass}
              style={{ color: theme === COLOR_THEMES.LIGHT ? '#394258' : '#f98e03' }}
            >
              {downgradeNotice}
            </div>
          )
          : (
            <div
              className={pricingPlanCTAClasses}
              style={{
                backgroundColor: ctaBgColor,
                color: ctaTextColor
              }}
            >
              {ctaText}
            </div>
          )
      }
    </button>
  );
};

PlanCta.propTypes = {
  planName: string.isRequired,
  isTopCta: bool.isRequired
};

export default PlanCta;
