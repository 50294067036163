import React from 'react';
import { object } from 'prop-types';

import { t, translationRenderer } from '@jotforminc/translation';

import MyPlanBanner, { MyPlanBannerButton, MyPlanBannerPlanText } from '../../../../../ui/MyPlanBanner/MyPlanBanner';

import './styles.scss';

const MyAccountMyPlanBanner = ({
  buttonProps,
  planTextProps,
  ...props
}) => {
  return (
    <MyPlanBanner
      className='py-0 spring-billing-container'
      {...props}
    >
      <div className='spring-billing-content'>
        <MyPlanBannerPlanText
          {...planTextProps}
        />
        <MyPlanBannerButton
          {...buttonProps}
        />
        <div className='flowers-image'>
          <img
            className="bee"
            src="https://cdn01.jotfor.ms/assets/img/campaigns/2024/spring-sale/flyin/bee.gif"
            alt="Bee"
          />
          <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/spring-sale/billing/flowers.svg" alt="summer sale flowers" />
        </div>
        <div className='spring-billing-title-area'>
          <div className="spring-billing-first">
            {t('SUMMER SALE')}
          </div>
          <div className="spring-billing-second">
            {translationRenderer('SAVE [1[50%]]')({
              renderer1: text => (<strong>{text}</strong>)
            })}
          </div>
        </div>
      </div>
    </MyPlanBanner>
  );
};

MyAccountMyPlanBanner.propTypes = {
  buttonProps: object.isRequired,
  planTextProps: object.isRequired
};

export default MyAccountMyPlanBanner;
