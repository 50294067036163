import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';

import '../styles/campaignStyles/summersale.scss';
import Starfish from '../assets/svg/summer/starfish.svg';
import Plant from '../assets/svg/summer/plant.svg';
import SummerBg from '../assets/svg/summer/bg.svg';

export const SummerPromotionRenderer = () => (
  <div className="jfLimitDialog-ss23-section">
    <div className="jfLimitDialog-ss23-text">
      <strong className='jfLimitDialog-ss23-summer'>{t('SUMMER SALE')}</strong>
      <p>
        {translationRenderer('SAVE [1[50%]]')({
          renderer1: text => <strong>{text}</strong>
        })}
      </p>
    </div>
    <div className='ss2023-starfish'>
      <Starfish />
    </div>
    <div className='ss2023-plant'>
      <Plant />
    </div>
    <div className='ss2023-bg'>
      <SummerBg />
    </div>
  </div>
);
