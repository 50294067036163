/* eslint-disable max-len */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from '@jotforminc/uikit';

import { IconXmark } from '@jotforminc/svg-icons';
import LimitDialog from './LimitDialog';

const HeaderRenderer = ({ onClose }) => (
  <button className="jfBasicModal-close" type="button" onClick={onClose}>
    <IconXmark />
  </button>
);

HeaderRenderer.propTypes = {
  onClose: PropTypes.func
};

HeaderRenderer.defaultProps = {
  onClose: f => f
};

const LimitDialogModal = ({
  onClose,
  modalProps,
  currentAccountType,
  activeCampaign,
  limitDialogV2,
  ...limitDialogProps
}) => {
  const uikitModalRef = useRef(null);

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnOutsideClick={false}
      closeOnEscPress={true}
      onModalClose={onClose}
      usePortal={true}
      DialogRenderer={({ children }) => (
        <div id="jfBasicModal-limit" className={classNames('jfBasicModal', { hasActiveCampaign: activeCampaign, 'jfBasicModal-content-wrapper-limit-dialog-v2': limitDialogV2 })}>
          {children}
        </div>
      )}
      ContentRenderer={({ children }) => (
        <div className={`jfBasicModal-content-wrapper  ${currentAccountType === 'GOLD' ? 'limitModalEnterprise' : ''}`}>
          <div className="jfBasicModal-content">
            {children}
          </div>
        </div>
      )}
      HeaderRenderer={() => (
        <HeaderRenderer
          onClose={onClose}
          currentAccountType={currentAccountType}
        />
      )}
      {...modalProps}
    >
      <LimitDialog
        currentAccountType={currentAccountType}
        activeCampaign={activeCampaign}
        limitDialogV2={limitDialogV2}
        onClose={onClose}
        {...limitDialogProps}
      />
    </Modal>
  );
};

LimitDialogModal.propTypes = {
  onClose: PropTypes.func,
  modalProps: PropTypes.shape({}),
  currentAccountType: PropTypes.string,
  activeCampaign: PropTypes.string,
  limitDialogV2: PropTypes.bool
};

LimitDialogModal.defaultProps = {
  onClose: f => f,
  modalProps: {},
  currentAccountType: 'FREE',
  activeCampaign: '',
  limitDialogV2: false
};

export default LimitDialogModal;
