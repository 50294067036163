/* eslint-disable max-len */
import React, { useMemo } from 'react';

import { t } from '@jotforminc/translation';
import { ActionManager } from '@jotforminc/abtest-manager';
import { handleCustomNavigation } from '@jotforminc/utils';

import './v2.scss';

import { object, string } from 'prop-types';
import ExitModal from '../../../../../ui/ExitModal/ExitModal';

import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';
import BlackText from './black_text.svg';
import FridayText from './friday_text.svg';

const BlackFridayExitModalV2 = ({ accountTypeName, user = {} }) => {
  const {
    utm_campaign: utmCampaign
  } = useAssetInfo();
  const utmSource = `${utmCampaign}-exit-modal-cta`;

  const actionManager = useMemo(() => new ActionManager({ user, projectName: 'growthAssetManager' }), [user]);

  const onClose = () => {
    actionManager.registerJotformAction({ action: 'click', target: 'BlackFridayExitModalV2' });
  };

  const handleClick = () => {
    actionManager.registerJotformAction({ action: 'click', target: 'BlackFridayExitModalV2' });
    handleCustomNavigation(`/pricing/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=exit-intent`, '_self', true);
  };

  const seenAction = () => {
    actionManager.registerJotformAction({ action: 'seen', target: 'BlackFridayExitModalV2' });
  };

  return (
    <ExitModal
      onClose={onClose}
      title="Black Friday Exit Modal"
      seenAction={seenAction}
    >
      <div className="exit-modal-content">
        <div className="exit-modal--area">
          <div className="bag-wrapper wrapper-left">
            <img className="sale-bag" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/img-left-bag.png" alt="Jotform Sale Bag" />
            <div className="shadow" />
          </div>
          <div className="exit-modal--container">
            <div className="exit-modal--title">
              <p className="exit-modal--title-h2">
                {t('LIMITED TIME OFFER')}
              </p>
              <div className="title-banner">
                <div className="wrapper">
                  <div className="svg1">
                    <BlackText />
                  </div>
                  <div className="svg2">
                    <FridayText />
                  </div>
                </div>
              </div>
              <div className="title-percent">
                <span>{t('50% OFF')}</span>
                <span>
                  {t('ON ANNUAL PLANS')}
                  *
                </span>
              </div>
              <button className="exit-modal--cta js-exit-modal-cta" type="button" onClick={handleClick}>
                {t('Upgrade now')}
              </button>
            </div>
            <p className="exit-modal--title-h5">
              *
              {t('Offer does not apply to Jotform Enterprise. The 50-percent discount is a one-time offer when upgrading to an annual Bronze, Silver, or Gold plan. Plans will auto-renew at the full price one year after the discount is applied. After one year, plans can also be adjusted to the full-priced monthly plan, unless canceled. If you cancel your account within 30 days of initial payment, a full refund will be issued. Cannot be combined with other offers.')}
            </p>
          </div>
          <div className="bag-wrapper wrapper-right">
            <img className="sale-bag" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/img-right-bag.png" alt="Jotform Sale Bag" />
            <div className="shadow" />
          </div>
        </div>
      </div>
    </ExitModal>
  );
};

BlackFridayExitModalV2.propTypes = {
  accountTypeName: string.isRequired,
  user: object.isRequired
};

export default BlackFridayExitModalV2;
