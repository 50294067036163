import React from 'react';
import { string } from 'prop-types';

import { t, translationRenderer } from '@jotforminc/translation';

import './v2.scss';

import FlyIn from '../../../../../ui/FlyIn/FlyIn';

const RegularFiftyDiscountFlyInV1 = ({ isSticky, ...props }) => {
  const handleClose = () => {
    console.log('action: flyin close');
  };

  return (
    <FlyIn
      onClose={handleClose}
      isSticky={isSticky}
      {...props}
    >
      <div className="jf-spring-sale-wrapper inner" data-jfa="spring-sale-flyin">
        <div className="jf-spring-sale-wrapper-container">
          <div className="images">
            <img className="img-flowers" src="https://cdn01.jotfor.ms/assets/img/campaigns/2024/spring-sale/flyin/flowers.svg" alt="Flowers" />
            <img className="img-bee" src="https://cdn01.jotfor.ms/assets/img/campaigns/2024/spring-sale/flyin/bee.gif" alt="Bee" />
          </div>
          <div className="text-area">
            <h6>
              {t('SUMMER SALE')}
            </h6>
            <h4>
              {translationRenderer('SAVE [1[50%]]')({
                renderer1: str => (<strong>{str}</strong>)
              })}
            </h4>
          </div>
          <a className="action-link" href="/pricing/?utm_source=summerSale-2024-flyin&utm_medium=website&utm_content=photography&utm_campaign=summerSale-2024">
            {t('Upgrade now')}
          </a>
        </div>
      </div>
    </FlyIn>
  );
};

RegularFiftyDiscountFlyInV1.propTypes = {
  isSticky: string.isRequired
};

export default RegularFiftyDiscountFlyInV1;
