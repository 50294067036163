import { useState, useEffect } from 'react';

import { customFormatToParts } from '../utils/utils';

const supportsES2020 = () => {
  try {
    const s = new Intl.NumberFormat(
      'en',
      {
        style: 'unit',
        unit: 'bit',
        unitDisplay: 'long',
        notation: 'scientific'
      }
    ).format(10000);

    if (s !== '1E4 bits') {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

const shouldPolyfill = () => typeof Intl === 'undefined' || !('NumberFormat' in Intl) || !supportsES2020();

const usePolyfillLoader = () => {
  const [isPolyfillLoaded, setPolyfillLoaded] = useState();

  const loadPolyfill = () => {
    if (shouldPolyfill()) {
      Intl.NumberFormat.prototype.formatToParts = customFormatToParts;
    }
    setPolyfillLoaded(true);
  };

  useEffect(() => {
    if (!isPolyfillLoaded) {
      loadPolyfill();
    }
  }, []);

  return isPolyfillLoaded;
};

export default usePolyfillLoader;
