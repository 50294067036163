import React from 'react';
import { string, bool, object } from 'prop-types';
import cx from 'classnames';
import { usePricingTable } from '../../hooks/usePricingTable';
import { SIGN_PLAN_COLORS } from '../../constants';

import '../../styles/best_value.scss';

const FeatureItem = ({
  planName,
  feature,
  isLastItem
}) => {
  const {
    info, count, Icon, isPulledUp
  } = feature;

  const { getPlan } = usePricingTable();
  const { colors, isBestValue } = getPlan(planName);

  const PLAN_COLORS = isBestValue ? SIGN_PLAN_COLORS.BEST_PLAN : SIGN_PLAN_COLORS[planName];

  return (
    <div
      className={cx(
        'relative flex justify-center items-center text-sm py-5'
      )}
      style={{
        borderBottom: isLastItem ? 'none' : '1px solid #EDEDF6'
      }}
    >
      <span className="flex flex-col space-y-1">
        <strong className='font-bold block flex justify-center items-center text-2xl mb-2 mx-2'>
          {
            ([1, 0].includes(count) && Icon)
              ? <Icon />
              : (
                <>
                  {isPulledUp && (
                    <i style={{ color: colors.COLOR }} className="mr-2">
                      <Icon className="w-6 h-6 block sign-icon" />
                    </i>
                  )}
                  {count}
                </>
              )
          }
        </strong>
        <span
          className='font-medium text-xs'
          style={{
            color: PLAN_COLORS.CONTENT_TEXT_BOTTOM
          }}
        >
          {info}
        </span>
      </span>
    </div>
  );
};

FeatureItem.propTypes = {
  planName: string.isRequired,
  feature: object.isRequired,
  isLastItem: bool.isRequired
};

export default FeatureItem;
