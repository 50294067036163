/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { t } from '@jotforminc/translation';

import {
  CAMPAIGN_TYPES,
  PERIODS,
  SOURCES,
  THEMES
} from '../constants';
import { SAVE_UP_TO_DISCOUNTRATE } from '../constants/texts';

import { usePricingContext } from '../context/pricingTableContext';

import Arrow from '../assets/svg/arrow.svg';

import '../styles/switch.scss';

const PERIOD_TEXTS = {
  [PERIODS.MONTHLY]: 'Monthly',
  [PERIODS.YEARLY]: 'Yearly',
  [PERIODS.BIYEARLY]: '2-Year'
};

const Switch = () => {
  const {
    state: {
      source,
      theme,
      period,
      campaignStatus,
      campaign: {
        type: campaignType,
        assetsVersion,
        discountPercent = '50'
      },
      showEoySwitchLongMessage,
      showTripleSwitch
    },
    setState,
    showBiyearlyPeriodSelector
  } = usePricingContext();

  const isEoyAssetVersionV2 = campaignStatus && CAMPAIGN_TYPES.EOY === campaignType && assetsVersion === 'v2' && source === SOURCES.PRICING;

  const showBiyearlyPeriod = showBiyearlyPeriodSelector();

  const [selectedPeriod, setSelectedPeriod] = useState(period);

  const discountRate = campaignStatus ? (showBiyearlyPeriod ? 60 : discountPercent) : 20;

  const periodList = showTripleSwitch
    ? [PERIODS.MONTHLY, PERIODS.YEARLY, PERIODS.BIYEARLY]
    : (showBiyearlyPeriod ? [PERIODS.YEARLY, PERIODS.BIYEARLY] : [PERIODS.MONTHLY, PERIODS.YEARLY]);

  const isNewUserSwitchThemeEnable = [THEMES.BILLING, THEMES.DEFAULT].includes(theme) && campaignStatus && campaignType === CAMPAIGN_TYPES.NEWUSER;

  const getSaveUpText = () => {
    if ([SOURCES.SURVEYS, SOURCES.TYPEFORM_ALTERNATIVE].includes(source)) {
      return t('Save {discountRate}%').replace('{discountRate}', discountPercent);
    }

    if (campaignStatus && [CAMPAIGN_TYPES.SAVEUPTOFORTYDISCOUNT].includes(campaignType)) {
      return t(SAVE_UP_TO_DISCOUNTRATE).replace('{discountRate}', discountRate);
    }

    if (
      campaignStatus
      && [
        CAMPAIGN_TYPES.HIPAAGOLD, CAMPAIGN_TYPES.EDUCATION, CAMPAIGN_TYPES.NONPROFIT,
        CAMPAIGN_TYPES.REGULARFIFTYDISCOUNT
      ].includes(campaignType)
    ) {
      return t('Save {discountRate}%').replace('{discountRate}', discountRate);
    }

    if (showTripleSwitch && !campaignStatus) {
      return t('Save up to 20% on annual plans');
    }

    if (campaignStatus && [CAMPAIGN_TYPES.EOY, CAMPAIGN_TYPES.NEWUSER].includes(campaignType)) {
      return showEoySwitchLongMessage ? t('Save up to 60% on 2-Year plans') : t(SAVE_UP_TO_DISCOUNTRATE).replace('{discountRate}', showBiyearlyPeriod ? '60%' : `${discountPercent}%`);
    }

    return t(SAVE_UP_TO_DISCOUNTRATE).replace('{discountRate}', '20%');
  };

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      period: selectedPeriod
    }));

    if (global.onJfPricingTablePeriodChanged) {
      global.onJfPricingTablePeriodChanged(selectedPeriod);
    }
  }, [selectedPeriod]);

  const switchClasses = cx(
    'p-1 flex items-center radius-full mx-auto relative mb-4',
    {
      'bg-white bg-opacity-20': isEoyAssetVersionV2 && !isNewUserSwitchThemeEnable,
      'bg-navy-300 bg-opacity-10': !isEoyAssetVersionV2 && !isNewUserSwitchThemeEnable,
      'bg-navy-900 bg-opacity-50': isNewUserSwitchThemeEnable,
      'mt-6': theme === THEMES.BILLING,
      'min-w-92': showTripleSwitch,
      'min-w-60': !showTripleSwitch
    }
  );
  const switchBgClasses = cx('block w-full h-full radius-full', {
    'bg-blue-500': !isEoyAssetVersionV2
  });
  const switchBgPositionClasses = cx(
    'duration-300 h-8 absolute px-1 rtl:mr-0 rtl:ml-1 mr-1',
    {
      'min-w-1/2': periodList.length === 2,
      'min-w-1/3': periodList.length === 3,
      'rtl:right-0 rtl:left-auto left-0': selectedPeriod === periodList[0] && [PERIODS.MONTHLY, PERIODS.YEARLY].includes(periodList[0]),
      'rtl:right-1/2 rtl:left-auto left-1/2': periodList.length === 2 && selectedPeriod === periodList[1] && [PERIODS.YEARLY, PERIODS.BIYEARLY].includes(periodList[1]),
      'rtl:right-1/3 rtl:left-auto left-1/3': periodList.length === 3 && selectedPeriod === periodList[1] && [PERIODS.YEARLY, PERIODS.BIYEARLY].includes(periodList[1]),
      'rtl:right-2/3 rtl:left-auto left-2/3': periodList.length === 3 && selectedPeriod === periodList[2] && periodList[2] === PERIODS.BIYEARLY
    }
  );

  return (
    <div className="flex flex-col">
      <div className={switchClasses}>
        {
          periodList.map(period_ => (
            <div
              key={`jf-pricing-table-switch-${period_}-option`}
              className={cx('z-1', { 'min-w-1/2': periodList.length === 2, 'min-w-1/3': periodList.length === 3 })}
            >
              <button
                type="button"
                className={cx(
                  'line-height-3xl text-md w-full radius-full px-2 font-medium',
                  {
                    'color-white': selectedPeriod === period_ && !isEoyAssetVersionV2,
                    'color-gray-600': selectedPeriod === period_ && isEoyAssetVersionV2,
                    'color-navy-300': selectedPeriod !== period_ && !isNewUserSwitchThemeEnable && !isEoyAssetVersionV2,
                    'color-blue-300': selectedPeriod !== period_ && isNewUserSwitchThemeEnable && !isEoyAssetVersionV2,
                    'color-gray-50': selectedPeriod !== period_ && isEoyAssetVersionV2
                  }
                )}
                onClick={() => setSelectedPeriod(period_)}
              >
                {t(PERIOD_TEXTS[period_])}
              </button>
            </div>
          ))
        }
        <div className={switchBgPositionClasses}>
          <span className={switchBgClasses} style={{ backgroundColor: isEoyAssetVersionV2 ? '#F6E7AE' : null }} />
        </div>
      </div>
      <div className="relative mx-auto mb-4 pricingTableSwitchText">
        <span
          className={cx(
            'text-md line-height-md font-medium',
            {
              'color-blue-500': !isNewUserSwitchThemeEnable,
              'color-blue-300': isNewUserSwitchThemeEnable
            }
          )}
          style={{ color: isEoyAssetVersionV2 ? '#F6E7AE' : null }}
        >
          {getSaveUpText()}
        </span>
        {!showEoySwitchLongMessage && !showTripleSwitch && (
          <span className="absolute pricing--switch-arrow">
            <Arrow
              className={cx({
                'fill-blue-500': !isNewUserSwitchThemeEnable,
                'fill-blue-300': isNewUserSwitchThemeEnable
              })}
              style={{ fill: isEoyAssetVersionV2 ? '#F6E7AE' : null }}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default Switch;
