import React from 'react';
import { object } from 'prop-types';

import { t } from '@jotforminc/translation';
import { useFormatCurrency } from '@jotforminc/currency';

import {
  PERIODS,
  PLANS,
  SOURCES
} from '../constants';

import {
  ALL_FEATURES_INCLUDED,
  BILLED_ANNUALLY,
  BILLED_ANNUALLY_AFTER_2_YEARS,
  BILLED_ANNUALLY_AT_PRICE,
  BILLED_BIYEARLY,
  BILLED_BIYEARLY_AT_PRICE
} from '../constants/texts';

import { usePricingContext } from '../context/pricingTableContext';

const PlanNote = ({ plan }) => {
  const {
    state: {
      period,
      campaignStatus,
      forceDiscount,
      source,
      isNonprofitEducationUser
    },
    getColumnColors,
    checkLegacyYearlyUsersOnCampaignTime,
    getShowCampaignPrice
  } = usePricingContext();

  const {
    name: planName,
    prices,
    onSale
  } = plan;

  const { HEADER_NOTE: headerNoteColor } = getColumnColors();

  const showCampaignPrice = getShowCampaignPrice({ plan });
  const isLegacyYearlyUsersOnCampaignTime = checkLegacyYearlyUsersOnCampaignTime();

  const formatCurrency = useFormatCurrency();

  if (planName === PLANS.FREE) {
    return (
      <span style={{ color: headerNoteColor }}>{`* ${t(ALL_FEATURES_INCLUDED)}`}</span>
    );
  }

  let visiblePeriod = period;

  if (campaignStatus) {
    visiblePeriod = showCampaignPrice ? period : PERIODS.MONTHLY;
  }

  if (source === SOURCES.BUY_ONE_GET_ONE) {
    return (
      <span style={{ color: headerNoteColor }}>
        {t(BILLED_ANNUALLY_AFTER_2_YEARS)}
      </span>
    );
  }

  if (visiblePeriod === PERIODS.BIYEARLY) {
    let prices_ = prices;

    if (forceDiscount) {
      prices_ = ![SOURCES.BLACK_FRIDAY_PAGE, SOURCES.G2_DISCOUNT_PAGE, SOURCES.SUMMER_CAMP, SOURCES.SQUARE_APP_WEEK].includes(source) ? onSale.nonProfit : onSale.prices;
    }

    return showCampaignPrice && !isNonprofitEducationUser
      ? (
        <span style={{ color: headerNoteColor }}>
          {t(BILLED_BIYEARLY_AT_PRICE).replace('{price}', formatCurrency({ value: prices_[PERIODS.BIYEARLY] }))}
        </span>
      )
      : (
        <span style={{ color: headerNoteColor }}>
          {t(BILLED_BIYEARLY)}
        </span>
      );
  }

  if (visiblePeriod === PERIODS.YEARLY) {
    let prices_ = prices;

    if (forceDiscount) {
      prices_ = ![SOURCES.BLACK_FRIDAY_PAGE, SOURCES.G2_DISCOUNT_PAGE, SOURCES.SUMMER_CAMP, SOURCES.SURVEYS, SOURCES.TYPEFORM_ALTERNATIVE, SOURCES.SQUARE_APP_WEEK].includes(source)
        ? onSale.nonProfit
        : onSale.prices;
    }

    return showCampaignPrice && !isNonprofitEducationUser
      ? (
        <span style={{ color: headerNoteColor }}>
          {t(BILLED_ANNUALLY_AT_PRICE).replace('{price}', formatCurrency({ value: prices_[PERIODS.YEARLY] }))}
        </span>
      )
      : (
        <span style={{ color: headerNoteColor }}>
          {t(BILLED_ANNUALLY)}
        </span>
      );
  }

  if (isLegacyYearlyUsersOnCampaignTime) {
    return (
      <span style={{ color: headerNoteColor }}>
        {t(BILLED_ANNUALLY_AT_PRICE).replace('{price}', formatCurrency({ value: prices[PERIODS.YEARLY] }))}
      </span>
    );
  }

  return null;
};

PlanNote.propTypes = {
  plan: object.isRequired
};
export default PlanNote;
