/* eslint-disable no-nested-ternary */
import React from 'react';
import cx from 'classnames';
import { t } from '@jotforminc/translation';
import { getUrlParameter } from '@jotforminc/utils';

import { CAMPAIGN_TYPES, SOURCES, THEMES } from '../constants';
import {
  ALL_PRICES_LISTED_ABOVE_ARE_IN_USD,
  CAMPAIGN_DISCLAIMER,
  HIPAA_CAMPAIGN_DISCLAIMER,
  NONPROFIT_EDUCATION_CAMPAIGN_DISCLAIMER,
  OFFER_EXPIRES,
  SUMMER_CAMP_DISCLAIMER
} from '../constants/texts';

import { usePricingContext } from '../context/pricingTableContext';

import { translateLongDate } from '../utils';

const Disclaimers = () => {
  const {
    state: {
      user: {
        location: { country_code: countryCode },
        plan_type: planType
      },
      campaign: {
        type: campaignType,
        expires = {},
        assetsVersion,
        discountPercent = '50'
      } = {},
      campaignStatus,
      billingCountry,
      theme,
      source,
      isNonprofitEducationUser
    }
  } = usePricingContext();

  const getExpireDateStr = () => {
    // November 25,2022
    const expireDateFormat = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC'
    };
    const {
      new_users_campaign: newUserExpireTimestampStr,
      summer: summerExpireTimestamp,
      black_friday: blackFridayExpireTimestampStr,
      over_quota_campaign: overQuotaExpireTimestampStr,
      back_to_paid_campaign: backToPaidExpireTimestampStr,
      // summer_camp_campaign: summerCampExpireTimestampStr
      regular_fifty: regularFiftyExpireTimestampStr,
      save_up_to_forty: saveUpToFortyExpireTimestampStr,
      spring_sale: springSaleExpireTimestampStr
    } = expires;

    const expireTimesByCampaign = {
      [CAMPAIGN_TYPES.NEWUSER]: newUserExpireTimestampStr,
      [CAMPAIGN_TYPES.BLACKFRIDAY]: blackFridayExpireTimestampStr,
      [CAMPAIGN_TYPES.OVERQUOTAUSER]: overQuotaExpireTimestampStr,
      [CAMPAIGN_TYPES.BACKTOPAID]: backToPaidExpireTimestampStr,
      [CAMPAIGN_TYPES.SUMMER]: summerExpireTimestamp,
      // [CAMPAIGN_TYPES.SUMMERCAMP]: summerCampExpireTimestampStr,
      [CAMPAIGN_TYPES.REGULARFIFTYDISCOUNT]: assetsVersion === 'v2' || ['1', 'true'].includes(getUrlParameter('spring'))
        ? springSaleExpireTimestampStr || regularFiftyExpireTimestampStr
        : regularFiftyExpireTimestampStr,
      [CAMPAIGN_TYPES.SAVEUPTOFORTYDISCOUNT]: saveUpToFortyExpireTimestampStr,
      [CAMPAIGN_TYPES.EOY]: new Date(
        // eslint-disable-next-line no-nested-ternary
        new Date() < new Date('2024-01-01 00:00:00')
          ? '2023-12-31 23:59:59'
          : (
            new Date() < new Date('2024-01-12 00:00:00')
              ? '2024-01-12 23:59:59'
              : '2024-01-15 23:59:59'
          )
      ).getTime() / 1000
    };

    if (expireTimesByCampaign[campaignType]) {
      const expireDate = new Date(parseInt(`${expireTimesByCampaign[campaignType]}000`, 10));
      return expireDate.toLocaleDateString('en-US', expireDateFormat);
    }
  };

  const isNonProfitPage = isNonprofitEducationUser && !campaignStatus && theme === THEMES.DEFAULT;
  const isNonProfitCampaign = (campaignStatus && [CAMPAIGN_TYPES.NONPROFIT, CAMPAIGN_TYPES.EDUCATION].includes(campaignType)) || isNonprofitEducationUser;
  const isSummerCampCampaign = campaignType === CAMPAIGN_TYPES.SUMMERCAMP;
  const showCampaignDisclaimer = !isNonProfitPage && !isNonProfitCampaign && (source !== SOURCES.SQUARE_APP_WEEK) && campaignStatus && (
    ([SOURCES.SURVEYS, SOURCES.TYPEFORM_ALTERNATIVE].includes(source))
    || Object.values(CAMPAIGN_TYPES).filter(c => ![CAMPAIGN_TYPES.NONE, CAMPAIGN_TYPES.HIPAAGOLD, CAMPAIGN_TYPES.EDUCATION, CAMPAIGN_TYPES.NONPROFIT].includes(c)).includes(campaignType)
  );
  const showUsdDisclaimer = !planType && ['CA', 'AU', 'BR'].includes(billingCountry || countryCode);
  const showHipaaCampaignDisclaimer = campaignType === CAMPAIGN_TYPES.HIPAAGOLD;

  const showCampaignDisclaimerText = cx('text-left text-xxs max-w-xl mx-auto px-2', {
    'mt-3': theme !== THEMES.MINI,
    'mt-6': theme === THEMES.MINI
  });

  let campaignDisclaimer = isSummerCampCampaign ? SUMMER_CAMP_DISCLAIMER : CAMPAIGN_DISCLAIMER;
  const expireDateStr = getExpireDateStr();
  if (expireDateStr) {
    campaignDisclaimer = `*${t(campaignDisclaimer)} ${t(OFFER_EXPIRES).replace('{expireDate}', translateLongDate(expireDateStr))}`;
  } else {
    campaignDisclaimer = `*${t(campaignDisclaimer)}`;
  }

  campaignDisclaimer = campaignDisclaimer.replace('{discountPercent}', discountPercent);

  return (
    <>
      {isNonProfitCampaign && (
        <p className={showCampaignDisclaimerText} style={{ color: '#7f838d' }}>
          {NONPROFIT_EDUCATION_CAMPAIGN_DISCLAIMER}
        </p>
      )}
      {showCampaignDisclaimer && (
        <p className={showCampaignDisclaimerText} style={{ color: '#7f838d' }}>
          {campaignDisclaimer}
        </p>
      )}
      {showHipaaCampaignDisclaimer && (
        <p className={showCampaignDisclaimerText} style={{ color: '#7f838d' }}>
          *
          {t(HIPAA_CAMPAIGN_DISCLAIMER)}
        </p>
      )}
      {showUsdDisclaimer && (
        <p className="text-center color-gray-200 text-sm mt-4">
          *
          {t(ALL_PRICES_LISTED_ABOVE_ARE_IN_USD)}
        </p>
      )}
    </>
  );
};

export default Disclaimers;
