/* eslint-disable max-len */
import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';

import '../styles/campaignStyles/lockdown.scss';

export const LockdownPromotionRenderer = () => (
  <div className="jfLimitDialog-ld2022-section-wrapper">
    <div className="jfLimitDialog-ld2022-section">
      <div className="people-img left">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427 242">
          <path d="M299.483 230.659h-16.451l.203-86.72 22.239-.406-5.991 87.126Z" fill="#00428F" />
          <path d="M318.878 230.456h-16.857c2.844-27.417 4.57-57.78-8.631-68.746-5.89-4.875-7.007-11.577-8.327-17.669l39.908-1.422c5.178 40.11-3.656 57.982-6.093 87.837Z" fill="#0058BF" />
          <path d="M319.386 241.829h-36.354v-11.983h19.192c9.546 0 17.162 5.382 17.162 11.983Z" fill="#00428F" />
          <path d="M339.695 241.829h-37.369v-11.983h20.309c9.444 0 17.06 5.382 17.06 11.983Z" fill="#0058BF" />
          <path d="M324.869 29.802c3.656 1.117 4.163 5.585.812 7.312l-11.677 2.843c-1.93 1.015-4.265.304-5.281-1.625l-3.96-7.717c-1.523-2.945 1.117-6.5 4.366-5.687l15.74 4.874Z" fill="#B47735" />
          <path d="m367.112 27.162-38.791 40.415c-4.264 3.859-7.412 7.718-11.169 3.351-2.844-3.35-2.539-8.428.71-11.373l45.29-40.212 3.96 7.82Z" fill="#AF6827" />
          <path d="m331.876 68.39-11.069 10.052c-3.757 3.453-9.545 3.148-12.997-.609l-.61-.61c-3.452-3.756-3.148-9.544.61-12.997l11.068-10.053 12.998 14.216Z" fill="#26962D" />
          <path d="M313.801 74.584h-18.482c2.336-8.124 1.828-17.974 0-28.433h18.482c-1.422 11.17-2.641 22.035 0 28.433Z" fill="#BC7835" />
          <path d="M299.686 32.544c-5.179 10.155 1.117 7.616 5.077 18.075 6.093 16.044 13.607 13.912 18.786 3.757 5.179-10.154 1.32-22.441-8.631-27.518-9.952-5.078-22.239-1.016-27.418 9.037" fill="#CE8A41" />
          <path
            d="M317.558 44.628s2.031 4.874 7.209 4.57" stroke="#823C19" strokeWidth="1.38"
            strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"
          />
          <path d="M319.995 33.763a1.218 1.218 0 1 0 0-2.437 1.218 1.218 0 0 0 0 2.437Z" fill="#333" />
          <path d="M364.066 25.944c-2.945-3.25-2.742-8.226.507-11.17 3.25-2.945 8.124-2.742 11.069.507 2.944 3.25 2.741 8.225-.508 11.17-3.148 2.945-8.124 2.742-11.068-.507Z" fill="#AF6827" />
          <path d="M368.635 22.085c.812.812 2.132.914 2.945.101l10.053-9.342c.812-.812.914-2.132.101-2.945-.812-.812-2.132-.913-2.944-.101l-10.053 9.342c-.813.71-.914 2.133-.102 2.945Z" fill="#AF6827" />
          <path d="M324.564 89.51c-.812-8.732-9.037-18.988-12.084-18.988l-14.724-2.843c-8.732 0-15.739 20.309-15.739 29.346l1.422 47.219 41.532.812c.304-.203 1.523-49.148-.407-55.545Z" fill="#39B54A" />
          <path d="m284.048 111.749 11.881-29.752c1.421-3.555 5.381-5.28 8.936-3.86 3.554 1.422 5.28 5.383 3.858 8.937l-11.88 29.854c-1.422 3.554-5.382 5.28-8.936 3.859-3.555-1.422-5.281-5.484-3.859-9.038Z" fill="#CE8A41" />
          <path d="m296.843 112.359 18.582 31.479c.914 1.624.508 3.655-.914 4.772-1.523 1.219-3.858 1.016-5.077-.507l-23.355-28.129c-1.422-1.624-1.016-4.163.711-5.381l4.772-3.351c1.726-1.219 4.164-.711 5.281 1.117Z" fill="#CE8A41" />
          <path d="m318.065 150.438-10.459 3.757-.609-9.85 8.53-.812 2.538 6.905Z" fill="#CE8A41" />
          <path d="m289.227 91.542 5.991-14.927c1.828-4.671 7.209-7.007 11.881-5.078 4.671 1.828 7.006 7.21 5.077 11.881l-5.991 14.927-16.958-6.803Z" fill="#4BC657" />
          <path d="M315.73 14.977c-2.031-1.828-5.077-1.828-7.21-.305l-18.176 13.2-.203.204c-.407.203-.711.508-1.016.812-13.607 14.521 6.296 29.753 6.296 29.753 6.194.203 10.154-5.179 11.576-12.693l5.89-2.336-2.64-15.536 6.498-4.773c1.117-1.624 1.727-5.89-1.015-8.326Z" fill="#823C19" />
          <path d="M306.834 48.525c2.256-1.253 2.892-4.414 1.421-7.062-1.47-2.647-4.491-3.778-6.746-2.525-2.255 1.252-2.891 4.414-1.421 7.062 1.471 2.647 4.491 3.778 6.746 2.525Z" fill="#CE8A41" />
          <path d="M315.121 31.02c-.61-.405-.711-1.218-.305-1.827l2.234-3.046c.406-.61 1.219-.711 1.828-.305.609.406.711 1.219.305 1.828l-2.234 3.046c-.407.61-1.219.711-1.828.305Z" fill="#823C19" />
          <path d="M366.299 233.401h-9.646l-3.758-66.106h17.162l-3.758 66.106Z" fill="#E29E66" />
          <path d="M381.937 233.401h-9.748l-3.656-66.106h17.06l-3.656 66.106Z" fill="#FFB069" />
          <path d="M392.092 181.917H348.63l4.265-45.188h33.51l5.687 45.188Z" fill="#838BC5" />
          <path d="M384.476 241.829h-28.839v-8.53h14.927c7.616 0 13.912 3.757 13.912 8.53Z" fill="#B5B5B5" />
          <path d="M398.286 241.829h-28.839v-8.327h15.232c7.616-.101 13.607 3.656 13.607 8.327Z" fill="#9CA2D1" />
          <path d="m380.719 37.317 7.311 7.413-22.949 23.456-7.413-7.514 23.051-23.355Z" fill="#823C19" />
          <path d="m357.16 46.76 23.457-6.498v25.792l-23.457 6.6V46.76Z" fill="#000" />
          <path d="M375.54 64.53h-10.662v17.467h10.662V64.53Z" fill="#FCD486" />
          <path d="m386.405 41.582-.203 1.827-9.951 1.524c-2.437.609-3.047 3.858-1.016 5.28l5.281 3.757c1.32.914 3.148.61 4.062-.71l6.803-5.89c1.219-1.828-3.046-2.437-4.976-5.788Z" fill="#D6A651" />
          <path d="M350.052 50.822c0-11.373 8.835-20.614 19.7-20.614 10.865 0 19.7 9.241 19.7 20.614 0 11.373-8.835 20.614-19.7 20.614-10.865 0-19.7-9.24-19.7-20.614Z" fill="#FFB069" />
          <path d="m410.878 56.102-29.753 35.541c-4.062 4.062-7.108 8.022-10.967 3.86-3.046-3.25-2.945-8.328.203-11.374l36.252-35.642 4.265 7.615Z" fill="#E29E66" />
          <path d="M352.895 136.729c-.507-18.176-5.483-25.69-6.498-34.83-.204-1.421 0-4.366 0-5.788 0-8.936 5.28-14.927 14.013-14.927h18.379c8.733 0 15.943 11.78 15.943 20.817v.305c0 3.249-.812 6.499-2.234 9.443-5.382 11.373-5.28 24.98-6.093 24.98h-33.51Z" fill="#FF6100" />
          <path d="M408.745 56.001c-3.046-3.148-3.046-8.124 0-11.17a7.667 7.667 0 0 1 11.069 0c3.046 3.148 3.046 8.124 0 11.17-3.047 3.046-8.022 3.046-11.069 0Z" fill="#E29E66" />
          <path d="M413.112 51.94a2.084 2.084 0 0 0 2.945 0l9.646-9.75a2.083 2.083 0 0 0-2.944-2.944l-9.647 9.748c-.813.711-.813 2.031 0 2.945Z" fill="#E29E66" />
          <path d="M383.054 35.895s-.101-.203-.203-.203c-7.92-7.413-20.512-7.413-28.026.508-3.25 3.452-5.281 9.545-5.484 18.684v27.722c0 8.225 6.702 14.927 14.928 14.927 8.225 0 14.927-6.702 14.927-14.927V46.05c3.96-2.336 4.366-5.687 3.96-9.343-.102 0 0-.71-.102-.812Z" fill="#FFC102" />
          <path d="M382.851 35.692c-4.163-3.859-9.443-5.687-14.724-5.382 5.687-.406 12.998 4.163 12.896 6.397.305 2.742.204 5.28-1.523 7.413-1.624 1.93-2.335 4.468-2.335 7.007v31.58c0 7.92-6.093 14.318-13.912 14.826h1.015c8.226 0 14.928-6.702 14.928-14.927v-31.58c0-2.54.71-5.078 2.335-7.007 1.726-2.133 1.93-4.672 1.625-7.413-.102.101-.305-1.016-.305-.914Z" fill="#FFDA67" />
          <path d="M390.162 127.793h-.101c-3.554 1.016-7.21-1.015-8.225-4.467l-7.21-24.777c-1.015-3.555 1.015-7.21 4.468-8.226h.102c3.554-1.015 7.209 1.016 8.225 4.468l7.209 24.777c1.117 3.554-.913 7.21-4.468 8.225Z" fill="#FFB069" />
          <path d="M402.856 162.319h-25.59a3.164 3.164 0 0 1-3.148-3.148v-16.857a3.163 3.163 0 0 1 3.148-3.147h25.59a3.163 3.163 0 0 1 3.148 3.147v16.857a3.164 3.164 0 0 1-3.148 3.148Z" fill="#FFC102" />
          <path d="M401.129 139.776h3.555l-7.413-23.254-3.351 1.32 7.209 21.934Z" fill="#FFA403" />
          <path d="m410.675 112.866-17.466 12.389-7.007-9.647 18.583-9.85c2.64-1.929 5.179-3.859 7.21-1.218 1.929 2.64 1.32 6.397-1.32 8.326Z" fill="#FFB069" />
          <path d="M411.893 111.038a5.89 5.89 0 1 0 .002-11.778 5.89 5.89 0 0 0-.002 11.778Z" fill="#FFB069" />
          <path d="m378.789 140.994-3.351-1.32 8.226-25.081 3.452 1.32-8.327 25.081Z" fill="#FFC102" />
          <path d="M377.368 162.319c-1.828 0-3.25-1.422-3.25-3.25v-16.653c0-1.828 1.422-3.249 3.25-3.249s3.249 1.421 3.249 3.249v16.653c0 1.828-1.523 3.25-3.249 3.25Z" fill="#FFA403" />
          <path d="m385.288 116.928 8.835-5.889c.609-.407.812-1.321.406-1.93s-1.32-.812-1.929-.406l-8.835 5.89 1.523 2.335Z" fill="#FFC102" />
          <path d="M369.447 227.714h-13.911v14.115h13.911v-14.115Z" fill="#838BC5" />
          <path d="M384.07 227.714h-13.912v14.115h13.912v-14.115Z" fill="#9CA2D1" />
          <path d="m101.469 174.91-5.585 58.795-10.459.813-1.726-93.625 13.607-1.726 4.163 35.743Z" fill="#E29E66" />
          <path d="M99.845 233.401h-1.422v-.102H84.511v8.428H113.452c-.102-4.569-6.194-8.326-13.607-8.326Z" fill="#EA5706" />
          <path d="M124.012 233.401h-1.421v-.102h-13.912v8.428H137.62c0-4.569-6.093-8.326-13.608-8.326Z" fill="#FF6100" />
          <path d="m85.02 224.465 12.185-.305-.813 10.561c-3.96.913-7.819 1.015-11.373.101" fill="#838BC5" />
          <path d="M104.211 179.48c-8.428.711-16.146.508-23.05-.914l.1-37.673 19.701-1.726 3.249 40.313Z" fill="#EA5706" />
          <path d="M98.118 153.586c-9.646 0-17.364-7.819-17.364-17.364l34.729-3.859v3.859c0 9.545-7.819 17.364-17.365 17.364Z" fill="#FF6100" />
          <path d="m124.52 199.992-3.452 34.018h-10.967l-1.523-31.784c-.61-12.185-3.758-24.168-9.444-35.033L90.4 144.955l20.309-7.312 8.936 24.473c4.57 12.185 6.093 25.081 4.874 37.876Z" fill="#FFB069" />
          <path d="m109.492 224.465 12.591-.305-.914 10.561c-4.163.913-8.022 1.015-11.779.101" fill="#9CA2D1" />
          <path d="M126.145 174.606c-7.007 4.671-14.927 6.194-23.66 5.28l-14.724-39.298 28.229-6.905 10.155 40.923Z" fill="#FF6100" />
          <path d="m87.158 45.972 15.435-1.726-3.148 28.23-13.81.202 1.523-26.706ZM100.588 24.598c-3.305-.109-5.17 3.701-3.16 6.294l5.307 6.717a3.807 3.807 0 0 0 5.343.581l7.708-5.168c2.802-2.388 4.541-6.267.831-6.682-4.867 1.079-10.294.205-16.029-1.742Z" fill="#E29E66" />
          <path d="M119.234 50.203c3.058-10.701-3.715-19.956-13.568-24.843-19.23-9.592-21.575 3.229-24.633 13.93s7.532-.258 10.918 11.553c2.492 8.687 24.224 10.061 27.283-.64Z" fill="#FFB069" />
          <path
            d="M118.653 40.44s-4.056 3.17-9.512.72" stroke="#E29E66" strokeWidth="1.38"
            strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"
          />
          <path d="M78.114 50.213s-22.848-3.453-20.817 13.1c.203 1.827 1.625 3.35 3.351 3.858 2.539.71 5.179-.914 5.687-3.554.609-2.945 2.234-6.398 6.6-7.92 8.327-2.64 5.179-5.484 5.179-5.484Z" fill="#823C19" />
          <path d="M81.16 52.752c-.812.406-1.827.101-2.234-.711l-1.421-2.742c-.406-.812-.102-1.828.71-2.234.813-.406 1.828-.102 2.235.71l1.421 2.742c.406.813.102 1.828-.71 2.234Z" fill="#FF6100" />
          <path d="M102.282 27.974c-.609-.406-.711-1.218-.305-1.827l2.234-3.047c.406-.609 1.219-.71 1.828-.304.609.406.711 1.218.305 1.827l-2.234 3.047c-.407.507-1.219.71-1.828.304ZM107.054 28.888a1.32 1.32 0 1 0 0-2.64 1.32 1.32 0 0 0 0 2.64ZM95.174 38.738c.304 1.422 1.827 2.234 3.25 1.625 1.218-.61 1.725-2.133 1.014-3.25-.304-.507-.609-.913-.812-1.523-1.32-4.67-3.96-7.717-8.022-7.616-2.335.102-2.234-2.538-.914-4.975l.102-.203c.507-.914-15.638 1.015-12.186 17.77 0 0 .305 4.062 2.945 9.748 1.726 3.86 10.053 7.312 11.069 3.047 0 0 .406-2.742-.711-5.89-1.117-3.25-2.945-4.366-3.148-5.788-.305-1.625-.305-2.64.61-3.859 2.132-2.741 5.483-2.03 6.803.914Z" fill="#823C19" />
          <path d="M94.97 65.14c-7.31-1.625-12.083-1.422-16.551 9.24-3.96 20.919-2.539 38.791.101 55.952-.61 12.896 27.925 7.108 27.925 7.108 12.287-1.929 12.795-8.428 11.779-19.192C113.249 76.919 104.82 65.14 94.97 65.14Z" fill="#616898" />
          <path d="m99.642 95.502 14.521-2.234-1.625-4.874-13.607 2.132a2.506 2.506 0 0 0-2.133 2.844 2.506 2.506 0 0 0 2.844 2.132Z" fill="#494E72" />
          <path d="m159.452 34.676 5.483 2.945-16.45 26.402c-2.945 4.671-7.21 8.327-12.389 10.46l-19.395 7.92c-2.843 2.132-6.194 3.757-9.748 4.265-5.484.812-9.444-.813-9.444-.813-1.117-1.624-3.859-4.772-2.742-7.819 1.93-5.483 33.104-9.342 44.376-20.512 11.677-11.678 20.309-22.848 20.309-22.848Z" fill="#FFB069" />
          <path d="M203.827 38.84c3.351 1.828 3.046 6.194-.508 7.413l-12.083.507c-2.133.61-4.265-.507-4.875-2.64l-2.437-8.327c-.914-3.249 2.336-6.092 5.382-4.772l14.521 7.819Z" fill="#E29E66" />
          <path d="M195.5 30.716c-6.803-8.936-15.841-7.717-22.847 1.219l-1.523 2.132c-7.007 8.936-2.742 15.74 6.092 22.645 8.835 6.905 14.217 12.896 21.223 3.96 7.007-8.936 2.945-22.035-2.945-29.956Z" fill="#FFB069" />
          <path d="M197.094 37.875c.598-.153.914-.937.707-1.752-.208-.815-.861-1.353-1.459-1.2-.597.152-.914.936-.706 1.751.207.816.86 1.353 1.458 1.2Z" fill="#333" />
          <path
            d="M193.977 50.416s2.742 4.265 7.819 3.453" stroke="#E29E66" strokeWidth="1.38"
            strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"
          />
          <path d="m175.902 37.621-7.92-2.945-1.016 47.93 20.005-1.422c-5.281-16.247-12.186-33.814-11.069-43.563Z" fill="#E29E66" />
          <path d="M178.847 230.963h-17.263v-86.415h22.442l-5.179 86.415Z" fill="#00428F" />
          <path d="m198.141 230.963-15.334-.203c12.795-28.534 4.874-61.028-14.013-84.587l35.541-2.742c11.576 26.199 9.647 63.162-6.194 87.532Z" fill="#0058BF" />
          <path d="M155.187 92.659c0-9.24 2.132-20.106 12.287-32.393l14.724 8.123c10.764 7.007 18.38 22.34 18.38 31.581l5.077 45.797-46.203.508-4.265-53.616Z" fill="#09F" />
          <path d="M199.054 241.829h-37.368v-10.967h20.309c9.342 0 17.059 4.874 17.059 10.967Z" fill="#00428F" />
          <path d="M220.379 241.829H183.01c-1.827-2.234-1.827-5.991 0-11.17h20.309c9.444 0 17.06 4.976 17.06 11.17Z" fill="#0058BF" />
          <path d="M202.71 33.153c-.101-.812-.508-1.421-1.117-1.93-.609-.507-1.422-.608-2.132-.507l-20.106 3.351.609 2.843.711-1.218 19.09-2.843h.406c.102 0 .102.101.204.101.203.102.304.305.304.61.102.812-.101 2.944-.914 3.96.406.609 1.117.507 1.523 1.117 1.219-1.117 1.828-3.047 1.422-5.484Z" fill="#333" />
          <path d="M178.847 20.359c-20.817.507-15.943 16.856-15.638 19.8 0 0 2.437 7.921 10.358 8.124l2.64-.304c1.015-.102.304-2.336.203-3.351l-.203-1.726c-.305-2.234.914-4.468 2.945-5.484 3.452-1.726 7.006.61 7.412 3.96l.305 2.336c.305 1.625.812 1.218 1.828 1.015l1.625-.203c1.015-.101 1.726-1.015 1.624-2.03l-1.32-10.866c-.203-1.523.914-2.843 2.336-3.046h.101c-.304-5.991-2.031-10.662-14.216-8.225Z" fill="#823C19" />
          <path d="M180.675 143.635c-15.841-17.263-25.59-37.775-18.38-56.155-1.015-6.499 3.554-12.49 10.053-13.302h.102c6.397-.711 10.865 6.397 7.819 11.982-9.749 17.77-5.585 35.642 7.717 53.616l-7.311 3.859Z" fill="#FFB069" />
          <path d="M170.825 71.639c-5.991-.203-11.068 4.57-11.272 10.56l-.609 20.411 21.832.61.61-20.411c.203-5.89-4.57-10.967-10.561-11.17Z" fill="#33ADFF" />
          <path d="M259.778 96.72c-1.929 1.93-5.178 1.93-7.108 0L228.909 72.96c-1.93-1.93-1.93-5.179 0-7.108 1.929-1.93 5.178-1.93 7.108 0l23.761 23.761a4.908 4.908 0 0 1 0 7.109Z" fill="#E29E66" />
          <path d="m235.103 75.802 12.998.508c-1.625-5.686-1.117-11.068 2.335-16.044.203-4.062-2.945-7.413-7.006-7.616-4.062-.203-7.413 2.945-7.616 7.007l-.711 16.145ZM253.889 40.058c-1.422-.71-.406 1.117-1.117 2.539-.711 1.422-.102 3.148 1.32 3.859l1.117 2.741c1.422.711 3.148.102 3.859-1.32l.914-.304c2.03-.61 2.437-3.047.406-3.453-2.539-.304-6.499-4.062-6.499-4.062Z" fill="#E29E66" />
          <path d="M233.58 25.334c-11.779 2.844.406 18.99.406 18.99 1.32 12.59 14.013 21.12 20.512 17.364 3.453-1.93 5.788-11.78 3.148-22.747-2.843-11.88-12.287-16.348-24.066-13.607Z" fill="#FFB069" />
          <path d="m247.796 85.144-2.539-11.576c13.1-2.64 20.005-7.21 24.676-14.114 8.428-12.592 2.031-29.753 2.031-29.855l5.585-1.625c.508.813 9.951 18.888 2.335 35.846-4.772 10.865-15.638 17.973-32.088 21.324Z" fill="#E29E66" />
          <path d="M275.924 31.02c4.375 0 7.921-3.409 7.921-7.615s-3.546-7.616-7.921-7.616c-4.374 0-7.92 3.41-7.92 7.616 0 4.206 3.546 7.616 7.92 7.616Z" fill="#E29E66" />
          <path d="m278.869 28.076-6.804-2.03a2.203 2.203 0 0 1-1.624-1.626l-5.179-19.8c-.508-1.727 1.218-3.352 2.945-2.844l6.803 2.03a2.203 2.203 0 0 1 1.625 1.625l5.179 19.802c.406 1.828-1.219 3.35-2.945 2.843Z" fill="#616898" />
          <path d="m276.026 23.303-3.757 2.742c-.914.711-2.235.508-2.945-.406-.711-.914-.508-2.234.406-2.945l3.757-2.742c.914-.71 2.234-.507 2.945.407.711.913.508 2.234-.406 2.944ZM253.178 137.339l-28.737-1.523c4.163-6.499 2.437-13.506-.305-22.544h29.042c-2.335 8.53-2.234 16.451 0 24.067Z" fill="#E29E66" />
          <path d="M261.911 88.496c-1.523-7.109-14.623-17.365-14.623-17.365l-13.81-3.25c-3.452 0-13.099 10.866-12.388 21.935.711 10.154 2.234 23.964 3.249 23.964l30.058 1.32c1.015 0-4.367.305 3.452-13.201.305-1.624 5.484-6.701 4.062-13.403Z" fill="#616898" />
          <path d="M238.962 233.401h-9.647l-4.163-76.058c-3.656-10.764-3.555-16.45.203-23.558l18.684 3.858-5.077 95.758Z" fill="#FFA403" />
          <path d="M254.194 133.886c-7.718 2.234-16.146 1.422-28.839.102l-.813 5.179a16.443 16.443 0 0 0 1.219 9.342c.609 1.421 1.523 2.741 2.538 3.96 7.515 8.733 11.881 19.598 12.694 31.073l3.858 49.859h9.647c8.124-52.195.609-99.515-.304-99.515Z" fill="#FFC102" />
          <path d="M254.904 140.385c4.875-.812 6.601 3.25 7.515 8.124.914 4.874.812 8.834-4.164 9.647-3.554.609-8.225-16.857-3.351-17.771Z" fill="#FFB069" />
          <path d="M254.092 145.158c-16.146-17.06-27.722-32.393-23.457-54.733 1.117-8.733 2.031-14.42 8.53-12.693 3.148.406 5.89 2.64 6.6 5.686.407 1.625.305 3.554-.711 5.585-9.443 17.974.711 35.033 14.217 52.804l-5.179 3.351Z" fill="#FFB069" />
          <path d="M245.054 38.941c-.507-.913.102-2.03 1.117-2.234l12.287-2.538c-6.702-13.607-17.567-13.201-26.604-9.038-19.396 8.936-4.57 58.693-4.57 58.693 2.336 5.585 8.428 8.429 14.216 6.702 5.687-1.828 9.139-7.615 7.921-13.505-6.397-13.912-7.514-25.082-4.265-32.9.609-1.626.711-3.555-.102-5.18Z" fill="#494E72" />
          <path d="M257.037 241.829c0-4.773-6.195-8.53-13.912-8.53h-1.929v-.102h-13.201v8.632H257.037Z" fill="#C4CDE5" />
          <path d="M257.951 233.401h-1.422v-.102h-13.912v8.428H271.558c0-4.569-6.093-8.326-13.607-8.326Z" fill="#9CA2D1" />
          <path d="m116.701 87.48-5.179-19.801-19.496 5.077c-5.484 1.421-8.733 7.007-7.312 12.49 1.422 5.483 7.007 8.733 12.49 7.311l19.497-5.077Z" fill="#616898" />
          <path d="m101.063 153.891-3.25-10.866 14.116-3.351 3.249 10.866c.812 9.849-11.373 10.966-14.115 3.351Z" fill="#EA5706" />
          <path d="m164.123 35.286-6.804-2.031a2.204 2.204 0 0 1-1.624-1.625l-5.179-19.801c-.508-1.727 1.218-3.351 2.945-2.844l6.803 2.031a2.205 2.205 0 0 1 1.625 1.625l5.179 19.801c.507 1.727-1.117 3.351-2.945 2.844Z" fill="#616898" />
          <path d="M168.895 34.067c1.016 4.57-3.452 8.428-7.717 8.428s-7.717-3.757-7.717-8.428c0-2.945 1.218-8.733 3.046-8.327 3.554.711-.406 7.21 4.062 6.702 4.265-.609 3.96-17.262 8.326 1.625Z" fill="#FFB069" />
          <path d="m257.544 39.754-3.249 1.32c-.406.203-.914 0-1.015-.406-.204-.407 0-.914.406-1.016l3.249-1.32c.406-.203.914 0 1.016.406.203.406 0 .914-.407 1.016Z" fill="#BC7835" />
          <path d="M185.752 145.767h5.585c0-.508 0-1.117-.102-1.625-.812-4.874-5.483-8.326-10.459-7.514-4.975.812-8.225 5.382-7.413 10.256.813 4.874 5.484 8.327 10.46 7.514.71-.101 1.32-.304 2.031-.507v-8.124h-.102Z" fill="#FFB069" />
          <path d="M258.154 54.072c-1.625.406-4.468-.711-5.484-2.133l1.219-.812c.711 1.117 3.757 1.624 4.468 1.218 0 0 0 1.625-.203 1.727Z" fill="#BC7835" />
          <path d="M256.021 44.831a1.32 1.32 0 1 0 0-2.64 1.32 1.32 0 0 0 0 2.64Z" fill="#333" />
          <path d="M244.648 47.674c.508 2.234.305 4.773-1.828 5.28-2.132.508-4.265-.913-4.874-3.147-.508-2.234.813-4.468 2.945-4.976 2.234-.508 3.249.61 3.757 2.843Z" fill="#FFB069" />
          <path d="M242.204 50.954c.983-.233 1.559-1.35 1.288-2.496-.271-1.146-1.287-1.887-2.269-1.655-.983.233-1.559 1.35-1.288 2.496.271 1.146 1.287 1.887 2.269 1.655Z" fill="#E29E66" />
          <path d="M243.125 49.807a1.016 1.016 0 1 0 0-2.032 1.016 1.016 0 0 0 0 2.032Z" fill="#FFB069" />
          <path d="M16.984 232.791H6.829L4.8 150.336c-3.352-7.615-3.86-16.145-.61-25.995l23.66 15.232-10.865 93.218Z" fill="#494E72" />
          <path d="m6.22 125.052 32.698-.508c7.006 10.967-1.219 109.567-3.758 107.333h-9.646l-3.96-78.19c-8.53-.507-20.208-14.724-15.334-28.635Z" fill="#616898" />
          <path d="M37.394 240.712H7.032v-9.038h15.74c8.123.102 14.622 4.062 14.622 9.038Z" fill="#494E72" />
          <path d="M55.57 240.712H25.21v-8.835h16.044c7.92.102 14.318 3.961 14.318 8.835Z" fill="#616898" />
          <path d="M28.052 52.752H13.125V73.67h14.927c-1.828-6.804-2.03-13.81 0-20.919ZM42.675 39.957s-7.718-5.89-9.952-10.663c-2.03-1.624-5.178-.101-5.077 2.54l.203 6.904c.102 1.727 1.523 3.047 3.148 2.945l9.24 1.93c3.047-.102 4.672-1.625 2.438-3.656Z" fill="#8E4B13" />
          <path d="M1.447 38.027c0-11.373 7.413-20.512 18.38-20.512 10.967 0 19.7 9.24 19.7 20.512 0 5.687.304 12.186-3.757 15.943-4.976 4.773-8.632 1.625-14.725-.812C10.994 49.096.128 49.298.128 37.926" fill="#9E5A1D" />
          <path d="M35.77 38.738a1.32 1.32 0 1 0 0-2.64 1.32 1.32 0 0 0 0 2.64Z" fill="#1E202F" />
          <path d="m82.988 77.325-1.726-1.117c-.812-2.437-3.757-.609-6.296.204l-4.67 1.523c0-.711 4.975-3.25 3.655-4.976-1.016-1.219-2.64.102-4.367 1.625-3.25 1.117-2.742 1.828-4.265 4.67-1.828 3.453-5.077 5.89-8.936 6.297l-19.801 3.96-11.17-19.7c-2.132-3.25-6.499-4.062-9.647-1.93l-.101.102c-3.25 2.133-4.062 6.5-1.93 9.647l15.029 22.645a6.94 6.94 0 0 0 9.14 2.234c2.74-1.219 16.957-7.413 24.776-12.186l14.013-5.28c2.336-.711 7.82-4.062 6.296-7.718Z" fill="#8E4B13" />
          <path d="m27.037 68.999-5.484-2.742c-5.585-2.843-12.388-.812-15.536 4.773-4.265 7.615-3.757 35.845-2.64 56.662h36.048c-1.828-19.192-3.554-27.214 1.422-33.713 5.686-7.515-6.804-17.364-13.81-24.98Z" fill="#662D91" />
          <path d="M87.66 87.277c.1-2.64-5.89-1.93-8.429-2.031l-4.874-.305c.304-.71 5.788-1.32 5.179-3.35-.508-1.524-2.539-.915-4.671 0-3.453-.102-3.25.71-5.585 2.843-1.016.913-2.031 1.624-3.25 2.132 0 0 0-.102-.102-.102-1.117 1.118-25.081 11.475-25.081 11.475L25.615 81.184c-2.742-2.64-7.21-2.64-9.85.102h-.101c-2.64 2.741-2.64 7.21.101 9.85l19.497 18.887c2.64 2.539 6.702 2.64 9.444.203 2.437-1.726 13.505-9.951 20.105-16.349l.61-.101 14.622.101c2.64.102 7.718-3.25 7.92-5.788l-.304-.812Z" fill="#9E5A1D" />
          <path d="m18.405 96.416 12.998-14.318-9.545-8.631c-3.96-3.554-10.053-3.351-13.709.609-3.554 3.96-3.35 10.053.61 13.709l9.646 8.63Z" fill="#662D91" />
          <path d="M.432 60.063c6.194 2.843 13.302 2.538 21.121 0l-.406-16.755 4.265-1.32-.102-4.671c-.101-2.336 1.625-4.265 3.86-4.57 2.436-.305 5.381-1.117 7.412-3.757 3.35-4.367-2.133-8.631-7.21-10.764-7.717-3.148-17.16-3.046-23.355 3.351C.737 26.96-.177 31.021.026 41.378l.406 18.685Z" fill="#494E72" />
          <path d="M37.496 48.08c-.102 0-.102 0 0 0-4.163-.203-5.788-3.858-5.89-4.061-.101-.305 0-.711.305-.813.305-.101.61 0 .71.305.102.101 1.422 3.148 4.875 3.35.304 0 .508.306.508.61 0 .305-.305.61-.508.61ZM32.215 34.778c-.203-.61.204-1.117.711-1.32l4.164-1.219c.609-.203 1.117.203 1.32.711.203.61-.203 1.117-.711 1.32l-4.163 1.219c-.61.101-1.219-.203-1.32-.711Z" fill="#70360B" />
          <path d="M22.163 48.08a4.366 4.366 0 1 0 0-8.733 4.366 4.366 0 0 0 0 8.733Z" fill="#9E5A1D" />
          <path d="M22.163 45.948a2.234 2.234 0 1 0 0-4.468 2.234 2.234 0 0 0 0 4.468Z" fill="#8E4B13" />
          <path
            d="M166.107 11.1812L173.4 3.88834M167.258 13.1974L180.416 4.73813M168.424 15.2559L179.475 11.5721" stroke="white" strokeLinecap="round"
            className="lockdown--flash-left"
          />
          <path
            d="M280.762 6.46809L288.218 0.988403M281.53 8.43541L294.391 2.69656M282.307 10.447L292.62 8.67559" stroke="white" strokeLinecap="round"
            className="lockdown--flash-right"
          />
        </svg>
      </div>
      <div className="image-text">
        <p className="text-yellow">
          {t('UPGRADED PLANS')}
        </p>
        <p className="text-white">
          {translationRenderer('GET [1[50% OFF]]')({
            renderer1: str => (<span>{str}</span>)
          })}
        </p>
        <span className="current">{t('Current Prices')}</span>
      </div>
      <div className="people-img right">
        <svg
          width="449" height="241" viewBox="0 0 449 241"
          fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M377 6.214L370.435 1M376.324 8.08594L365 2.62534M375.639 10L366.56 8.31447" stroke="white" strokeLinecap="round"
            className="lockdown--flash-left-2"
          />
          <path
            d="M100.744 35.3745L95.3266 28.9764M99.7146 37.0779L89.6787 29.5066M98.6688 38.821L90.0945 35.3909" stroke="white" strokeLinecap="round"
            className="lockdown--flash-right-2"
          />
          <path d="M165 133.2L141.4 133L151.9 226.8C152.2 229.6 150.1 232 147.3 232C139.7 232 133.6 235.8 133.6 240.4H162V232.2L165 133.2Z" fill="#494E72" />
          <path d="M274 231.2L281.3 232.3L286.4 166.6L270.4 166L274 231.2Z" fill="#E29E66" />
          <path d="M281.5 229.5C281.5 229.5 254.8 234.4 254.8 240.5H271.1H281.5C282.8 238.3 282.8 234.7 281.5 229.5Z" fill="#616898" />
          <path d="M267.2 232.3H274L278.6 166.6L262.6 166L267.2 232.3Z" fill="#FFB069" />
          <path d="M261.3 181.6H287.6L289.2 137L257.3 137.5L261.3 181.6Z" fill="#0F76DB" />
          <path d="M163.7 130.4L133.4 129.9C127.3 139 133.6 214.1 135.1 229.5C135.4 232.4 130.3 232.8 130.3 232.8C123.9 232.8 121.4 235.9 121.4 240.5H145.9V232.3H145.7L149.6 159.1C157.9 158.6 168.6 143.4 163.7 130.4Z" fill="#616898" />
          <path d="M141.4 66.8L153.9 61.6L156.3 82.1L143.7 83.8C143.9 77.8 143.3 72.1 141.4 66.8Z" fill="#703116" />
          <path d="M145.9 53.2L137.4 49.5L136.4 50.8C133.3 53.9 129.5 54.8 131.3 57L138.5 62.2C139.5 63.4 141.3 63.6 142.5 62.6L147.4 58.5C149.1 56.7 148.3 53.6 145.9 53.2Z" fill="#703116" />
          <path d="M152.4 40.7C143.2 38.9 136 43.3 134.1 52.9C132.3 62.5 130.1 72.4 143.4 71.1C148.1 70.6 149.8 66.6 151.7 57L162 58.5C163.9 48.8 161.6 42.5 152.4 40.7Z" fill="#823C19" />
          <path d="M132.8 62.7C134.4 63 136.9 62.1 138.3 60.8C138.7 60.4 138.7 59.7 138.2 59.4C137.9 59.2 137.5 59.2 137.2 59.5C136 60.5 133.7 61 132.9 60.7C132.9 60.6 132.6 62.6 132.8 62.7Z" fill="#703116" />
          <path d="M136.5 55.1C137.163 55.1 137.7 54.5627 137.7 53.9C137.7 53.2372 137.163 52.7 136.5 52.7C135.837 52.7 135.3 53.2372 135.3 53.9C135.3 54.5627 135.837 55.1 136.5 55.1Z" fill="#141520" />
          <path d="M148.9 95.2L123.5 91.5C117.6 90.6 112.7 86.6 110.8 80.9L107.8 63.6C106.5 63.1 102.9 64 101.9 62.6C100.6 60.9 99.4 57.9 100.7 53.5L110.5 56.4C110.3 56.1 110 55.8 109.8 55.8L112.1 56.8L112.8 61.1L121 77.6C121.6 79.5 123.2 80.8 125.2 81.1L150.6 84.8L148.9 95.2Z" fill="#703116" />
          <path d="M107.3 60.9C109.896 60.9 112 58.0794 112 54.6C112 51.1206 109.896 48.3 107.3 48.3C104.704 48.3 102.6 51.1206 102.6 54.6C102.6 58.0794 104.704 60.9 107.3 60.9Z" fill="#823C19" />
          <path d="M109.7 59.4L102.6 64C101.5 64.7 100.1 63.9 100.1 62.6V42.3C100.1 41.7 100.4 41.2 100.8 40.9L107.9 36.3C109 35.6 110.4 36.4 110.4 37.7V58C110.4 58.6 110.1 59.1 109.7 59.4Z" fill="#6D7191" />
          <path d="M111.7 54.3H108.8C107.9 54.3 107.1 53.5 107.1 52.6C107.1 51.7 107.9 50.9 108.8 50.9H111.7C112.6 50.9 113.4 51.7 113.4 52.6C113.4 53.6 112.6 54.3 111.7 54.3Z" fill="#823C19" />
          <path d="M105.2 58.1L105.1 57.9C105 57.6 104.7 57.4 104.4 57.2C104.7 56.8 104.8 56.2 104.6 55.6C104.4 55.2 104.1 55 103.8 54.8C104.1 54.4 104.2 53.8 104 53.2C103.8 52.8 103.4 52.5 103 52.3L103.2 52.2C104 51.8 104.2 51.6 103.9 50.8C103.6 50 102.6 49.6 101.8 50L96.8 52.2C96 52.6 95.6 53.5 96 54.3C96.1 54.6 96.4 54.7 96.6 54.8C96 55.2 95.8 56 96.1 56.7C96.3 57.1 96.6 57.3 96.9 57.5C96.6 57.9 96.5 58.5 96.7 59.1C97 59.7 97.6 60.1 98.3 60C98.3 60.3 98.3 60.5 98.4 60.8L98.5 61C98.8 61.7 99.7 62.1 100.4 61.8L104.4 60.1C105.2 59.8 105.5 58.9 105.2 58.1Z" fill="#823C19" />
          <path d="M164.7 132.9C162.1 123 159 111.9 163.5 105.3C165.1 103 167.8 92.8 167.8 88.5C167.8 80.3 162.7 78.2 154.1 78.2L144.6 80.1C140.5 80.6 125.1 96.5 130.3 103.1C134.9 108.9 133.3 116 131.6 133H164.7" fill="#FF6100" />
          <path d="M19.3 40.8L56.1 67.8C60.9 70.7 64.8 73.8 67.5 68.9C69.6 65.1 68.3 60.3 64.6 58.1L21.6 32.5L19.3 40.8Z" fill="#703116" />
          <path d="M50.1 66.9L63.1 75.9C67.2 78.7 72.8 77.7 75.6 73.6C78.4 69.5 77.4 63.9 73.3 61.1L60.3 52.1L50.1 66.9Z" fill="#E89003" />
          <path d="M18.8 29.4C15.5 27.4 11.4 28.1 9.00003 30.9L3.40003 27.5C2.40003 26.9 1.20003 27.2 0.600028 28.2C2.75373e-05 29.2 0.300028 30.5 1.30003 31L7.20003 34.6C6.70003 37.7 8.00003 40.9 10.8 42.6C14.4 44.8 19.2 43.7 21.4 40C23.6 36.5 22.4 31.7 18.8 29.4Z" fill="#703116" />
          <path d="M206.3 71.7C199 67.1 191.5 64.2 185.4 58.5C178.8 52.3 176.8 41 172.2 33C172.1 32.4 172 31.9 171.8 31.4C171.4 27.6 173.8 26.9 174.3 26.8C175.2 26.6 175.8 25.8 175.6 24.9C175.4 24 174.6 23.4 173.7 23.5C172.2 23.8 169.8 25 168.8 27.7C168.8 27.7 168.7 27.7 168.7 27.6L165.1 25.2C164.1 24.5 162.8 24.7 161.9 25.5C161 26.3 160.4 27.5 159.9 27.9C157.3 30.7 155.1 32.5 161 36.9C161.7 37.4 162.9 38.1 166.8 39.2C169 47.8 171.5 60.5 178.1 67C183.2 72 190 74.4 197 78.4C200.5 81.9 206.2 83.2 209.5 79.5C212.2 76.7 209 74.5 206.3 71.7Z" fill="#FFB069" />
          <path d="M137.1 48.1C134.1 46.1 131.4 38 141.3 36.9C149 36 156.6 36.5 161.8 42.8C164.9 46.6 165 50.1 165.8 58.2L168.3 83.4C169.4 89.1 170.2 93.8 164.1 93.8C160.4 93.8 156.8 92.3 155.6 88.9L142 52C141.2 51.6 137.8 48.7 137.1 48.1Z" fill="#461C05" />
          <path d="M149.8 130.2H149.9C153.5 130.2 156.5 127.3 156.5 123.7L156.7 98.3C156.7 94.7 153.8 91.7 150.2 91.7H150.1C146.5 91.7 143.5 94.6 143.5 98.2L143.3 123.6C143.3 127.2 146.2 130.2 149.8 130.2Z" fill="#823C19" />
          <path d="M332.6 147.8L337.8 232.8L351.4 233.3L353.1 141.1L339.8 139.4L332.6 147.8Z" fill="#494E72" />
          <path d="M336.6 232.3H338V232.2H351.7V240.5H338H323.2C323.2 235.9 329.2 232.3 336.6 232.3Z" fill="#494E72" />
          <path d="M313.5 232.3H314.9V232.2H328.6V240.5H314.9H300.1C300.1 235.9 306.1 232.3 313.5 232.3Z" fill="#616898" />
          <path d="M359 134.3C361.6 117.4 362.2 96.1 358.3 75.6C353.4 62.9 349.3 64.9 342 66.5C330 67.6 320.3 84 319.2 120.7C319.1 124.3 318.7 133 319.1 134.4H359V134.3Z" fill="white" />
          <path d="M340.1 153.4C349.5 152.3 356.3 143.9 355.2 134.5L320.8 134.6L321.2 138.4C322.3 147.7 330.7 154.4 340.1 153.4Z" fill="#616898" />
          <path d="M312.5 197.4L315.5 232.8L329.1 233.1C329.1 233.1 332.3 177.7 337.9 167L346.5 145.1L321.6 136.1C321.7 136.1 309.5 160.4 312.5 197.4Z" fill="#616898" />
          <path d="M349.907 43.9236L334.607 45.9236L337.607 66.7236L351.307 66.9236L349.907 43.9236Z" fill="#E29E66" />
          <path d="M331.33 28.2263C334.296 26.9597 337.431 29.91 336.444 33.0015L333.856 41.0259C333.171 42.9908 331.098 44.1054 329.133 43.4204L320.326 41.3684C316.864 40.1767 313.921 37.1713 317.189 35.5061C322.037 34.6361 326.705 32.0493 331.33 28.2263Z" fill="#E29E66" />
          <path d="M321.565 58.3862C314.977 49.5602 322.991 41.2285 325.495 30.7318C329.202 15.0005 347.242 19.5024 353.802 28.2323C360.363 36.9622 346.342 41.3986 345.715 46.4678C344.625 55.7269 328.057 67.2398 321.565 58.3862Z" fill="#FFB069" />
          <path d="M341.978 30.8946C341.143 34.1509 342.52 37.5012 345.324 39.2979L346.19 39.7779L350.002 47.6313C350.002 47.6313 356.988 41.1549 356.735 35.9217C356.538 30.8808 341.978 30.8946 341.978 30.8946Z" fill="#823C19" />
          <path d="M318.959 47.4823C319.807 43.9101 323.705 40.8158 326.921 42.5984C339.661 49.6603 344.081 32.7882 344.732 37.5952C345.88 46.3165 343.572 60.3982 327.516 62.0893C321.061 62.796 316.154 59.8337 318.918 47.7021C318.986 47.5784 318.986 47.5784 318.959 47.4823Z" fill="#823C19" />
          <path
            d="M320.251 49.0882C320.251 49.0882 325.156 50.5945 329.26 46.4011" stroke="#E29E66" strokeWidth="1.3592"
            strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"
          />
          <path d="M331.5 32.2C331.9 31.6 331.7 30.8 331.1 30.4L328 28.3C327.4 27.9 326.6 28.1 326.2 28.7C325.8 29.3 326 30.1 326.6 30.5L329.7 32.6C330.3 33 331.1 32.8 331.5 32.2Z" fill="#823C19" />
          <path d="M327 34.9C327.718 34.9 328.3 34.318 328.3 33.6C328.3 32.882 327.718 32.3 327 32.3C326.282 32.3 325.7 32.882 325.7 33.6C325.7 34.318 326.282 34.9 327 34.9Z" fill="#823C19" />
          <path d="M257 36.1C258.3 35.2 257.5 37.2 258.5 38.4C259.4 39.7 259.1 41.4 257.9 42.4L257.3 45.3C256 46.2 254.3 45.9 253.3 44.7L252.4 44.6C250.4 44.3 249.6 42 251.5 41.3C253.8 40.3 257 36.1 257 36.1Z" fill="#E29E66" />
          <path d="M263.2 52L276.8 46.1L282.5 67.4L266 74.9C271.2 59 265.1 57.4 263.2 52Z" fill="#E29E66" />
          <path d="M261.6 26.5C270.6 20.1 274.4 24 278.3 34.5L278.4 37.1C278 46.2 278.8 53.5 268.4 57.4C258.1 61.3 253 57.8 253.5 46.6C254 38.7 255.5 30.9 261.6 26.5Z" fill="#FFB069" />
          <path d="M258.572 38.951C258.602 38.1231 258.134 37.4343 257.527 37.4125C256.92 37.3907 256.403 38.0442 256.374 38.8721C256.344 39.7 256.812 40.3889 257.419 40.4107C258.026 40.4325 258.542 39.779 258.572 38.951Z" fill="#823C19" />
          <path
            d="M260 46.9C257.7 48.4 256.3 48.4 254.4 48.1" stroke="#E29E66" strokeWidth="1.3592"
            strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"
          />
          <path
            d="M256.9 35.4C258 35.7 259.1 36.2 260.2 37.2" stroke="#823C19" strokeWidth="1.3275"
            strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"
          />
          <path d="M274.4 229.5C274.4 229.5 247.7 234.4 247.7 240.5H264H274.4C275.7 238.3 275.7 234.7 274.4 229.5Z" fill="#9CA2D1" />
          <path d="M364.6 37.2C364.4 38 363.6 38.4 362.8 38.1L319.8 24.8C319 24.6 318.6 23.8 318.9 23C319.1 22.2 319.9 21.8 320.7 22.1L363.7 35.4C364.4 35.6 364.8 36.4 364.6 37.2Z" fill="#616898" />
          <path d="M333.8 146.4C349.4 129.4 359 109.2 351.9 91.1C352.9 84.7 348.4 78.8 342 78H341.9C335.6 77.3 331.2 84.3 334.2 89.8C343.8 107.3 339.7 124.9 326.6 142.6L333.8 146.4Z" fill="#FFB069" />
          <path d="M343.5 75.6C349.4 75.4 354.4 80.1 354.6 86L355.2 106.1L333.7 106.7L333 86.7C332.8 80.7 337.5 75.8 343.5 75.6Z" fill="#E8ECF5" />
          <path d="M328.7 148.6H323.2C323.2 148.1 323.2 147.5 323.3 147C324.1 142.2 328.7 138.8 333.6 139.6C338.5 140.4 341.7 144.9 340.9 149.7C340.1 154.5 335.5 157.9 330.6 157.1C329.9 157 329.3 156.8 328.6 156.6V148.6H328.7Z" fill="#FFB069" />
          <path d="M227.9 73.4L210.7 77.6C212.3 72 213.8 64.9 210.4 60C210.2 56 211.3 54.5 215.3 54.3C219.3 54.1 225.1 49.6 225.3 53.6C225.3 60.5 225.2 69.5 227.9 73.4Z" fill="#E29E66" />
          <path d="M201.6 44.9C202.8 43.9 202.2 45.9 203.2 47.1C204.2 48.3 204.1 50.1 202.9 51.1L202.5 54C201.3 55 199.5 54.9 198.5 53.7L197.6 53.6C195.5 53.4 194.6 51.2 196.5 50.4C198.6 49.3 201.6 44.9 201.6 44.9Z" fill="#E29E66" />
          <path d="M216.6 28.6C229.8 30.5 226 45.9 226 45.9C222.1 59.3 210.2 67.3 203.1 65.3C199.4 64.2 200.4 54.3 199 43.9C197.4 32.2 204.8 26.9 216.6 28.6Z" fill="#FFB069" />
          <path d="M210.6 72.2L226.1 69.1C240.3 80.9 234.8 133.6 234.8 139.5C223 144.3 211.8 142.4 199.9 138.2C199.2 138.2 200.2 104.4 200.7 91.2C201.1 80.9 210.6 72.2 210.6 72.2Z" fill="#39B54A" />
          <path d="M199.2 84.7L214.8 90.3C220.1 92.2 225.5 90.3 226.9 86.1L227.1 85.4C228.5 81.2 225.2 76.2 219.9 74.3L204.3 68.7L199.2 84.7Z" fill="#46C151" />
          <path d="M214.6 91C214.4 91.6 213.8 91.9 213.2 91.7L201.1 87.7L201.8 85.6L213.9 89.6C214.5 89.7 214.8 90.4 214.6 91Z" fill="#2EA039" />
          <path d="M199 45.5L202.4 46C202.8 46.1 203.2 45.8 203.3 45.4C203.4 45 203.1 44.6 202.7 44.5L199.3 44C198.9 43.9 198.5 44.2 198.4 44.6C198.2 45 198.5 45.4 199 45.5Z" fill="#BC7835" />
          <path d="M200.3 59C201.9 59 204.2 57.7 205.4 56.1C205.8 55.6 205.6 54.9 205 54.7C204.7 54.6 204.3 54.7 204 54.9C203 56.2 200.8 57.1 200 56.9C200.1 57 200.1 59 200.3 59Z" fill="#BC7835" />
          <path d="M201.1 50C201.818 50 202.4 49.418 202.4 48.7C202.4 47.982 201.818 47.4 201.1 47.4C200.382 47.4 199.8 47.982 199.8 48.7C199.8 49.418 200.382 50 201.1 50Z" fill="#333333" />
          <path d="M217 26.7L197.3 26.8C197.3 26.8 202.7 37.4 209.8 38.9L210 46.3L220.5 58.7C232.6 59.1 232.7 43.1 232.7 43.1C233.3 38.9 232.2 34.6 229.3 31.5C226.9 29.2 223.1 27 217 26.7Z" fill="#494E72" />
          <path d="M212.1 48.8C212.1 51.1 212.8 53.5 215 53.5C217.2 53.5 218.9 51.7 218.9 49.4C218.9 47.1 217.1 45.3 215 45.3C212.9 45.3 212.1 46.6 212.1 48.8Z" fill="#FFB069" />
          <path d="M215.3 51.4C216.294 51.4 217.1 50.4598 217.1 49.3C217.1 48.1402 216.294 47.2 215.3 47.2C214.306 47.2 213.5 48.1402 213.5 49.3C213.5 50.4598 214.306 51.4 215.3 51.4Z" fill="#E29E66" />
          <path d="M213.8 50.5C214.352 50.5 214.8 50.0523 214.8 49.5C214.8 48.9477 214.352 48.5 213.8 48.5C213.248 48.5 212.8 48.9477 212.8 49.5C212.8 50.0523 213.248 50.5 213.8 50.5Z" fill="#FFB069" />
          <path d="M347.6 45.7C349.533 45.7 351.1 43.8196 351.1 41.5C351.1 39.1804 349.533 37.3 347.6 37.3C345.667 37.3 344.1 39.1804 344.1 41.5C344.1 43.8196 345.667 45.7 347.6 45.7Z" fill="#FFB069" />
          <path d="M347.4 44.4C348.781 44.4 349.9 43.1464 349.9 41.6C349.9 40.0536 348.781 38.8 347.4 38.8C346.019 38.8 344.9 40.0536 344.9 41.6C344.9 43.1464 346.019 44.4 347.4 44.4Z" fill="#E29E66" />
          <path d="M345.9 43C346.563 43 347.1 42.4627 347.1 41.8C347.1 41.1372 346.563 40.6 345.9 40.6C345.237 40.6 344.7 41.1372 344.7 41.8C344.7 42.4627 345.237 43 345.9 43Z" fill="#FFB069" />
          <path d="M357.3 33.4L327.5 24.2C329.5 17.7 336.4 14.1 342.9 16.1L349.2 18.1C355.6 20.1 359.3 26.9 357.3 33.4Z" fill="#616898" />
          <path d="M278.9 22.8C271.9 20 261.1 21.3 260.9 25C260.6 29.2 267.2 27 265.8 36C264.4 45 272.5 42.9 271.4 49.7C270.2 57.3 289.6 58.9 289.9 40.4C290.1 29.4 285 25.3 278.9 22.8Z" fill="#823C19" />
          <path d="M267.4 40.6C266.7 42.7 266.7 45.3 268.8 45.9C270.9 46.5 273.1 45.3 273.8 43.2C274.5 41.1 273.3 38.8 271.3 38.1C269.1 37.5 268.1 38.5 267.4 40.6Z" fill="#FFB069" />
          <path d="M271.995 42.5817C272.343 41.4754 271.857 40.3367 270.908 40.0383C269.96 39.74 268.909 40.395 268.561 41.5013C268.213 42.6077 268.7 43.7464 269.648 44.0447C270.596 44.343 271.647 43.688 271.995 42.5817Z" fill="#E29E66" />
          <path d="M268.8 42.8C269.352 42.8 269.8 42.3523 269.8 41.8C269.8 41.2477 269.352 40.8 268.8 40.8C268.248 40.8 267.8 41.2477 267.8 41.8C267.8 42.3523 268.248 42.8 268.8 42.8Z" fill="#FFB069" />
          <path d="M351.4 58.9C349.7 59.5 334.5 65.2 334.5 65.2L330.7 73.7L353.7 67.2L351.4 58.9Z" fill="#E8ECF5" />
          <path d="M298.7 66.7C299.3 70.5 296.2 72.6 292.4 73.2C288.6 73.8 284.4 57.9 283.9 54.1C283.3 50.4 298.1 63 298.7 66.7Z" fill="#823C19" />
          <path d="M288.6 77.7C284.9 63.7 280.8 61.3 271.3 66.5L266.6 69C255.6 85.2 253.2 87.6 255.7 103C257.5 114.1 253.5 149.3 252 147.8L292.9 147.1C293 147.1 295.7 104.2 288.6 77.7Z" fill="#0099FF" />
          <path d="M284.2 126.9H266.6V132.8H284.2V126.9Z" fill="#33ADFF" />
          <path d="M280.7 60.5C279.1 61.3 266.5 66.3 266.5 66.3L264.9 71.8L284.6 67L280.7 60.5Z" fill="#33ADFF" />
          <path d="M290 141.1C299.9 120.3 299.8 105.2 289 83.4C288.2 81.5 287.2 80 286.1 78.7C281.3 73.2 272 75.4 270.5 82.6C270.1 84.4 270.4 86 271.7 87.3C286 101.2 288.3 121.4 281.1 142.1L290 141.1Z" fill="#0099FF" />
          <path d="M271.6 87.3C270.3 86 269.2 82.7 269.1 83.1C268.6 84.8 269 86.5 270.3 87.8C284.6 101.7 286.9 121.9 279.7 142.6L288.6 141.5C288.7 141.4 288.7 141.2 288.8 141.1L281 142C288.3 121.4 286 101.2 271.6 87.3Z" fill="#0F76DB" />
          <path d="M283 147.6L279.1 148.8C279 148.4 278.9 148 278.8 147.6C278.3 143.9 280.9 140.6 284.5 140C288.1 139.5 291.4 142 291.9 145.7C292.4 149.4 289.8 152.7 286.2 153.3C285.7 153.4 285.2 153.4 284.7 153.3L283 147.6Z" fill="#FFB069" />
          <path d="M85.6 230.9H101.8L101.5 133.6L79.6 134.3L85.6 230.9Z" fill="#00428F" />
          <path d="M68.9 231.2L83.1 230.6C80.3 200.3 78.6 166.5 91.6 154.4C97.4 149 99.2 137.4 100.5 130.7L60.4 133.3C55.4 177.7 62.9 194.8 68.9 231.2Z" fill="#0058BF" />
          <path d="M69.4 240.5H101.7V230.6H84.6C76.2 230.6 69.4 235 69.4 240.5Z" fill="#00428F" />
          <path d="M51.3 240.5H84.6V230.6L71.8 230.8C63.4 230.8 51.3 235 51.3 240.5Z" fill="#0058BF" />
          <path d="M75.4 22.6L61 29.2C57.4 30.3 55.9 32.9 59.2 34.6L70.7 37.4C72.6 38.4 74.9 37.7 75.9 35.8L79.8 28.2C81.3 25.3 78.6 21.8 75.4 22.6Z" fill="#703116" />
          <path d="M71.4 64.8H89.6C87.3 56.8 87.8 47.1 89.6 36.8H71.4C72.8 47.8 74 58.5 71.4 64.8Z" fill="#703116" />
          <path d="M70.3 17.9C60.5 22.9 59.2 37.4 64.3 47.4C69.4 57.4 78 53 80 51.8C83.8 49.7 84.7 47.7 87 45.5C100.4 32.7 88.6 9.99999 71.6 17.3C71.1 17.5 70.7 17.6 70.3 17.9Z" fill="#823C19" />
          <path d="M78.8 13.1L75.4 10.5C73.7 9.19998 71.1 9.29998 69.7 11C68.2 12.8 68.5 15.5 70.3 16.9C70.3 16.9 74.4 18.2 79 19.5C77.2 23.1 76.1 28.8 78.6 30.3C81.9 32.3 83.1 36.4 84.6 39.4C86.5 43.3 92.2 42.9 93.5 38.8C96.2 30.1 95.5 21.2 88.4 17.3C85.7 15.8 81.3 15 78.8 13.1Z" fill="#6D7191" />
          <path d="M83.7 38C85.7435 38 87.4 36.3434 87.4 34.3C87.4 32.2565 85.7435 30.6 83.7 30.6C81.6566 30.6 80 32.2565 80 34.3C80 36.3434 81.6566 38 83.7 38Z" fill="#823C19" />
          <path
            d="M69.2 38.1C68.8 39.4 66.9 41.9 63.2 41.4" stroke="#703116" strokeWidth="1.3275"
            strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"
          />
          <path d="M65.6 30.3C66.2628 30.3 66.8 29.7627 66.8 29.1C66.8 28.4372 66.2628 27.9 65.6 27.9C64.9373 27.9 64.4 28.4372 64.4 29.1C64.4 29.7627 64.9373 30.3 65.6 30.3Z" fill="#141520" />
          <path d="M60.8 86.5C61.6 78.9 69.7 60.1 72.7 60.1L87.2 57.7C95.8 57.7 102.7 85.3 102.7 93L101.3 133.9L60.4 134.6C60.2 134.4 58.9 92.1 60.8 86.5Z" fill="#FFA403" />
          <path d="M100.9 99.6L89.3 70.3C87.9 66.8 84 65.1 80.5 66.5C77 67.9 75.3 71.8 76.7 75.3L88.4 104.6C89.8 108.1 93.7 109.8 97.2 108.4C100.6 107.1 102.3 103.1 100.9 99.6Z" fill="#823C19" />
          <path d="M98.8 101.8L94.1 98.5C92.4 97.3 90 97.8 88.9 99.6L70.7 130.3L68 137L78.3 140.7L78.8 131.9L99.5 107C100.9 105.5 100.5 103 98.8 101.8Z" fill="#823C19" />
          <path d="M96.4 82.9L90.5 68.2C88.7 63.6 83.4 61.3 78.8 63.2C74.2 65 71.9 70.3 73.8 74.9L79.7 89.6L96.4 82.9Z" fill="#FFA403" />
          <path d="M78.4 88.8L72.7 75.7C72.4 75.1 72.7 74.4 73.3 74.2C73.9 73.9 74.6 74.2 74.8 74.8L80.5 87.9C80.8 88.5 80.5 89.2 79.9 89.4C79.4 89.7 78.7 89.4 78.4 88.8Z" fill="#E89003" />
          <path d="M69.2 25.7C69.5 25 69.2 24.3 68.5 24L65.1 22.6C64.4 22.3 63.7 22.6 63.4 23.3C63.1 24 63.4 24.7 64.1 25L67.5 26.4C68.2 26.7 69 26.4 69.2 25.7Z" fill="#141520" />
          <path d="M157.6 106.8L141.8 106.1L142.3 95.1C142.5 90.7 146.2 87.3 150.5 87.5C154.9 87.7 158.3 91.4 158.1 95.7L157.6 106.8Z" fill="#FF6100" />
          <path d="M141.3 105.2L141.5 91C141.5 90.4 142 89.8 142.7 89.8C143.3 89.8 143.9 90.3 143.9 91L143.7 105.2C143.7 105.8 143.2 106.4 142.5 106.4C141.8 106.3 141.3 105.8 141.3 105.2Z" fill="#EF5906" />
          <path d="M154 122.7C153.1 119.2 149.5 117.2 146 118.1L123 127.9C120.1 124.5 117.5 123.7 112.9 124.9L111.9 125.2C109.1 128.2 108.7 131 110.5 133.4C112.3 135.8 115.4 136.6 118.2 135.9L125.7 134C135.3 133.4 149.5 130.9 149.5 130.9C153 130 155 126.4 154.1 122.9L154 122.7Z" fill="#823C19" />
          <path d="M220.6 230H234L235.1 140.2H213L220.6 230Z" fill="#00428F" />
          <path d="M198 230L213.1 229.8C200.5 200.5 208.6 165.7 227.2 141.6L199.8 138.7C188.5 165.4 182.4 205 198 230Z" fill="#0058BF" />
          <path d="M205.7 240.6H234V229.8H218.7C211.5 229.8 205.7 234.7 205.7 240.6Z" fill="#00428F" />
          <path d="M181.6 240.6H213.1C214.6 238.4 214.6 234.7 213.1 229.6H196C188 229.6 181.6 234.6 181.6 240.6Z" fill="#0058BF" />
          <path d="M217.4 155.7C207.6 155.7 199.7 147.8 199.7 138V136.2H235V138C235.1 147.8 227.2 155.7 217.4 155.7Z" fill="#0058BF" />
          <path d="M428.8 131.4L406 131.2C399.3 141.6 418 235.8 420.5 233.6H429.6L424.3 177.7C423.4 168.7 425.1 159.8 428.5 151.5C430.6 146.3 431.5 138.9 428.8 131.4Z" fill="#00428F" />
          <path d="M422.7 130.2L391.7 129.8C379.9 162.7 390.4 234.1 395.3 231.5H404.4L407 178.7C407.5 168.6 411.6 159 418.1 151.3C422.7 145.9 425.5 137.9 422.7 130.2Z" fill="#0058BF" />
          <path d="M400.8 239.7H429.6V231.2H414.7C407 231.2 400.8 235 400.8 239.7Z" fill="#00428F" />
          <path d="M375.9 239.7H404.7V231.4H389.5C382 231.4 375.9 235.1 375.9 239.7Z" fill="#0058BF" />
          <path d="M412 61.6H424.9L421.6 79H408.7L412 61.6Z" fill="#8E4B13" />
          <path d="M398.5 43.3C398.5 43.3 407.3 40.6 410.9 37.2C413.3 36.4 415.5 38.9 414.5 41.2L412 47.2C411.4 48.7 409.7 49.4 408.2 48.7L400.5 47.7C398 46.5 395.9 44.3 398.5 43.3Z" fill="#8E4B13" />
          <path d="M432 54C435.8 44 432.2 33.3 423.9 30.1C415.6 27 405.8 32.6 402 42.6C398.2 52.6 395.8 60.4 404.1 63.5C412.4 66.8 428.1 64.1 432 54Z" fill="#9E5A1D" />
          <path d="M403 49.5C402.8 49.3 402.5 49.3 402.2 49.5C402.1 49.6 401 50.7 399.2 51C399.1 51.4 399 51.8 398.9 52.2C401.2 51.9 402.8 50.4 402.9 50.3C403.1 50.1 403.1 49.8 403 49.5Z" fill="#70360B" />
          <path d="M404.1 43.8C404.763 43.8 405.3 43.2627 405.3 42.6C405.3 41.9372 404.763 41.4 404.1 41.4C403.437 41.4 402.9 41.9372 402.9 42.6C402.9 43.2627 403.437 43.8 404.1 43.8Z" fill="#1E202F" />
          <path d="M447.6 99.8L429.6 77.1C428.1 73.8 424.2 72.3 420.8 73.8L420.7 73.9C417.4 75.4 415.9 79.3 417.4 82.7L434.9 100.9C434.9 100.9 417 114.8 411.1 119.1L420.7 121.3C430.3 116.3 443 109.6 444.7 108.4C447.8 106.7 449 103 447.6 99.8Z" fill="#9E5A1D" />
          <path d="M439.3 86.9L426 99.6L414.5 87.5C411 83.8 411.1 78 414.8 74.5C418.5 71 424.3 71.1 427.8 74.8L439.3 86.9Z" fill="#494E72" />
          <path d="M401.3 79.1L408.1 70.3C413.4 67.5 424.4 69.5 427.4 75.2C431.4 82.9 431 111.5 429.9 132.6H391.1C393 113.1 389.3 92.5 401.3 79.1Z" fill="#494E72" />
          <path d="M387.9 30.1L382.2 32.8C381.5 33.1 380.7 32.6 380.7 31.9V9.29999C380.7 8.89999 380.9 8.49999 381.3 8.39999L387 5.69999C387.7 5.39999 388.5 5.89999 388.5 6.59999V29.3C388.5 29.6 388.3 30 387.9 30.1Z" fill="#494E72" />
          <path d="M381.6 16C383.9 15.1 385.1 20.7 385.9 23L387.6 27.3C388.1 26.8 387.1 21.7 389.1 21.7C390.6 21.7 390.6 23.7 390.5 25.9C391.6 29 390.8 29 389.6 31.8C389.1 32.9 388.8 34.1 388.7 35.3H388.8C388.2 36.6 377.8 64.7 377.8 64.7L405.5 70C408.7 71.7 410 75.7 408.3 78.9L408.2 79C406.5 82.2 402.5 83.5 399.3 81.8L368.5 73.2C365.4 71.6 364.2 67.9 365.5 64.8C366.4 62.1 379.4 41 383.2 33.2L382.3 37.9L377.9 24.8C377 22.5 378.5 16.9 380.8 16H381.6Z" fill="#9E5A1D" />
          <path d="M389.2 83.9L392 65.7L409.5 68.5C419.5 69.4 417.5 79.4 417.5 79.4C416.7 84.4 410.7 87.2 405.7 86.4L389.2 83.9Z" fill="#494E72" />
          <path d="M415 49.2L415.1 47.2C413.9 46.3 409.2 42.9 409.9 41.6C412.2 37.3 407.7 34.2 406.6 33.1C405 31.4 404.5 27.9 407.7 26.9C412.6 25.2 416.9 25.9 421.9 27.5C429.4 29.9 436.2 35.7 436.9 44.1C437.1 47.1 436 50.2 434.7 53.6C429.8 66.5 419.3 68.6 413.1 62.7L415 49.2Z" fill="#461C05" />
          <path d="M407.9 40.4C408.1 39.9 407.8 39.3 407.2 39.1L403.2 37.9C402.7 37.7 402.1 38 401.9 38.6C401.7 39.1 402 39.7 402.6 39.9L406.6 41.1C407.2 41.2 407.8 40.9 407.9 40.4Z" fill="#70360B" />
        </svg>
      </div>
    </div>
  </div>
);
