import React from 'react';
import { string } from 'prop-types';

import { t } from '@jotforminc/translation';

import { handleCustomNavigation } from '@jotforminc/utils';
import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';
import './v1.scss';

const BlackFridayRefundRequestV1 = ({ accountTypeName }) => {
  const {
    utm_campaign: utmCampaign
  } = useAssetInfo();

  const utmSource = `${utmCampaign}-refund-request-cta`;

  const handleClick = () => {
    handleCustomNavigation(`/pricing/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`, '_self', true);
  };
  return (
    <section className='bf-refund-request'>
      <div className="refund-wrapper">
        <div className='refund-container'>
          <div className="refund-flex-left">
            <div className="refund-flex-left-text">
              <span>{t('Important Notice')}</span>
              <span>{t('If you want a refund for a campaign purchase, you can directly buy the desired plan, and the amount you have paid will be deducted from your invoice automatically.')}</span>
            </div>
          </div>
          <div className="refund-flex-center">
            <img width="70" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/refund-request/sale-bag.png" alt="Jotform Black Friday Bag" />
          </div>
          <div className="refund-flex-right">
            <div className="refund-flex-right-text">
              <div className="title-percent">
                <span>{t('BLACK FRIDAY')}</span>
                <span>{t('HUGE SALE')}</span>
              </div>
            </div>
            <a
              className="locale upgrade-now-button" onClick={handleClick}
              href={`/pricing/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=refund-request`}
            >
              {t('Upgrade Now')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

BlackFridayRefundRequestV1.propTypes = {
  accountTypeName: string.isRequired
};

export default BlackFridayRefundRequestV1;
