import React from 'react';

import PricingTable from './PricingTable';

import PricingTableProvider from '../context/pricingTableContext';
import CurrencyProvider from '../context/currencyProvider';

import LightESignPricingWithFeatures from '../layouts/LightESignPricingWithFeatures';
import LightESignPricingWithProdDetails from '../layouts/LightESignPricingWithProdDetails';

const PricingTableContainer = props => {
  // eslint-disable-next-line react/destructuring-assignment
  if (props.layout === 'LightESignPricingWithFeatures') {
    return <LightESignPricingWithFeatures {...props} />;
  }

  // eslint-disable-next-line react/destructuring-assignment
  if (props.layout === 'LightESignPricingWithProdDetails') {
    return <LightESignPricingWithProdDetails {...props} />;
  }

  return (
    <PricingTableProvider {...props}>
      <CurrencyProvider>
        <PricingTable />
      </CurrencyProvider>
    </PricingTableProvider>
  );
};

PricingTableContainer.propTypes = PricingTableProvider.propTypes;
PricingTableContainer.defaultProps = PricingTableProvider.defaultProps;

export default React.memo(PricingTableContainer);
