import React from 'react';

import { t } from '@jotforminc/translation';

const MyAccountBadge = () => {
  return (
    <div className="sm:ml-auto sm:rtl:ml-0 sm:rtl:mr-auto">
      <div
        className="px-1 xl:px-1.5 py-1 flex color-white font-bold radius-md line-height-xs text-xxs xl:text-xs right-0 top-5 lg:absolute xl:static whitespace-nowrap"
        style={{
          background: 'linear-gradient(90deg, #0075E3 0%, #0066C3 100%)'
        }}
      >
        {t('SAVE 50%')}
      </div>
    </div>
  );
};

export default MyAccountBadge;
