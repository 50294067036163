import React, {
  createContext, useContext, useState, useEffect
} from 'react';
import { node, string } from 'prop-types';
import isEqual from 'lodash/isEqual';

export const CurrencyProvider = ({ children, ...props }) => {
  const [currencyState, setCurrencyState] = useState(props);

  useEffect(() => {
    if (!isEqual(currencyState, props)) {
      setCurrencyState(props);
    }
  }, [props]);

  return (
    <CurrencyContext.Provider value={{ currencyState, setCurrencyState }}>
      {children}
    </CurrencyContext.Provider>
  );
};

CurrencyProvider.propTypes = {
  children: node.isRequired,
  locale: string,
  currency: string
};

CurrencyProvider.defaultProps = {
  currency: window.currency || 'USD',
  locale: window.userLocale || 'en-US'
};

const CurrencyContext = createContext({ currencyState: { ...CurrencyProvider.defaultProps } });

export const useCurrencyState = () => {
  const { currencyState } = useContext(CurrencyContext);
  return currencyState;
};
