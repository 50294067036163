import React from 'react';
import { t } from '@jotforminc/translation';
import './v1.scss';
import PricingBadge from '../../../../../ui/PricingBadge/PricingBadge';

const PricingBadgeV1 = () => {
  return (
    <PricingBadge>
      <strong>{t('SALE')}</strong>
    </PricingBadge>
  );
};

export default PricingBadgeV1;
