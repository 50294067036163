/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import { string, object } from 'prop-types';
import ReactDOM from 'react-dom';

import { t } from '@jotforminc/translation';
import { formatCurrency } from '@jotforminc/currency';
import { handleCustomNavigation } from '@jotforminc/utils';

import './v1.scss';

import { useOutsideClick } from '../../../../../../hooks/useOutsideClick';
import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';

import Modal from '../../../../../ui/Lightbox/Lightbox';
import { useActionManager } from '../../../../../../hooks/useActionManager';

const SmallBox = ({
  show,
  oneDollarText,
  currencyImage,
  handleClick
}) => {
  const portalId = 'jf-gam-lightbox-pricing-small-box-portal';

  const portalEl = useMemo(
    () => {
      const portalEl_ = global.document?.getElementById(portalId);

      if (portalEl_) return portalEl_;

      const newPortalEl_ = global.document?.createElement('div');
      newPortalEl_?.setAttribute('id', portalId);

      const body = global.document?.body;
      body?.insertBefore(newPortalEl_, body.firstChild);

      return newPortalEl_;
    },
    []
  );

  return ReactDOM.createPortal(
    !show
      ? <></>
      : (
        <div className="silverone--lightbox-small">
          <div className="silverone--lightbox-small--image">
            <img
              className="money"
              src={`https://cdn.jotfor.ms/assets/img/campaigns/2024/one-dollar/${currencyImage}_2x.png`}
              alt="Coin"
            />
          </div>
          <div className="silverone--lightbox-small--heading">
            <div className="badge">
              <div className="badge-inline">{t('LIMITED TIME OFFER')}</div>
            </div>
            <div className="top-title">{t('Silver Plan')}</div>
            <div className="title">
              {t('Only {oneCurrencyUnit}').replace('{oneCurrencyUnit}', oneDollarText)}
              <p>
                {t('FOR A YEAR')}
                *
              </p>
            </div>
            <div className="bottom-title">
              {t('FOR A YEAR')}
              *
            </div>
            <div className="silverone--lightbox-small--button">
              <button type="button" onClick={handleClick}>
                {t('Upgrade now')}
              </button>
            </div>
          </div>
        </div>
      ),
    portalEl
  );
};

const SilverOneModal = ({ accountTypeName, user = {} }) => {
  const [startCloseAnimation, setStartCloseAnimation] = useState(false);
  const [lightboxOpened, setLightboxOpened] = useState(true);
  const { tickAction } = useActionManager();

  const {
    account_type: { currency = 'USD' } = {},
    language
  } = user;

  const oneDollarText = formatCurrency({
    value: 1,
    locale: language,
    currency
  });

  const currencyImage = useMemo(() => {
    switch (currency) {
      case 'GBP':
        return 'pound';
      case 'EUR':
        return 'euro';
      default:
        return 'dollar';
    }
  }, [currency]);

  const {
    utm_campaign: utmCampaign
  } = useAssetInfo();
  const utmSource = `${utmCampaign}-exit-modal-cta`;

  const handleClose = () => {
    tickAction({ action: 'click', target: 'close' });
    setStartCloseAnimation(true);
  };

  const handleClick = () => {
    tickAction({ action: 'click', target: 'upgrade-now' });
    handleCustomNavigation(`/pricing/silver/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=exit-intent`, '_self', true);
  };

  const modalOutsideClickRef = useOutsideClick(handleClose);

  useEffect(() => {
    if (startCloseAnimation) {
      setTimeout(() => {
        setLightboxOpened(false);
      }, 350);
    }
  }, [startCloseAnimation]);

  return (
    <>
      <SmallBox
        show={!lightboxOpened}
        handleClick={handleClick}
        oneDollarText={oneDollarText}
        currencyImage={currencyImage}
      />
      <Modal
        className={startCloseAnimation ? 'lightbox-animation' : ''}
        open={lightboxOpened}
        showCloseIcon={false}
        ariaLabel="SilverOne Lightbox Pricing"
        data-campaign-type="ONEDOLLARSILVERPRICING"
        title="SilverOne Lightbox Pricing"
      >
        <div
          ref={modalOutsideClickRef}
          className="lightbox-content"
        >
          <div className="gamLightbox-close">
            <button
              type="button"
              onClick={handleClose}
            />
          </div>
          <div className="lightbox--modal-content">
            <div className="modal-content-top">
              <div className="lightbox--wrapper">
                <div className="center">
                  <div className="badge">
                    <div className="badge-inline">{t('LIMITED TIME OFFER')}</div>
                  </div>
                  <div className="heading">
                    <div className="top-title">{t('Silver Plan')}</div>
                    <div className="title">{t('Only {oneCurrencyUnit}').replace('{oneCurrencyUnit}', oneDollarText)}</div>
                    <div className="bottom-title">
                      {t('FOR A YEAR')}
                      *
                    </div>
                    <div className="subtitle">
                      {t('Get more than double your limits')}
                      {' '}
                      <br />
                      {' '}
                      {t('by upgrading to a Silver plan.')}
                    </div>
                  </div>
                  <div className="button">
                    <button type="button" className="button-1" onClick={handleClick}>
                      {t('Upgrade now')}
                    </button>
                  </div>
                </div>
                <img
                  className="money"
                  src={`https://cdn.jotfor.ms/assets/img/campaigns/2024/one-dollar/${currencyImage}_2x.png`}
                  alt="Coin"
                />
              </div>
            </div>
            <div className="modal-content-footer">
              <p className="lightbox-modal--text">
                *
                {t('Offer does not apply to Jotform Enterprise. The {oneCurrencyUnit} offer is a one-time offer for Bronze users upgrading to a Silver plan. Plans will switch to Silver for the remainder of the existing Bronze billing cycle and be charged at the price of an annual Silver plan at the end of the billing cycle. Cannot be combined with other offers.').replace('{oneCurrencyUnit}', oneDollarText)}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

SilverOneModal.propTypes = {
  accountTypeName: string.isRequired,
  user: object.isRequired
};

export default SilverOneModal;
