import React from 'react';
import cx from 'classnames';
import {
  object, string, bool, func
} from 'prop-types';
import { t } from '@jotforminc/translation';

import {
  ORDERED_PLAN_LIST, PLANS, CAMPAIGN_TYPES, SOURCES, THEMES
} from '../constants';
import {
  GET_THE_OFFER,
  CURRENT,
  DOWNGRADE,
  GET_STARTED,
  UPGRADE,
  YOU_ARE_SCHEDULED_TO_BE_DOWNGRADED_TO_FREE_ON_EXPIRATION_DATE,
  REACTIVATE,
  START_A_FREE_TRIAL
} from '../constants/texts';

import { usePricingContext } from '../context/pricingTableContext';

import { comparePlans } from '../utils';

// eslint-disable-next-line complexity
const PlanCTA = ({
  plan,
  type,
  showCampaignPrice,
  onClick
}) => {
  const {
    state: {
      userPlan,
      isGuestUser,
      disableCampaign,
      source,
      showDowngradeNotice,
      expirationDate,
      campaign: { type: campaignType },
      campaignStatus,
      theme,
      isReactivationEnable,
      hideAllLimits
    },
    getColumnColors,
    getPlanColors
  } = usePricingContext();

  const columnColors = getColumnColors();
  const planColors = getPlanColors();

  const { COLOR: CTA_BG_COLOR, TEXT: CTA_TEXT_COLOR } = planColors[plan.name];

  const getPlanCTAText = targetPlanName => {
    // pricingTableGuestTrialCTAText AB test
    if (global.window?.pricingTableGuestTrialCTAText && targetPlanName !== PLANS.FREE) return START_A_FREE_TRIAL;

    if (userPlan === 'GUEST' && campaignType.includes(CAMPAIGN_TYPES.NEWUSER) && targetPlanName !== PLANS.FREE) return GET_THE_OFFER;
    if (ORDERED_PLAN_LIST.indexOf(userPlan) < 0) return GET_STARTED;
    if (comparePlans(userPlan, targetPlanName) === 0 && isReactivationEnable && targetPlanName !== PLANS.FREE && !campaignStatus) return REACTIVATE;
    if (!showCampaignPrice && comparePlans(userPlan, targetPlanName) === 0) return CURRENT;
    if (comparePlans(userPlan, targetPlanName) <= 0) return UPGRADE;
    if (comparePlans(userPlan, targetPlanName) > 0) return DOWNGRADE;
  };

  let ctaBgColor = CTA_BG_COLOR;
  let ctaTextColor = CTA_TEXT_COLOR;

  if (getPlanCTAText(plan.name) === CURRENT) {
    ctaBgColor = '#D3DAE5';
    ctaTextColor = '#394258';
  } else if (getPlanCTAText(plan.name) === DOWNGRADE) {
    ctaBgColor = '#e6ebf2';
    ctaTextColor = '#57647e';
  }

  const pricingPlanCTAClasses = cx('cursor-pointer flex justify-center items-center w-full font-bold transition hover:opacity-70 radius', {
    'max-w-10/12 mx-auto': campaignStatus && ![CAMPAIGN_TYPES.REGULARFIFTYDISCOUNT, CAMPAIGN_TYPES.SAVEUPTOFORTYDISCOUNT].includes(campaignType) && userPlan !== 'GUEST',
    'max-w-full': userPlan === 'GUEST' && campaignType.includes(CAMPAIGN_TYPES.NEWUSER),
    'h-12': theme === THEMES.DEFAULT,
    'h-10': theme !== THEMES.DEFAULT
  });

  const planCTAClasses = cx('w-full', {
    'sticky bottom-0 bg-white': theme === THEMES.LITE,
    'p-3': theme !== THEMES.DEFAULT && !hideAllLimits,
    'p-4': theme === THEMES.DEFAULT,
    'radius-bl-lg radius-br-lg px-3 pb-5': theme === THEMES.MINI && hideAllLimits
  });

  const showDowngradeNoticeClass = cx('text-xs min-h-12 flex items-center', {
    'bg-white -mx-3': campaignStatus && [CAMPAIGN_TYPES.NEWUSER, CAMPAIGN_TYPES.OVERQUOTAUSER, CAMPAIGN_TYPES.BACKTOPAID].includes(campaignType)
  });

  const teamsPricing = source === SOURCES.TEAMS_PRICING;
  const signPricing = source === SOURCES.SIGN_PRICING;
  const appsPricing = source === SOURCES.APPS_PRICING;

  const signUpBonusClasses = cx('text-sm font-bold text-center py-2 px-4');

  const isGuestCampaignAvailable = isGuestUser || theme === THEMES.MINI;

  const hasCampaignAsset = !disableCampaign && Object
    .values(CAMPAIGN_TYPES)
    .filter(c => ![CAMPAIGN_TYPES.NONE, CAMPAIGN_TYPES.REGULARFIFTYDISCOUNT, CAMPAIGN_TYPES.SAVEUPTOFORTYDISCOUNT].includes(c))
    .includes(campaignType);

  const ctaBackgroundColor = (
    (!isGuestCampaignAvailable && (hasCampaignAsset || (source === SOURCES.PRICING && campaignType === 'EDUCATION')))
    || hideAllLimits
  )
    ? columnColors.HEADER
    : undefined;

  if (type === 'signUpBonus' && campaignType.includes(CAMPAIGN_TYPES.NEWUSER) && theme !== THEMES.MINI) {
    if (plan.name === PLANS.FREE) {
      return (
        <div
          className="hidden md:block"
          data-test-id='jf-pt-plan-cta'
          onClick={onClick}
          onKeyUp={onClick}
          style={{
            backgroundColor: ctaBackgroundColor,
            borderBottom: !(teamsPricing || signPricing || appsPricing) ? `1px solid ${columnColors.CONTENT_BORDER}` : '',
            height: '59px'
          }}
        />
      );
    }
    return (
      <div
        className="flex justify-center p-3"
        data-test-id='jf-pt-plan-cta'
        onClick={onClick}
        onKeyUp={onClick}
        style={{ backgroundColor: ctaBackgroundColor, borderBottom: !(teamsPricing || signPricing || appsPricing) ? `1px solid ${columnColors.CONTENT_BORDER}` : '' }}
      >
        <div className={signUpBonusClasses} style={{ backgroundColor: ctaBgColor, color: ctaTextColor }}>
          {t(GET_THE_OFFER)}
        </div>
      </div>
    );
  }

  return (
    <div
      className={planCTAClasses}
      data-test-id='jf-pt-plan-cta'
      onClick={onClick}
      onKeyUp={onClick}
      // eslint-disable-next-line no-nested-ternary
      style={{ backgroundColor: type === 'bottom' && (theme === THEMES.BILLING || theme === THEMES.DEFAULT) ? 'transparent' : type === 'top' ? columnColors.HEADER : ctaBackgroundColor }}
    >
      {
        plan.name === PLANS.FREE && showDowngradeNotice
          ? (
            <div
              className={showDowngradeNoticeClass}
              style={{
                color: campaignStatus && [CAMPAIGN_TYPES.NEWUSER, CAMPAIGN_TYPES.OVERQUOTAUSER, CAMPAIGN_TYPES.BACKTOPAID].includes(campaignType)
                  ? '#394258'
                  : '#f98e03'
              }}
            >
              {t(YOU_ARE_SCHEDULED_TO_BE_DOWNGRADED_TO_FREE_ON_EXPIRATION_DATE).replace('{expirationDate}', expirationDate)}
            </div>
          )
          : (
            <div className={pricingPlanCTAClasses} style={{ backgroundColor: ctaBgColor, color: ctaTextColor }}>
              {t(getPlanCTAText(plan.name))}
            </div>
          )
      }
    </div>
  );
};

PlanCTA.propTypes = {
  plan: object.isRequired,
  showCampaignPrice: bool.isRequired,
  type: string,
  onClick: func
};

PlanCTA.defaultProps = {
  type: '',
  onClick: f => f
};

export default PlanCTA;
