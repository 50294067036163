import React from 'react';
import { CurrencyProvider as CurrencyComponentProvider } from '@jotforminc/currency';
import { node } from 'prop-types';

import { usePricingContext } from './pricingTableContext';

const CurrencyProvider = ({ children }) => {
  const {
    state: {
      currencyLocale,
      currencyCode
    }
  } = usePricingContext();

  return (
    <CurrencyComponentProvider
      locale={currencyLocale}
      currency={currencyCode}
    >
      {children}
    </CurrencyComponentProvider>
  );
};

CurrencyProvider.propTypes = {
  children: node.isRequired
};

export default CurrencyProvider;
