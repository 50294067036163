import React from 'react';
import { string } from 'prop-types';

import usePolyfillLoader from '../hooks/usePolyfillLoader';

import '../styles/Currency.scss';

const loadPolyfillHoc = Component => {
  const CurrencyPolyfillHoc = props => {
    const { locale } = props;
    const isLoaded = usePolyfillLoader(locale);

    return (
      isLoaded ? <Component {...props} /> : <div className='currency-loading-spinner' />
    );
  };

  CurrencyPolyfillHoc.propTypes = {
    locale: string
  };

  CurrencyPolyfillHoc.defaultProps = {
    locale: null
  };

  return CurrencyPolyfillHoc;
};

export default loadPolyfillHoc;
