import React from 'react';
import { t } from '@jotforminc/translation';
import './v1.scss';

const PricingBadgeV1 = () => {
  return (
    <div className="jfRHeader--pricing-badge">
      <i>{t('SAVE 50%')}</i>
    </div>
  );
};

export default PricingBadgeV1;
