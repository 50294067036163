import React from 'react';

import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';

import HeaderBanner from '../../../../../ui/HeaderBanner/HeaderBanner';
import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';

import './v1.scss';

const AcademyBannerV1 = () => {
  const {
    utm_campaign: utmCampaign
  } = useAssetInfo();

  const utmSource = `${utmCampaign}-mfhb-cta`;

  const handleClick = () => {
    // dummy utm's please change it later with actual value
    handleCustomNavigation(`/academy/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_medium=myforms-header`, '_self', true);
  };
  return (
    <HeaderBanner>
      <span>
        academy banner v1
      </span>
      <a
        onClick={handleClick}
        href={`/academy/?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_medium=myforms-header`}
      >
        {t('Academy')}
      </a>
    </HeaderBanner>
  );
};

export default AcademyBannerV1;
