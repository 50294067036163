import React, { useEffect } from 'react';
import cx from 'classnames';

// eslint-disable-next-line no-restricted-imports
import '@jotforminc/jotform.css';

import { PLAN_LAYOUTS, PLANS, THEMES } from '../constants';

import Plan from './Plan';
import EnterprisePlan from './EnterprisePlan';
import NoticeBox from './NoticeBox';
import Disclaimers from './Disclaimers';
import Switch from './Switch';

import { CheckoutCardPlan } from './layouts/CheckoutCardPlan';

import { usePricingContext } from '../context/pricingTableContext';

const PricingTable = () => {
  const {
    state: {
      plans,
      hideNoticeBox,
      campaign: { expires },
      campaignStatus,
      theme,
      planLayout
    },
    showPeriodSwitchToggleSelector,
    getVisiblePlans
  } = usePricingContext();

  const visiblePlans = getVisiblePlans();

  const showPeriodSwitchToggle = showPeriodSwitchToggleSelector();

  const PlanLayout = planLayout === PLAN_LAYOUTS.CHECKOUT_CARD ? CheckoutCardPlan : Plan;

  const visiblePlanList = visiblePlans.map(plan => (
    plan === PLANS.ENTERPRISE
      ? (
        <EnterprisePlan key='ENTERPRISE' />
      )
      : (
        <PlanLayout
          key={plan}
          plan={plans[plan]}
        />
      )
  ));

  const tableWrapperClasses = cx('w-min m-auto min-w-full', {
    'md:min-w-md': theme === THEMES.DEFAULT && !campaignStatus
  });

  // Reload page when campaign is ended
  useEffect(() => {
    let timer;
    if (campaignStatus && expires?.new_users_campaign) {
      const maxDelay = 1209600000; // 14days
      let remainTime = (expires.new_users_campaign * 1000) - (new Date()).getTime();
      if (remainTime > maxDelay) {
        remainTime = maxDelay;
      }
      if (remainTime && remainTime > 0) {
        timer = setTimeout(() => {
          window.location.reload();
        }, remainTime);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [campaignStatus]);

  return (
    <div
      className={tableWrapperClasses}
      data-testid='pricing-table-container'
    >
      {showPeriodSwitchToggle && <Switch />}
      {!hideNoticeBox && <NoticeBox />}
      <ul className='flex flex-wrap xl:flex-nowrap justify-center gap-4 md:gap-0 w-full mt-2 list-none'>
        {visiblePlanList}
      </ul>
      <Disclaimers />
    </div>
  );
};

export default PricingTable;
