import React, { useEffect, useState } from 'react';
import { node, string } from 'prop-types';

import {
  Dialog,
  DialogBody,
  DialogCloseButton
} from '@jotforminc/magnet';
import { StorageHelper } from '@jotforminc/storage-helper';

import './exit-modal.scss';

import { handleShowExitModal } from '../../../utils/exit';
import { getDismissStorageItemKey, checkIsAssetDismissed } from '../../../utils/campaign';

import { useAssetInfo, useAssetType } from '../../../hooks/useCampaignInfo';
import { useActionManager } from '../../../hooks/useActionManager';

const ExitModal = ({
  children,
  title,
  onClose
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    type: campaignType,
    assetsVersion
  } = useAssetInfo();
  const assetType = useAssetType();
  const { tickAction } = useActionManager();

  const dismissKey = getDismissStorageItemKey({
    assetType,
    assetsVersion,
    campaignType
  });

  const isDismissed = checkIsAssetDismissed({
    assetType,
    assetsVersion,
    campaignType
  });

  const handleOnClose = () => {
    tickAction({ action: 'closed' });

    document.querySelector('body').classList.remove('jf-exit-modal');
    StorageHelper.setLocalStorageItem({
      key: dismissKey,
      value: '1',
      ttl: 1000 * 60 * 60 * 24
    });
    setIsOpen(false);
    onClose();
  };

  const mouseOutListener = event => handleShowExitModal(event, () => setIsOpen(true));

  const removeListener = () => {
    document.removeEventListener('mouseout', mouseOutListener);
  };

  useEffect(() => {
    if (isOpen) {
      document.querySelector('body').classList.add('jf-exit-modal');
      removeListener();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      tickAction({ action: 'seen' });
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener('mouseout', mouseOutListener);
    return removeListener;
  }, []);

  return isDismissed
    ? <></>
    : (
      <Dialog
        open={isOpen}
        onClose={handleOnClose}
        ariaLabel={title}
        id="exit-modal"
        data-campaign-type={campaignType}
        zIndex={8000}
      >
        <DialogBody>
          {children}
        </DialogBody>
        <DialogCloseButton onClick={handleOnClose} />
      </Dialog>
    );
};

ExitModal.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  onClose: () => {}
};

ExitModal.defaultProps = {
  onClose: () => {}
};

export default ExitModal;
