import memoize from 'lodash/memoize';
import chunk from 'lodash/chunk';

// eslint-disable-next-line no-restricted-globals
const isInteger = Number.isInteger || (value => typeof value === 'number' && isFinite(value) && Math.floor(value) === value);

export const intFormatterCreator = ({
  locale,
  currency,
  currencyDisplay,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
}) => new Intl.NumberFormat(locale, {
  style: 'currency',
  currencyDisplay,
  currency,
  minimumFractionDigits,
  maximumFractionDigits
});

export const floatFormatterCreator = ({
  locale,
  currency,
  currencyDisplay,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
}) => new Intl.NumberFormat(locale, {
  style: 'currency',
  currencyDisplay,
  currency,
  minimumFractionDigits,
  maximumFractionDigits
});

export const getNumberParts = ({
  value, currency, intFormatter, floatFormatter
}) => {
  const formatter = isInteger(value) ? intFormatter : floatFormatter;
  const formattedValue = formatter.formatToParts(value)
    .map(({ type, value: partValue }) => {
      switch (type) {
        case 'currency':
          return { type, value: partValue === 'TRY' || partValue === '₺' ? 'TL' : partValue };
        default:
          return { type, value: partValue };
      }
    });

  if (currency === 'TRY') {
    const currencyIndex = formattedValue.findIndex(v => v.type === 'currency');
    const currencyItem = formattedValue.splice(currencyIndex, 2);
    formattedValue.push(currencyItem[0]);
  }

  return formattedValue;
};

export const formatCurrency = ({
  value,
  locale,
  currency,
  currencyDisplay
}) => {
  const intFormatter = memoize(intFormatterCreator)({ locale, currency, currencyDisplay });
  const floatFormatter = memoize(floatFormatterCreator)({ locale, currency, currencyDisplay });

  return getNumberParts({
    value, currency, intFormatter, floatFormatter
  }).map(part => part.value).join('');
};

export function customFormatToParts(number) {
  const parts = [];
  const options = this.resolvedOptions();
  const { currency, currencyDisplay } = options;
  const maximumFractionDigits = options.maximumFractionDigits || 2;

  // currency part
  let currencySymbol = currencyDisplay === 'symbol' ? '$' : currency || 'USD';
  if (currency === 'TRY') currencySymbol = 'TRY';
  if (currency === 'BRL') currencySymbol = currencyDisplay === 'symbol' ? 'R$' : currency;
  if (currency === 'EUR') currencySymbol = currencyDisplay === 'symbol' ? '€' : currency;
  if (currency === 'GBP') currencySymbol = currencyDisplay === 'symbol' ? '£' : currency;
  if (currency === 'AUD') currencySymbol = currencyDisplay === 'symbol' ? 'AU$' : currency;
  if (currency === 'CAD') currencySymbol = currencyDisplay === 'symbol' ? 'CA$' : currency;
  parts.push({ type: 'currency', value: currencySymbol });

  // Handle negative values
  if (number < 0) {
    parts.push({ type: 'minusSign', value: '-' });
  }

  // integer part
  const intValue = Math.floor(Math.abs(number)); // 1234567890
  const reversedIntValueString = intValue.toString().split('').reverse(); // 0987654321
  const groupedReversedIntValueString = chunk(reversedIntValueString, 3); // 098 765 432 1
  const groupedIntValueString = groupedReversedIntValueString.reverse(); // 1 234 567 890
  groupedIntValueString.forEach((group, index) => {
    parts.push({ type: 'integer', value: group.reverse().join('') });
    if (index < groupedIntValueString.length - 1) {
      parts.push({ type: 'group', value: ',' });
    }
  });

  const fraction = Math.abs(number) % 1;
  // decimal part
  parts.push({ type: 'decimal', value: '.' });

  // fraction part
  parts.push({ type: 'fraction', value: fraction.toFixed(maximumFractionDigits).slice(maximumFractionDigits) });

  return parts;
}
