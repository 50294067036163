import React from 'react';
import { bool, object } from 'prop-types';
import { t } from '@jotforminc/translation';
import { Currency } from '@jotforminc/currency';
import cx from 'classnames';

import {
  PERIODS, SOURCES, THEMES
} from '../constants';
import { MONTH } from '../constants/texts';

import { usePricingContext } from '../context/pricingTableContext';

const getMonthlyPriceByPeriod = ({ prices, period }) => {
  switch (period) {
    case PERIODS.MONTHLY:
      return prices[PERIODS.MONTHLY];
    case PERIODS.YEARLY:
      return prices[PERIODS.YEARLY] / 12;
    case PERIODS.BIYEARLY:
      return prices[PERIODS.BIYEARLY] / 24;
    default:
  }
};

// eslint-disable-next-line complexity
const Price = ({
  plan: {
    prices, nonSalesPrices, fullPrices, onSale
  }, showCampaignPrice
}) => {
  const {
    state: {
      period,
      campaignStatus,
      forceDiscount,
      theme,
      source
    },
    getColumnColors,
    checkLegacyYearlyUsersOnCampaignTime,
    showPeriodSwitchToggleSelector
  } = usePricingContext();

  const isLegacyYearlyUsersOnCampaignTime = checkLegacyYearlyUsersOnCampaignTime();
  const showPeriodSwitchToggle = showPeriodSwitchToggleSelector();

  const isBuyOneGetOne = source === SOURCES.BUY_ONE_GET_ONE;

  const {
    HEADER_NOTE: headerNoteColor
  } = getColumnColors();

  const RedCross = () => <div className='absolute h-1 bg-red-300 top-4/12 left-0' style={{ width: '120%', transform: 'rotate(-25deg)' }} />;

  const priceClasses = {
    currencyValue: cx('font-extrabold flex items-end', {
      'text-8xl line-height-6xl': theme === THEMES.DEFAULT,
      'text-6xl line-height-4xl': theme !== THEMES.DEFAULT
    })
  };

  // eslint-disable-next-line react/prop-types
  const OldPrice = ({ value }) => {
    return (
      <div className='text-md pt-1' style={{ color: headerNoteColor }}>
        <strong className="font-bold relative">
          <Currency
            value={value}
            CurrencyRenderer={({ children }) => <span className='mr-1'>{children}</span>}
            ValueRenderer={({ children }) => (
              <span className='text-2xl'>
                <RedCross />
                {children}
              </span>
            )}
          />
        </strong>
        { isBuyOneGetOne ? '' : `/${t(MONTH)}` }
      </div>
    );
  };

  // eslint-disable-next-line react/prop-types
  const CurrentPrice = ({ value }) => {
    const isIntegerValue = value % 1 === 0;
    return (
      <div className='text-md py-2 current-price-wrapper'>
        <div className="flex items-end">
          <Currency
            value={value}
            CurrencyRenderer={({ children }) => <span className="text-sm">{children}</span>}
            ValueRenderer={({ children }) => <strong className={priceClasses.currencyValue}>{children}</strong>}
            DecimalWrapperRenderer={({ children }) => (
              <span className="flex flex-col text-left">
                <strong className="font-bold text-xl line-height-md">{children}</strong>
                <span className="text-sm font-normal line-height-xs" style={{ color: headerNoteColor }}>{`/${t(MONTH)}`}</span>
              </span>
            )}
          />
          {isIntegerValue && !isBuyOneGetOne && <span className="text-sm font-normal line-height-xs" style={{ color: headerNoteColor }}>{`/${t(MONTH)}`}</span>}
        </div>
      </div>
    );
  };

  if (showCampaignPrice) {
    let currentPrice = getMonthlyPriceByPeriod({ prices, period });
    let oldPrice = prices[PERIODS.MONTHLY];

    if (isBuyOneGetOne) {
      currentPrice = (nonSalesPrices || fullPrices || prices)[PERIODS.YEARLY];
      oldPrice = (nonSalesPrices || fullPrices || prices)[PERIODS.BIYEARLY];
    } else if (
      forceDiscount && onSale && ([SOURCES.G2_DISCOUNT_PAGE, SOURCES.BLACK_FRIDAY_PAGE, SOURCES.SUMMER_CAMP, SOURCES.SURVEYS, SOURCES.TYPEFORM_ALTERNATIVE, SOURCES.SQUARE_APP_WEEK].includes(source))
    ) {
      currentPrice = onSale.prices[PERIODS.YEARLY] / 12;
      oldPrice = prices[PERIODS.MONTHLY];
    } else if (forceDiscount && onSale) {
      currentPrice = getMonthlyPriceByPeriod({ prices: onSale.nonProfit, period });
      oldPrice = getMonthlyPriceByPeriod({ prices: nonSalesPrices || fullPrices || prices, period });
    } else if (nonSalesPrices) {
      oldPrice = getMonthlyPriceByPeriod({ prices: nonSalesPrices, period });
    }

    return (
      <div className='flex flex-col'>
        <OldPrice value={oldPrice} />
        <CurrentPrice value={currentPrice} />
      </div>
    );
  }

  if (isLegacyYearlyUsersOnCampaignTime) {
    return <CurrentPrice value={prices[PERIODS.YEARLY] / 12} />;
  }

  if (campaignStatus) {
    let currentPrice = prices[PERIODS.MONTHLY];
    if (showPeriodSwitchToggle) {
      currentPrice = getMonthlyPriceByPeriod({ prices, period });
    }
    return <CurrentPrice value={currentPrice} />;
  }

  const currentPrice = getMonthlyPriceByPeriod({ prices: fullPrices || prices, period });
  return <CurrentPrice value={currentPrice} />;
};

Price.propTypes = {
  plan: object.isRequired,
  showCampaignPrice: bool.isRequired
};

export default Price;
