import React from 'react';
import './index.scss';

const SummerSaleBanner = ({ ...props }) => {
  if (!document.body.classList.contains('summersale-body')) {
    document.body.classList.add('summersale-body');
  }

  return (
    <div className="summersale-banner" {...props}>
      <span>summersale</span>
    </div>
  );
};

export default SummerSaleBanner;
