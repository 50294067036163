import React, { useMemo } from 'react';
import { elementType, number, string } from 'prop-types';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import loadPolyfillHoc from '../hocs/loadPolyfillHoc';
import { useCurrencyState } from '../context/context';
import { floatFormatterCreator, getNumberParts, intFormatterCreator } from '../utils/utils';

// NOTE: FragmentDiv solves the Translated Edge browsers rerender issue.
// eslint-disable-next-line react/prop-types
const FragmentDiv = ({ children, ...props }) => <div style={{ display: 'contents' }} {...props}>{children}</div>;
// eslint-disable-next-line react/prop-types
const FragmentSpan = ({ children, ...props }) => <span {...props}>{children}</span>;

const Currency = props => {
  const currencyState = useCurrencyState();

  const {
    locale, value, currency, minimumFractionDigits, maximumFractionDigits,
    ValueRenderer, CurrencyRenderer, IntegerRenderer, GroupRenderer, DecimalWrapperRenderer, DecimalRenderer,
    FractionRenderer, MinusSignRenderer
  } = { ...currencyState, ...omitBy(props, isNil) };
  const formatterProps = {
    locale,
    currency,
    minimumFractionDigits,
    maximumFractionDigits
  };
  const ComponentMapByCurrencyPart = {
    currency: CurrencyRenderer,
    integer: IntegerRenderer,
    group: GroupRenderer,
    decimal: DecimalRenderer,
    fraction: FractionRenderer,
    minusSign: MinusSignRenderer
  };
  const intFormatter = useMemo(() => intFormatterCreator(formatterProps), [locale, currency]);
  const floatFormatter = useMemo(() => floatFormatterCreator(formatterProps), [locale, currency]);

  const numberParts = getNumberParts({
    value, currency, intFormatter, floatFormatter
  })
    .map(({ type, value: partValue }) => {
      const PartComponent = ComponentMapByCurrencyPart[type];

      return {
        type,
        value: PartComponent ? <PartComponent key={`jf-currency-${type}-renderer`}>{partValue}</PartComponent> : partValue
      };
    });

  const renderParts = parts => {
    const decimalAndFractionalComponents = [];
    let decimalWrapperIndex;

    const partComponents = parts.reduce((components, { type, value: partComponent }, index) => {
      if (DecimalWrapperRenderer && ['decimal', 'fraction'].includes(type)) {
        decimalAndFractionalComponents.push(partComponent);
        if (decimalWrapperIndex === undefined) {
          decimalWrapperIndex = index;
        }
      } else {
        components.push(partComponent);
      }
      return components;
    }, []);

    if (decimalWrapperIndex) {
      partComponents.splice(
        decimalWrapperIndex,
        0,
        (
          <DecimalWrapperRenderer key="jf-currency-decimal-wrapper-renderer">
            {decimalAndFractionalComponents}
          </DecimalWrapperRenderer>
        )
      );
    }

    return partComponents;
  };

  const renderedParts = [];

  if (numberParts[0].type === 'minusSign') {
    renderedParts.push(numberParts.shift().value);
  }

  if (numberParts[0].type === 'currency') {
    renderedParts.push(numberParts.shift().value);
    renderedParts.push((
      <ValueRenderer key='jf-currency-value-renderer'>
        {renderParts(numberParts)}
      </ValueRenderer>
    ));
  } else {
    renderedParts.push((
      <ValueRenderer key='jf-currency-value-renderer'>
        {renderParts(numberParts.slice(0, -1))}
      </ValueRenderer>
    ));
    renderedParts.push(numberParts.slice(-1).pop().value);
  }

  return <FragmentDiv>{renderedParts}</FragmentDiv>;
};

Currency.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  value: number.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  locale: string,
  // eslint-disable-next-line react/no-unused-prop-types
  currency: string,
  // eslint-disable-next-line react/no-unused-prop-types
  ValueRenderer: elementType,
  // eslint-disable-next-line react/no-unused-prop-types
  CurrencyRenderer: elementType,
  // eslint-disable-next-line react/no-unused-prop-types
  IntegerRenderer: elementType,
  // eslint-disable-next-line react/no-unused-prop-types
  GroupRenderer: elementType,
  // eslint-disable-next-line react/no-unused-prop-types
  DecimalWrapperRenderer: elementType,
  // eslint-disable-next-line react/no-unused-prop-types
  DecimalRenderer: elementType,
  // eslint-disable-next-line react/no-unused-prop-types
  FractionRenderer: elementType,
  // eslint-disable-next-line react/no-unused-prop-types
  MinusSignRenderer: elementType
};

Currency.defaultProps = {
  currency: null,
  locale: null,
  ValueRenderer: FragmentDiv,
  CurrencyRenderer: FragmentSpan,
  IntegerRenderer: FragmentSpan,
  GroupRenderer: FragmentSpan,
  DecimalWrapperRenderer: FragmentDiv,
  DecimalRenderer: FragmentSpan,
  FractionRenderer: FragmentSpan,
  MinusSignRenderer: FragmentSpan
};

export default loadPolyfillHoc(Currency);
