import React from 'react';
import { string, object } from 'prop-types';
import cx from 'classnames';

import DealCounter from '../../components/DealCounter';
import BestValueRibbon from '../../components/BestValueRibbon';
import PriceAndNote from '../../components/PriceAndNote';
import PlanCta from './PlanCTA';

import { usePricingTable } from '../../hooks/usePricingTable';

const PlanHeader = ({ planName, palette }) => {
  const {
    getPlan
  } = usePricingTable();

  const {
    prettyName,
    colors,
    isBestValue,
    showDealCounter
  } = getPlan(planName);

  const titleClasses = cx('relative flex justify-center items-center w-full font-bold radius-tl-lg radius-tr-lg text-lg', {
    'pt-4 mt-2': isBestValue
  });

  return (
    <div className="w-full" id={`pricing-table-${planName.toLowerCase()}-header`}>
      {showDealCounter && <DealCounter theme="default" plan={{ planName }} />}
      <div
        className={titleClasses}
        style={{
          color: colors.TEXT,
          backgroundColor: colors.COLOR
        }}
      >
        <span className="my-3">
          {prettyName}
        </span>
        {isBestValue && <BestValueRibbon />}
      </div>
      <div
        className={cx(
          'flex flex-col justify-center items-center w-full text-sm px-4 py-6 radius-b-lg'
        )}
        style={{
          color: palette.CONTENT_TEXT,
          backgroundColor: palette.CONTENT
        }}
      >
        <PriceAndNote
          planName={planName}
          palette={palette}
        />
        <PlanCta
          planName={planName}
          isTopCta={true}
        />
      </div>
    </div>
  );
};

PlanHeader.propTypes = {
  planName: string.isRequired,
  palette: object.isRequired
};

PlanHeader.defaultProps = {
};

export default PlanHeader;
