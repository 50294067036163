import React, { useMemo } from 'react';
import { string } from 'prop-types';
import cx from 'classnames';

import {
  COLOR_THEMES,
  ORDERED_PLAN_LIST,
  PLAN_COLUMN_COLORS,
  PLANS
} from '../../constants';

import pricingTableManager from '../../hocs/pricingTableManager';
import { usePricingTable } from '../../hooks/usePricingTable';

import PlanHeader from './PlanHeader';

import Disclaimers from '../../components/Disclaimers';

const Plan = ({ planName }) => {
  const {
    theme,
    getPlan
  } = usePricingTable();

  const {
    onPlanContainerClicked
  } = getPlan(planName);

  const listClasses = cx('max-w-72 radius-lg w-56 xs:w-44 p-1.5');

  const buttonClasses = cx('text-center w-full h-full flex flex-col justify-end');

  const palette = useMemo(() => {
    return theme === COLOR_THEMES.LIGHT ? PLAN_COLUMN_COLORS.LIGHT : PLAN_COLUMN_COLORS.DEFAULT;
  }, [theme]);

  const handleColumnClick = () => {
    onPlanContainerClicked({ clickPoint: 'plan-container' });
  };

  return (
    <li
      className={listClasses}
      data-testid={`pricing-table-${planName.toLowerCase()}-column`}
    >
      <div
        className={buttonClasses}
        onClick={handleColumnClick}
        aria-label={`${planName}`}
      >
        <PlanHeader
          planName={planName}
          palette={palette}
        />
      </div>
    </li>
  );
};

Plan.propTypes = {
  planName: string.isRequired
};

const LightboxPricingTable = () => {
  const {
    userPlan,
    visiblePlanNames
  } = usePricingTable();

  const tableWrapperClasses = cx('w-min m-auto min-w-full', {
    // 'md:min-w-md': !campaignStatus
  });

  const visibleUpperPlanNames = visiblePlanNames.filter(planName => {
    const indexOfUserPlan = ORDERED_PLAN_LIST.findIndex(pn => userPlan === pn);
    const indexOfPlan = ORDERED_PLAN_LIST.findIndex(pn => planName === pn);
    return indexOfPlan > indexOfUserPlan && planName !== PLANS.ENTERPRISE;
  });

  return (
    <div
      className={tableWrapperClasses}
      data-testid="pricing-table-container"
    >
      <ul className="flex flex-wrap xl:flex-nowrap justify-center w-full mt-2 list-none">
        { visibleUpperPlanNames.map(planName => <Plan planName={planName} key={planName} />) }
      </ul>
      <Disclaimers />
    </div>
  );
};

export default pricingTableManager(LightboxPricingTable);
