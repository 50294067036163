import React, { useState, useEffect } from 'react';
import {
  node, string, bool, func
} from 'prop-types';
import {
  Button, Checkbox, Modal, ModalBody
} from '@jotforminc/magnet';
import { IconXmark } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { StorageHelper } from '@jotforminc/storage-helper';

import './lightbox.scss';

import { getDismissStorageItemKey } from '../../../utils/campaign';

import { useAssetInfo, useAssetType } from '../../../hooks/useCampaignInfo';
import { useActionManager } from '../../../hooks/useActionManager';

const Lightbox = ({
  children,
  ariaLabel,
  open: openProp,
  className,
  showCloseIcon,
  onClose
}) => {
  const {
    type: campaignType,
    assetsVersion
  } = useAssetInfo();
  const assetType = useAssetType();
  const { tickAction } = useActionManager();
  const [open, setOpen] = useState(openProp);
  const [dismiss, setDismiss] = useState(false);

  const dismissKey = getDismissStorageItemKey({
    assetType,
    assetsVersion,
    campaignType
  });

  const handleClose = () => {
    setOpen(false);
    if (!dismiss) {
      StorageHelper.setLocalStorageItem({
        key: dismissKey,
        value: '1',
        ttl: 1000 * 60 * 60
      });
    }
    tickAction({ action: 'closed' });
    onClose();
  };

  const handleDismissChange = isDismissed => {
    setDismiss(isDismissed);
    tickAction({ action: isDismissed ? 'dismissed' : 'undismissed' });
  };

  useEffect(() => {
    if (openProp !== open) {
      setOpen(openProp);
    }
  }, [openProp]);

  useEffect(() => {
    if (open) {
      tickAction({ action: 'seen' });
    }
  }, [open]);

  return (
    <Modal
      ariaLabel={ariaLabel}
      onClose={handleClose}
      size="large"
      open={open}
      id="gamLightbox"
      className={className}
      data-campaign-type={campaignType}
    >
      {showCloseIcon && (
        <div className="gamLightbox-close">
          <Button
            startIcon={IconXmark}
            colorStyle="secondary"
            rounded
            aria-label="Close Button"
            onClick={handleClose}
            size="small"
          />
        </div>
      )}
      <ModalBody>
        <div className="gamLightbox-body">
          {children}
        </div>
      </ModalBody>
      <NeverShowThisOption
        dismiss={dismiss}
        onDismissChange={handleDismissChange}
        dismissKey={dismissKey}
      />
    </Modal>
  );
};

const NeverShowThisOption = ({
  dismiss, dismissKey, onDismissChange
}) => {
  const handleDismiss = isChecked => {
    onDismissChange(isChecked);

    if (isChecked) {
      StorageHelper.setLocalStorageItem({
        key: dismissKey,
        value: '1',
        ttl: 1000 * 60 * 60 * 24 * 365
      });
    } else {
      StorageHelper.removeLocalStorageItem({ key: dismissKey });
    }
  };

  return (
    <div className='flex items-center gap-4 color-white mt-4 ml-1'>
      <Checkbox
        id='dismiss-campaign'
        size='small'
        checked={dismiss}
        onChange={e => handleDismiss(e.target.checked)}
      />
      <label htmlFor='dismiss-campaign'>{t('Never show this again')}</label>
    </div>
  );
};

NeverShowThisOption.propTypes = {
  dismiss: bool.isRequired,
  dismissKey: string.isRequired,
  onDismissChange: func.isRequired
};

Lightbox.propTypes = {
  children: node.isRequired,
  ariaLabel: string.isRequired,
  open: bool,
  className: string,
  showCloseIcon: bool,
  onClose: () => {}
};

Lightbox.defaultProps = {
  open: true,
  className: '',
  showCloseIcon: true,
  onClose: () => {}
};

export default Lightbox;
